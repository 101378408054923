













































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";

@Component
export default class DeleteCompanyProductPlan extends Request {
  @Prop() companyProductPlanId: number;
  @Prop() entityDelete: any;
  readonly header = `${translate("addChannels")}`;
  entity: any = {};
  dialog: boolean = false;
  onDelete() {
    this.entity = this.entityDelete;
    this.entity.active = !this.entity.active;
    this.entity.productId = this.entity.id;
    this.delete(this.paths.deleteLogicProductPath, this.entity);
    this.dialog = false;
    return this.$emit("is-active", this.entity);
  }

  get buttonIcons() {
    return this.entityDelete.active === true
      ? {
          icon: "mdi-close-circle",
          title: `${translate("inactivate")}`,
          color: "#ff0000",
        }
      : {
          icon: "mdi-check-circle",
          title: `${translate("activate")}`,
          color: "#008000",
        };
  }
}
