









































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";

import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import PlanDetailList from "./../planDetail/PlanDetailList.vue";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import LockUnlockDash from "../../components/commonComponents/LockUnlockDash.vue";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";

@Component({
  components: {
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PlanDetailList,
    LockUnlockDash,
  },
})
export default class ReportMatrix extends Request {
  title = `${translate("generalReportMatrix")}`;
  item: any = this.$route.query.item;
  status: any = this.$route.query.status;
  itemsPerPageList = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  customColumns = [
    {
      slotName: "isBlocked",
      itemValue: "isBlocked",
    },
  ];
  page = 1;
  pageCount = 0;
  itemsPerPage = 10;
  breadcrumbs = [
    {
      text: `${translate("title.dashboard")}`,
      disabled: false,
      to: "/dashboard/",
    },
    {
      text: `${translate("generalReportMatrix")}`,
      disabled: true,
    },
  ];
  header = [
    { text: `${translate("name")}`, value: "codeAndSocialName" },
    { text: `${translate("product")}`, align: "start", value: "product" },
    { text: `${translate("status")}`, align: "center", value: "isBlocked" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-notebook-multiple",
    titlePage: `${translate("generalReportMatrix")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  list: any = [];
  async loadData(): Promise<void> {
    const isBlocked = this.status === "notBlocked" ? false : true;
    const result = await this.get(
      this.paths.companyProductPlanByActivePath +
        `${this.item.id}?isBlocked=${isBlocked}&take=${
          this.itemsPerPage 
        }&skip=${1}`
    );
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
    this.list = result.result;
  }
  async mounted(): Promise<void> {
    this.loadData();
  }
  get companiesWithCodeAndSocialName() {
    return this.list.map((company) => ({
      ...company,
      codeAndSocialName: `${company.code} - ${company.socialName}`,
    }));
  }
  async loadPagination() {
    const isBlocked = this.status === "notBlocked" ? false : true;
    const result = await this.get(
      this.paths.companyProductPlanByActivePath +
        `${this.item.id}?isBlocked=${isBlocked}&take=${this.itemsPerPage}&skip=${this.page}`
    );
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
    this.list = result.result;
  }
  downloadCsv(list) {
    return list;
  }
  async downloadExcel(listToDownload) {
    this.list = await this.downloadCsv(listToDownload);
    let result, keys;

    if (this.list === null || (!this.list && !this.list.length)) {
      return this.$message?.successMessage(translate("downloadReady"));
    }

    const headersToExport = ["codeAndSocialName", "product", "isBlocked"];

    keys = Object.keys(this.list[0]).filter((key) =>
      headersToExport.includes(key)
    );

    result = "";
    result += keys
      .map((key) => {
        return key === "isBlocked" ? translate("status") : key;
      })
      .map((key) => translate(key))
      .join(",");
    result += "\n";

    this.list.forEach((item) => {
      result += keys
        .map((key) => {
          const cellValue =
            key === "isBlocked"
              ? item[key]
                ? translate("chip.isBlocked")
                : translate("active")
              : item[key];

          return typeof cellValue === "string" && cellValue.includes(",")
            ? `"${cellValue}"`
            : cellValue;
        })
        .join(",");
      result += "\n";
    });

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(result);
    hiddenElement.target = "_blank";

    hiddenElement.download = "relatório.csv";
    hiddenElement.click();
  }
}
