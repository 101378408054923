



















import { Component, Prop } from "vue-property-decorator";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views/index";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import { Broker } from "../../types/Broker";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";

@Component({
  components: {
    InputPopup,
  },
})
export default class BrokerInput extends Request {
  @Prop() entity: Broker;
  @Prop() list: Broker[];
  header = `${translate("broker")}`;

  active: boolean = true;

  async onSave(): Promise<any> {
    if (this.entity) {
      this.entity.active ? this.active : false;
      return await this.save(this.paths.brokerPath, this.entity);
    }
  }

  itemsForm: InputModel[] = [
    {
      id: 1,
      col: 10,
      label: `${translate("name")}`,
      tag: "v-text-field",
      type: "text",
      model: "name",
      required: true,
    },
    {
      id: 2,
      col: 2,
      slot: "active",
      model: "entity.active",
    },
  ];
}
