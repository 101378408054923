
















































import { Component } from "vue-property-decorator";
import ChannelDetailInput from "./ChannelDetailInput.vue";
import { Channel } from "../../types/Channel";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { HeaderItem } from "../../types/HeaderItem";
import { Breadcrumb } from "../../types/Breadcrumb";
import { page } from "../../types/Page";
import { paths } from "../../services/apiPaths";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    ChannelDetailInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class ChannelsList extends Request {
  path: string = paths.channelPath;
  channel: number = null;
  broker: number = null;
  page: page = {
    title: `${translate("channels")}`,
    icon: null,
  };
  companyId = this.$route.query.companyId;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("broker")}`,
      disabled: false,
      to: "/broker/",
    },
    {
      text: `${translate("channels")}`,
      disabled: true,
    },
  ];

  header: HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "referenceName" },
    {
      text: `${translate("phoneNumber")}`,
      align: "start",
      value: "phoneNumber",
    },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-chat-processing-outline",
    titlePage: `${translate("channels")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: Channel[] = [];

  async loadData(): Promise<void> {
    const result = await this.get(
      this.paths.channelDetailPath + `?companyId=${this.companyId}`
    );
    const channel = await this.get(this.paths.channelPath);
    this.channel = channel[0].find((i) => i.name === "whatsapp").id;

    if (result) {
      this.list = result[0];
    }
  }
  updateOptions() {
    this.loadData();
  }
}
