var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","flat":"","title":_vm.$t('status'),"left":""},on:{"click":function($event){return _vm.sendView()}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s("mdi-bell-check"))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("listNotication"))+" "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headerSend,"hide-default-footer":"","pageHeaderConfig":_vm.pageHeader,"items":_vm.list,"tagActive":"active","single-expand":true,"item-key":"id","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.wasSent",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.wasSent ? '#00FA9A' : '#DC143C'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.wasSent ? "mdi-check-circle" : "mdi-close-circle-outline")+" ")])]}}],null,true)},[(item.wasSent)?_c('span',{staticClass:"text-true"},[_vm._v(_vm._s(_vm.$t("sent")))]):_c('span',{staticClass:"text-false"},[_vm._v(_vm._s(_vm.$t("NotSent")))])])]}},{key:"item.logError",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.logError === "CONFIG_OMINI_NOT_FOUND" ? _vm.$t("error.CONFIG_OMINI_NOT_FOUND") : item.logError)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.onSave(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-send ")])]}}],null,true)},[_c('span',{staticClass:"text-true"},[_vm._v(_vm._s(_vm.$t("resend")))])])]}}],null,true)}),_c('v-card-actions',{staticClass:"card-padding primary"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(_vm._s(_vm.$t("itemsPerPage")))]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.loadPagination},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa-arrow-left","next-icon":"fa-arrow-right","color":"#d3075e","length":_vm.pageCount,"circle":"","total-visible":10},on:{"input":_vm.loadPagination},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }