export const general = {
  namespaced: true,
  state: () => ({
    loading: false,
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white",
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false,
    newLogo: ''
  }),
  mutations: {
    loading(state: { loading: boolean }, value: boolean) {
      state.loading = value;
    },

    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_LOGO(state, payload) {
      state.newLogo = payload;
    },
  },
  actions: {
    startLoading(context) {
      context.commit("loading", true);
    },
    endLoading(context) {
      context.commit("loading", false);
    },
  },
  getters: {
    isLoading(state: { loading: boolean }) {
      return state.loading;
    },
  },
};
