var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-padding-zero"},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('PageHeader',{attrs:{"header":_vm.pageHeader}}),_c('v-data-table',{attrs:{"headers":_vm.header,"hide-default-footer":"","pageHeaderConfig":_vm.pageHeader,"items":Array.isArray(_vm.list) ? _vm.list : [_vm.list],"tagActive":"active","single-expand":true,"item-key":"id","search":_vm.search,"page":_vm.page1,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page1=$event},"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('br'),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('filter'),"hint":_vm.$t('searchItem'),"outlined":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"icons"},[_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['MNT', 'VI']),expression:"['MNT', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
            name: 'MaintenanceList',
            query: { item: item.redirectionId },
          }}},[_c('v-icon',{attrs:{"dense":"","title":_vm.$t('seeMaintenance')}},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)]}}],null,true)}),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(_vm._s(_vm.$t("itemsPerPage")))]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.loadData},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa-arrow-left","next-icon":"fa-arrow-right","color":"#d3075e","length":_vm.pageCount,"circle":"","total-visible":10},on:{"input":_vm.loadData},model:{value:(_vm.page1),callback:function ($$v) {_vm.page1=$$v},expression:"page1"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }