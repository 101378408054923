










































































































import { Component, Prop } from "vue-property-decorator";
import { Channel } from "../../types/Channel";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";

import { mapGetters } from "vuex";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ChannelDetailInput extends Request {
  @Prop() readonly entity: Channel;
  @Prop() readonly list: Channel[];
  @Prop() readonly loadData: () => void;
  @Prop() readonly channel: number;
  readonly header = `${translate("addChannels")}`;
  show1:boolean = false;
  dialog: boolean = false;
  localEntity: any = {};
  getUser: any;
  editorUserId: number = null;
  load(): void {
    if (this.entity) {
      this.localEntity = {
        ...this.entity,
      };
    }
  }
  async onSave(): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;
    this.localEntity.channelId = this.channel;

    const result = await this.save(this.paths.channelDetailPath, {
      ...this.localEntity,
      editorUserId: this.editorUserId,
    });

    this.loadData();
    this.dialog = false
    return result;
  }

  async mounted() {
    this.load();
  }
}
