var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-padding-zero"},[_c('BaseBreadcrumb',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}}),_c('PageHeader',{attrs:{"header":_vm.pageHeader}}),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"headers":_vm.header,"hide-default-footer":"","pageHeaderConfig":_vm.pageHeader,"items":_vm.list,"tagActive":"active","item-key":"id","search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"showExpand":true,"single-expand":true,"expanded":_vm.expanded},on:{"update:page":function($event){_vm.page=$event},"item-expanded":_vm.loadDetails,"update:expanded":function($event){_vm.expanded=$event},"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pl-5 pr-5"},[_c('v-col',{attrs:{"cols":_vm.selectList == 2 || _vm.selectList == 3 ? 1 : 2}},[_c('v-select',{attrs:{"label":_vm.$t('searchType'),"outlined":"","item-text":"name","items":_vm.selectSeach,"item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.selectList),callback:function ($$v) {_vm.selectList=$$v},expression:"selectList"}})],1),(_vm.selectList == 2)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"search-input":_vm.searchGroup,"label":_vm.$t('selectGroup'),"outlined":"","item-text":"name","item-value":"groupId","clearable":true,"items":_vm.groupList,"return-object":true},on:{"update:searchInput":function($event){_vm.searchGroup=$event},"update:search-input":function($event){_vm.searchGroup=$event},"change":_vm.handleClearGroup},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,4286803846),model:{value:(_vm.selectGroup),callback:function ($$v) {_vm.selectGroup=$$v},expression:"selectGroup"}})],1):_vm._e(),(_vm.selectList == 3)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"search-input":_vm.searchHost,"label":_vm.$t('selectHost'),"outlined":"","item-text":"name","item-value":"hostId","clearable":true,"items":_vm.hostList,"return-object":true},on:{"update:searchInput":function($event){_vm.searchHost=$event},"update:search-input":function($event){_vm.searchHost=$event},"change":_vm.handleClearHost},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,4286803846),model:{value:(_vm.selectHost),callback:function ($$v) {_vm.selectHost=$$v},expression:"selectHost"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":_vm.$t('selectSeverity') + ':',"outlined":"","item-text":"name","items":_vm.selectSeverity,"item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.selectSeverityList),callback:function ($$v) {_vm.selectSeverityList=$$v},expression:"selectSeverityList"}})],1),_c('v-col',{attrs:{"cols":_vm.selectList == 2 || _vm.selectList == 3 ? 1 : 2}},[_c('v-select',{attrs:{"label":_vm.$t('acknowledged') + ':',"outlined":"","item-text":"name","items":_vm.selectAcknowledged,"item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.selectAcknowledgedList),callback:function ($$v) {_vm.selectAcknowledgedList=$$v},expression:"selectAcknowledgedList"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menuStartDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('startDate'),"prepend-icon":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.originalStartDate),callback:function ($$v) {_vm.originalStartDate=$$v},expression:"originalStartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menuEndDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('endDate'),"prepend-icon":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.originalEndDate),callback:function ($$v) {_vm.originalEndDate=$$v},expression:"originalEndDate"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.saveDisabled},on:{"click":function($event){return _vm.seach(true)}}},[_vm._v(_vm._s(_vm.$t("search"))+" ")])],1),_c('v-col',{staticClass:"mt-40",attrs:{"cols":"12"}},[(_vm.validDateInverted)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("searchStart"))+" ")]):_vm._e()])],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":_vm.getStatusClass(item.status)}},[_c('span',[_vm._v(" "+_vm._s(_vm.getStatusTranslation(item.status))+" ")])])]}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(_vm.getSeverityStyle(item.severity))},[_vm._v(" "+_vm._s(_vm.translateSeverity(item.severity))+" ")])]}},{key:"item.event.source",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getSourceTranslation(item.source))+" ")])]}},{key:"item.acknowledged",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(_vm.getAcknowledgedClass(item.acknowledged))},[_vm._v(" "+_vm._s(_vm.getAcknowledgedTranslation(item.acknowledged))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.headerAlert,"items":[_vm.listAlertDetails],"tagActive":"active","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.timeToAcknowledged",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: '#778899' })},[_vm._v(" "+_vm._s(item.timeToAcknowledged)+" ")])]}}],null,true)})],1)]}}],null,true)})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(_vm._s(_vm.$t("itemsPerPage")))]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.seach},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa-arrow-left","next-icon":"fa-arrow-right","color":"#d3075e","length":_vm.pageCount,"circle":"","total-visible":10},on:{"input":_vm.seach},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }