






















































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { HeaderItem } from "../../types/HeaderItem";
import { Breadcrumb } from "../../types/Breadcrumb";
import { page } from "../../types/Page";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { TranslateResult } from "vue-i18n";
import moment from "moment";
import { itemsPerPage } from "../../types/itemsPerPage";
@Component({
  components: {
    List,
    BaseBreadcrumb,
  },
})
export default class HistoryMetaXChatbots extends Request {
  page: page = {
    title: `${translate("history")}`,
    icon: null,
  };
  pagePagination: number = 1;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("metaXchatbot")}`,
      disabled: false,
      to: "/company-report/",
    },
    {
      text: `${translate("history")}`,
      disabled: true,
    },
  ];

  header: HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "fileName" },
    {
      text: `${translate("createdAt")}`,
      align: "start",
      value: "createdAt",
    },
    {
      text: `${translate("month")}`,
      align: "start",
      value: "month",
    },
    {
      text: `${translate("typeFile")}`,
      align: "start",
      value: "typeFile",
    },
    {
      text: `${translate("inconsistency")}`,
      align: "start",
      value: "inconsistency",
    },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-history",
    titlePage: `${translate("history")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  customColumns = [
    {
      slotName: "inconsistency",
      itemValue: "inconsistency",
    },
  ];
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  pageCount: number = 0;
  itemsPerPage: number = 10;
  list: any[] = [];
  defineColor(value: any) {
    switch (true) {
      case value === 0:
        return "#32906b";
      case value > 0:
        return "#ff0000";
      case value === "--" || value === null || value === undefined || value < 0:
        return "";
      default:
        return "";
    }
  }
  async loadData(): Promise<void> {
    const result = await this.get(
      this.paths.logSheetPath +
        `?take=${this.itemsPerPage}&skip=${this.pagePagination}`
    );
    this.list = result[0];
    this.pageCount = Math.ceil(result[1].count / this.itemsPerPage);
  }
  async loadPagination() {
    const resultPage = await this.get(
      this.paths.logSheetPath +
        `?take=${this.itemsPerPage}&skip=${this.pagePagination}`
    );

    this.list = resultPage[0];
    this.pageCount = Math.ceil(resultPage[1].count / this.itemsPerPage);
  }
  updateOptions() {
    this.loadData();
  }
  translateMonth = (month: string): TranslateResult => {
    return this.monthTranslations[month] || "";
  };
  translateType = (type: string): TranslateResult => {
    return this.typeTranslations[type] || "";
  };
  typeTranslations: { [key: string]: TranslateResult } = {
    C: translate("conversation"),
    A: translate("attendency"),
  };
  monthTranslations: { [key: string]: TranslateResult } = {
    "01": translate("january"),
    "02": translate("february"),
    "03": translate("march"),
    "04": translate("april"),
    "05": translate("may"),
    "06": translate("june"),
    "07": translate("july"),
    "08": translate("august"),
    "09": translate("september"),
    "10": translate("october"),
    "11": translate("november"),
    "12": translate("december"),
  };
  get fullList() {
    const dataList = this.list;

    return dataList.map((item) => {
      return {
        ...item,
        createdAt: item.createdAt
          ? moment(item.createdAt).format("DD/MM/YYYY")
          : "--",
        fileName: item.fileName ?? "--",
        inconsistency: item.inconsistency <= 0 ? "--" : item.inconsistency,
        month: item.month ? this.translateMonth(item.month) : "--",
        typeFile: item.typeFile ? this.translateType(item.typeFile) : "--",
        updatedAt: item.updatedAt ?? "--",
        urlFile: item.urlFile ?? "--",
      };
    });
  }
  downloadFile(urlFile: string) {
    if (!urlFile) {
      return;
    }

    const link = document.createElement("a");
    link.href = urlFile;

    const fileName = urlFile.split("/").pop() || "download";
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }
}
