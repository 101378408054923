







































































































































































































import { Datetime } from "vue-datetime";
import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";
import moment from "moment";

@Component({
  components: {
    Datetime,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ProductInput extends Request {
  @Prop() readonly entity: any;
  @Prop() readonly list: any[];
  @Prop() readonly loadData: any;

  tab = null;
  getUser: any;
  items = [
    {
      id: 1,
      name: `${translate("massiveConfiguration")}`,
    },
    {
      id: 2,
      name: `${translate("localization")}`,
    },
  ];
  readonly header = `${translate("editMassive")}`;
  companyId: number = null;
  path: string | undefined = "";
  dialog = false;
  localEntity: any = {};
  listDetails: any = [];
  dateEnd: any = "";
  dateStart: any = "";
  editorUserId: any;
  active: any = true;

  onSave() {
    this.dialog = false;
    return this.newList();
  }
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async newList() {
    const user = this.getUser();
    this.editorUserId = user.id;

    this.localEntity.companyId = this.companyId;
    this.localEntity.massiveDetails;
    this.localEntity.massiveDetails = this.listDetails;

    if (this.localEntity.id) {
      await this.post(this.paths.massivePathSave, {
        ...this.localEntity,
        editorUserId: this.editorUserId,
        active: this.active === true ? "1" : "0",
      });
    } else {
      await this.save(this.paths.massivePathSave, {
        ...this.localEntity,
        editorUserId: this.editorUserId,
        active: "1",
      });
    }

    if (!this.entity.id) {
      this.localEntity = {};
      this.listDetails = [
        {
          region: "",
          uf: "",
          city: "",
          isMassive: true,
        },
      ];
    }
    this.tab = 0;
    return this.loadData();
  }
  async mounted() {
    if (!this.entity.id) {
      this.listDetails.push({
        region: this.localEntity.region,
        uf: this.localEntity.uf,
        city: this.localEntity.city,
        isMassive: true,
      });
    }

    if (this.entity.massiveDetails) {
      this.listDetails = this.entity.massiveDetails;
    }
    const user = this.getUser();
    this.companyId = user.companyId.id;
    this.load();

    if (this.listDetails.length === 0) {
      this.listDetails.push({});
    }
    this.localEntity.dateEnd = moment(this.localEntity.dateEnd).format();
    this.localEntity.dateStart = moment(this.localEntity.dateStart).format();
  }
  close() {
    if (!this.entity.id) {
      this.localEntity = {};
      this.listDetails = [
        {
          region: "",
          uf: "",
          city: "",
          isMassive: true,
        },
      ];
    }
    this.dialog = false;
  }
  addComponent(): void {
    this.listDetails.push({
      region: this.localEntity.region,
      uf: this.localEntity.uf,
      city: this.localEntity.city,
      isMassive: true,
    });
  }

  get missingName() {
    return !this.localEntity.name;
  }

  get missingUf() {
    return this.listDetails.some((item) => !item.uf);
  }

  get missingCity() {
    return this.listDetails.some((item) => !item.city);
  }
  get missingRe() {
    return this.listDetails.some((item) => !item.region);
  }
  get nextDisabled() {
    return this.missingName;
  }

  get saveDisabled() {
    return (
      this.missingUf || this.missingCity || this.missingRe || this.missingName
    );
  }
}
