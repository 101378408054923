













































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";

@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class DeleteCompanyProductPlan extends Request {
  @Prop() companyProductPlanId: number;
  @Prop() entityDelete: any;
  companyId = this.$route.query.companyId;
  readonly header = `${translate("addChannels")}`;
  entity: any = {};
  dialog: boolean = false;
  active: any = true;
  editorUserId:any
  getUser:any
  onDelete() {
    const user = this.getUser();
    this.editorUserId = user.id;

    this.entity = this.entityDelete;
    this.entity.active = !this.entity.active;
    this.entity.productId = this.entity.id;
    this.put(this.paths.companyInactivePath + this.entity.id,{
      active:this.entity.active === true ? "1" : "0",
      editorUserId:this.editorUserId
    
    });
    this.dialog = false;
    return this.$emit("is-active", this.entity);
  }

  get buttonIcons() {
    return this.entityDelete.active === true
      ? {
          icon: "mdi-close-circle",
          title: `${translate("inactivate")}`,
          color: "#ff0000",
        }
      : {
          icon: "mdi-check-circle",
          title: `${translate("activate")}`,
          color: "#008000",
        };
  }
}
