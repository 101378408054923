



























































































































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import MaintenanceInput from "./MaintenanceInput.vue";
import { translate } from "../../plugins/i18n/index";
import { IMaintenance } from "../../types/Maintenance/IMaintenance";
import { TStatusList} from "../../types/Maintenance/TStatusList";
import { mapGetters } from "vuex";
import { IGetUser } from "../../types/IGetUser";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import moment from "moment";
@Component({
  components: {
    MaintenanceInput,
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ChangeStatus extends Request {
  @Prop() entity: IMaintenance;
  @Prop() readonly manutentionId: number;
  @Prop() loadData: () => void;
  selectedStatus: number = null;
  observation: string = "";
  reschedule: boolean = false;
  rescheduleLabel= this.$t("rescheduleMessage");
  closeDialog() {
    return this.$emit("close-dialog", false);
  }
  statusList: TStatusList[] = [
 //   {
 //     description: `${translate("waitingAuthorization")}`,
 //     id: 1,
 //    },
 //  {
 //     description: `${translate("scheduled")}`,
 //    id: 2,
 //  },
    {
      description: `${translate("running")}`,
      id: 3,
    },
    {
      description: `${translate("canceled")}`,
      id: 4,
    },
    {
      description: `${translate("completed")}`,
      id: 5,
    },
  // {
  //    description: `${translate("notAuthorized")}`,
  //   id: 6,
  // }
  ];
  productList: any = [];
  selectedProduct: any = null;
  date: any = null;
  menu:boolean = false;
  startDateTime:string = null;
  startDateMenu:boolean = false;
  endDateTime:string = null;
  endtDateMenu:boolean = false;
  requester: string = "";
  editorUserId:number;
  user:IGetUser;
  getUser:any;
  technicalManagerList: any = [];
  technicalManager: any = null;
  authorizations: any = null;
  authorizationsList: any = [];
  explanation: string = "";
  risk: string = "";
  ascUser: string = "";
  ascOs: string = "";
  ticketMatrix: any = null;
  clientUser: any = null;
  description: string = "";

  async mounted() {

    this.user = this.getUser();
    this.editorUserId = this.user.id;


    if (this.selectedStatus === 4) {
      this.reschedule = true;
    }
    if (this.entity.id) {
      this.date = moment(new Date(this.entity.date))
      this.startDateTime = this.entity.initialTime;
      this.endDateTime = this.entity.finalTime;
      this.technicalManager = this.entity.executor;
      this.selectedProduct = this.entity.productId;
      this.selectedStatus = this.entity.status;
      this.observation = this.entity.statusObservation;
      this.explanation = this.entity.explanation;
      this.risk = this.entity.risk;
      this.ascUser = this.entity.ascUser;
      this.ascOs = this.entity.ascOs;
      this.ticketMatrix = this.entity.ticketMatrix;
      this.clientUser = this.entity.clientUser;
      this.description = this.entity.description;
   
    }
    const user = this.$store.getters["auth/getUser"];
    this.requester = user.firstName;
    const product = await this.get(this.paths.productPath);
    this.productList = product[0];

    const users = await this.get(this.paths.userPath);
    this.technicalManagerList = users[0];

    const authorizations = await this.get(this.paths.userPath);
    this.authorizationsList = authorizations[0];
  }
  async onSave(): Promise<any> {
   
 
    const localEntity = {
      id: this.entity.id,
      status: this.selectedStatus,
    };
   
    const result = await this.patch(this.paths.maintenanceStatusPath + this.entity.id, {
      ...localEntity,
      editorUserId: this.editorUserId,
      
  });

    // this.authorizations.map(async (item) => {
    //   const entityAuthorization = {
    //     manutentionId: result.id,
    //     authorizationUserId: item,
    //   };
    //   return await this.save(
    //     this.paths.maintenanceAuthorizationPath,
    //     entityAuthorization
    //   );
    // });
    //this.updateStatus();

  this.loadData();
    this.$emit("update-dialog", false);
    return result;
  }
  async updateStatus() {

    this.entity.status = this.selectedStatus;
    this.entity.executor = this.technicalManager.id;
    const result = await this.save(this.paths.maintenanceStatusPath, {...this.entity,
      editorUserId: this.editorUserId,
    });
    this.loadData();
    return result;
  }

}
