














































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import { PlanDetail } from "../../types/PlanDetail";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import PlanDetailInput from "./PlanDetailInput.vue";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PlanDetailInput,
  },
})
export default class PlanDetailList extends Request {
  path = paths.planDetailPath;
  planId = this.$route.query.planId;
  productName = this.$route.query.productName;
  page = {
    title: `${translate("planDetail")}`,
    icon: null,
  };

  breadcrumbs = [
    {
      text: `${translate("product")}`,
      disabled: false,
      to: "/product/",
    },
    {
      text: `${translate("plan")}`,
      disabled: false,
      to: {
        name: "PlanList",
        query: {
          productId: this.$route.query.productId,
          productName: this.$route.query.productName,
        },
      },
    },
    {
      text: `${translate("planDetail")}`,
      disabled: true,
    },
  ];

  header = [
    { text: `${translate("name")}`, value: "name" },
    { text: `${translate("value")}`, align: "center", value: "value" },
    { text: `${translate("status")}`, align: "center", value: "active" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-notebook-multiple",
    titlePage: `${translate("planDetail")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: PlanDetail[] = [];
  async mounted(): Promise<void> {
    this.productName = this.$route.query.productName;
    const result = await this.get(this.paths.planPath, {
      planId: this.planId,
    });

    if (result) {
      this.list = result[0].find((i) => i.id === this.planId).planDetails;
    }
  }
}
