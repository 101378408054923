var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-padding-zero"},[_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"ring"}),_c('span',{staticClass:"text-loading"},[_vm._v(_vm._s(_vm.$t("loading")))])])]),_c('BaseBreadcrumb',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}}),_c('List',{attrs:{"header":_vm.header,"pageHeaderConfig":_vm.pageHeader,"items":_vm.list,"itemCnpj":true,"tagActive":"active","customColumns":_vm.customColumns,"searchInput":false,"item-key":"id","page":_vm.page,"itemsPerPage":_vm.itemsPerPage,"pageCount":_vm.pageCount,"updateOptions":_vm.updateOptions},scopedSlots:_vm._u([{key:"month",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.resolveMonth(item.month)))]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","title":_vm.$t('download')},on:{"click":function($event){return _vm.generateDownload(item.urlFile)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('template',{staticClass:"ml-5 mr-5",slot:"filters"},[_c('v-row',{staticClass:"pl-5 pr-5"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.productList,"label":_vm.$t('product'),"placeholder":_vm.$t('product'),"outlined":"","item-text":"name","item-value":"value","return-object":false,"clearable":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('date-picker',{attrs:{"type":"year","valueType":"format","placeholder":_vm.$t('year')},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.monthList,"label":_vm.$t('month'),"placeholder":_vm.$t('month'),"outlined":"","item-text":"title","item-value":"id","return-object":false,"clearable":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}}]),model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1)],1)],1),_c('template',{slot:"pagination"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(_vm._s(_vm.$t("itemsPerPage")))]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.loadData},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa-arrow-left","next-icon":"fa-arrow-right","color":"#d3075e","length":_vm.pageCount,"circle":"","total-visible":10},on:{"input":_vm.loadData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }