import dotenv from 'dotenv'

const enviroment: {[key: string]: string} = {
  local: '.env.local',
  prod: '.env',
  test: '.env.test'
}

dotenv.config({  
  path: enviroment[process.env.NODE_ENV ?? 'prod']
})


export default {
  appMatrix: process.env.VUE_APP_MATRIX_APP ?? 'MKP',
  protocol: process.env.VUE_APP_PROXY_PROTOCOL ?? '',
  serviceDiscovery: process.env.VUE_APP_MATRIX_SERVICE_DISCOVERY_API_URL ?? '',
  socket: process.env.VUE_APP_MATRIX_SOCKET_API_URL ?? ''
}
