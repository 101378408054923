













































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { TProduct } from "../../types/Company/TProduct";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { INotification } from "../../types/Notification/INotification";
import { TCompanyNotification } from "../../types/Notification/TCompanyNotification";
import { mapGetters } from "vuex";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class NotificationInput extends Request {
  @Prop() readonly entity: INotification;
  @Prop() readonly list: INotification [];
  @Prop() readonly loadData: () => void;
  @Prop() readonly productId: TProduct;
  @Prop() readonly entriesCompanyList: TCompanyNotification[];

  readonly header = `${translate("title.notification")}`;
  companyTypeIds: any = [];
  dialog: boolean = false;
  templateTypeList: [] = [];
  imageUrl: any = [];
  attachment: any = null;
  attachmentFile: any = null;
  editorUserId: number = null;
  getUser: any;
  notificationListStatus: any;
  valid: boolean = false;
  isSelectAllCompany: boolean = false;
  blob: any = null;
  isSendToAll: boolean = false;
  searchCompany: any = null;
  editorOption = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      ["link", "image", "video"], // Adiciona o botão "image" e "video" à barra de ferramentas
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
      [{ video: true }],
      ["customButton"],
    ],
    clipboard: {
      // Adiciona suporte para a cópia e colagem de imagens diretamente no editor
      matchers: [
        [
          "img",
          function (node, delta) {
            return delta.compose([
              { insert: { image: node.getAttribute("src") } },
            ]);
          },
        ],
      ],
    },

    placeholder: `${translate("insertHtml")}`,

    theme: "snow",
  };
  entriesCompany: any = [];
  showVideoModal = false;
  videoUrl = "";
  title: string | undefined = "";
  templateTypeId: [] = [];
  message: string = "";
  active: boolean = true;
  localEntity: any = [];
  itemsPerPage: number = 10;
  page: number = 1;
  pageCount: number = 0;

  close() {
    this.dialog = false;
  }
  async onSave(): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;

    let companies = [];

    for (const companyId of this.companyTypeIds) {
      if (companyId === 0) {
        this.isSendToAll = true;
        companies = [];
      } else {
        companies.push({ id: companyId });
      }
    }

    //const htmlMessage = `<html><body>${this.localEntity.message}</body></html>`;

    const result = await this.save(this.paths.notificationCreateSend, {
      notificationType: 1,
      title: this.localEntity.title,
      description: this.localEntity.message,
      productId: this.productId.id,
      editorUserId: this.editorUserId,
      companies: companies,
      isAll: this.isSendToAll,
    });

    if (!this.localEntity.id) {
      this.localEntity.title = "";
      this.localEntity.message = "";
      this.companyTypeIds = [];
    }
    this.dialog = false;
    this.loadData();
    return result;
  }
  async load(): Promise<void> {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async mounted() {
    this.load();

    if (this.dialog) {
      await this.sendView();
    }
  }
  removeCompanyType(id) {
    const index = this.companyTypeIds.indexOf(id);
    if (index !== -1) {
      this.companyTypeIds.splice(index, 1);
    }
  }
  async sendView() {
    const notificationListStatus = await this.get(
      this.paths.notificationListView +
        this.entity.id +
        `?&skip=${this.page}&take=${this.itemsPerPage}`
    );

    if (notificationListStatus) {
      this.notificationListStatus = notificationListStatus.result;
    }

    if (this.localEntity.id) {
      this.localEntity.title = this.entity.title;
      this.localEntity.message = this.entity.description;
      this.companyTypeIds = this.notificationListStatus.map((item) => {
        return item.company;
      });
      this.valid = true;
    }
  }

  get missingTitle() {
    return !this.localEntity.title;
  }
  get missingCompanyTypeIds() {
    return !this.companyTypeIds || this.companyTypeIds.length === 0;
  }
  get saveDisabled() {
    return this.missingTitle || this.missingCompanyTypeIds;
  }

  customFilter(item, queryText, itemText) {
    item;
    const searchText = queryText.toLowerCase();
    const itemValue = itemText.toLowerCase();
    return itemValue.includes(searchText);
  }
  @Watch("searchCompany")
  async searchCompanies(val) {
    if (val && val.length >= 3) {
      const list = await this.get(
        this.paths.companyPagePath +
          `?take=${this.itemsPerPage}&skip=${1}&searchValue=${val}`
      );
      this.entriesCompany = [...this.entriesCompany, ...list.result];
    }
    return this.$emit("entries-company", this.entriesCompany);
  }
}
