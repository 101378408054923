






























































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { IIntegration} from "../../types/Integration/IIntegration";
import { TErp} from "../../types/Integration/TErp";
import { SelectErp } from "../../types/Integration/SelectErp";
import { IGetUser } from "../../types/IGetUser";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

@Component({
  directives: { mask },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class IntegrationInput extends Request {
  @Prop() readonly erpList: TErp;
  @Prop() readonly entity: IIntegration;
  @Prop() readonly list: IIntegration [];
  @Prop() readonly loadData: ()=> void;
  dialog: boolean = false;
  readonly header = `${translate("editIntegration")}`;
  selectedErp: SelectErp = null;
  companyId = this.$route.query.companyId;
  port: number = null;
  primaryOffer: boolean = false;
  config: string = "";
  name: string = "";
  active: boolean = true;
  rules: any = [(v) => (v && v.length >= 5) || `${translate("minNumber")}`];
  localEntity: any = {};
  editorUserId: number = null;
  getUser: any;
  user: IGetUser ;

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  async onSave(): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;

    this.editorUserId = user.id;

    let configWithSlash = JSON.stringify(this.config);

    let result;

    if (this.localEntity.id) {
      result = await this.put(
        this.paths.integrationUpDatePath + "/" + this.localEntity.id,
        {
          erpId: this.selectedErp,
          companyId: this.companyId,
          port: this.localEntity.port,
          editorUserId: this.editorUserId,
          config: configWithSlash,
          isTalk: this.localEntity.isTalk,
          isOmini: this.localEntity.isOmini,
          active: this.active,
        }
      );
      this.loadData();
      result === undefined && (this.localEntity.active = false);
    } else {
      result = await this.save(this.paths.integrationPathSave, {
        erpId: this.selectedErp,
        companyId: this.companyId,
        port: this.localEntity.port,
        editorUserId: this.editorUserId,
        config: configWithSlash,
        isTalk: this.localEntity.isTalk,
        isOmini: this.localEntity.isOmini,
        active: this.active == true ? true : false,
      });
    }

    this.dialog = result.message ? true : false;

    if (!this.localEntity.id) {
      if (!result.message) {
        this.selectedErp = null;
        (this.config = ""), (this.rules = []);

        this.localEntity = {
          id: null,
          erpId: null,
          companyId: "",
          port: "",
          editorUserId: "",
          config: " ",
          isTalk: false,
          isOmini: false,
        };
      }
    }

    this.loadData();

    return result;
  }

  async mounted() {
    this.load();
    const user = this.getUser();
    this.editorUserId = user.id;

    if (this.localEntity.id) {
      this.selectedErp = this.localEntity.erpId;
      this.config = JSON.parse(this.localEntity.config);

      this.load();
    }
  }
  cancelDialog() {
    if (!this.localEntity.id) {
      this.selectedErp = null;
      (this.config = ""), (this.rules = []);

      this.localEntity = {
        id: null,
        erpId: null,
        companyId: "",
        port: "",
        editorUserId: "",
        config: " ",
        isTalk: false,
        isOmini: false,
      };
    }
    this.dialog = false;
  }

  get missingSelectedErp() {
    return !this.selectedErp;
  }
  get missingConfig() {
    return !this.config;
  }

  get missingPort() {
    return !this.localEntity.port;
  }
  get missingPortMin() {
    return this.localEntity.port.length < 5;
  }

  get saveDisabled() {
    return (
      this.missingPort ||
      this.missingSelectedErp ||
      this.missingConfig ||
      this.missingPortMin
    );
  }
}
