



















































































































import { Component, Prop } from "vue-property-decorator";
import { IProduct } from "../../types/Product/IProduct";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import moment from "moment";
import { IGetUser } from "../../types/IGetUser";
import { mapGetters } from "vuex";

@Component({
  components: {},
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ProductInput extends Request {
  @Prop() readonly entity: IProduct;
  @Prop() readonly list: IProduct[];
  @Prop() readonly loadData: () => void;
  readonly header = `${translate("editProduct")}`;
  getUser: any;
  dialog:boolean = false;
  localEntity: any = {};
  active:any = null;
  name:string = "";
  description: string | undefined = "";
  site: string = null;
  logo: string | undefined = "";
  scope:string = "";
  user: IGetUser;
  editorUserId:number;

  async onSave(): Promise<any> {
    this.dialog = false;

    await this.newList();
    if (!this.entity.id) {
      this.name = "";
      this.description = "";
      this.scope = "";
      this.site = "";
    }

    this.loadData();
  }

  load(): void {
    if (this.entity) {
      this.localEntity = {
        ...this.entity,
      };
    }
  }

  async newList() {
    const user = this.getUser();
    this.editorUserId = user.id;

    let result;

    Object.assign(this.entity, this.localEntity);
    this.entity.name = this.name;
    this.entity.description = this.description;
    this.entity.active = this.active;
    this.entity.scope = this.scope;
    this.entity.scope = this.scope;
    this.entity.site = this.site;
    this.entity.logo = this.logo;

    if (this.localEntity.id) {
      result = await this.put(
        this.paths.productUpdatePath + this.localEntity.id,
        {
          ...this.entity,
          editorUserId: this.editorUserId,
        }
      );
      result.updatedAt = moment(result.updatedAt).format("DD/MM/YYYY");
    } else {
      result = await this.save(this.paths.productSaveValidEscopePath, {
        ...this.entity,
        editorUserId: this.editorUserId,
      });
      result.updatedAt = moment(result.updatedAt).format("DD/MM/YYYY");
    }
    if (!this.entity.id) {
      this.name = "";
      this.description = "";
      this.scope = "";
      this.site = "";
    }

    this.dialog = false;

    this.loadData();

    return result;
  }

  async mounted() {
    this.load();
    this.name = this.entity.name;
    this.description = this.entity.description;
    this.active = this.entity.active;
    this.scope = this.entity.scope;
    this.logo = this.entity.logo;
    this.site = this.entity.site;
  }
  uppercase() {
    this.scope = this.scope.toUpperCase();
  }
  cancelDialog() {
    if (!this.entity.id) {
      this.name = "";
      this.description = "";
      this.active = "";
      this.scope = "";
      this.scope = "";
      this.site = "";
      this.logo = "";
    }
    this.dialog = false;
  }
  get missingName() {
    return !this.name;
  }
  get missingscope() {
    return !this.scope;
  }
  get saveDisabled() {
    return this.missingName || this.missingscope;
  }
}
