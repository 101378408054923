


















import { Component, Prop } from "vue-property-decorator";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import { IProductConfig } from "../../types/IProductConfig";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
//import moment from "moment";
@Component({
  components: { InputPopup },
})
export default class ProductConfigInput extends Request {
  @Prop() readonly entity: IProductConfig;
  @Prop() readonly list: IProductConfig[];
  @Prop() productId: number;
  active: boolean = true;
  readonly header = `${translate("addConfiguration")}`;

  async onSave(): Promise<any> {
    if (this.entity) {
      this.entity.productId = this.productId;
      if (!this.active) {
        this.entity.active = this.active;
      } else {
        this.entity.active = this.active;
      }
      return await this.save(this.paths.configPath, this.entity);
    }
  }

  itemsForm: InputModel[] = [
    {
      id: 1,
      col: 6,
      label: `${translate("name")}`,
      tag: "v-text-field",
      type: "text",
      model: "description",
      required: true,
    },
    {
      id: 2,
      col: 6,
      label: `${translate("storageType")}`,
      tag: "v-select",
      model: "storageType",
      required: true,
      list: [
        {
          id: "S3",
          name: "AWS S3",
        },
        {
          id: "BD",
          name: "Banco de Dados",
        },
        {
          id: "SL",
          name: "Servidor Local",
        },
      ],
    },
    {
      id: 4,
      col: 4,
      label: `${translate("storageHost")}`,
      tag: "v-text-field",
      type: "text",
      model: "storageHost",
    },
    {
      id: 5,
      col: 4,
      label: `${translate("storagePort")}`,
      tag: "v-text-field",
      type: "number",
      model: "storagePort",
    },
    {
      id: 6,
      col: 4,
      label: `${translate("storageDefaultRegion")}`,
      tag: "v-text-field",
      type: "text",
      model: "storageDefaultRegion",
    },

    {
      id: 7,
      col: 4,
      label: `${translate("storageName")}`,
      tag: "v-text-field",
      type: "text",
      model: "storageName",
    },
    {
      id: 8,
      col: 4,
      label: `${translate("storageUserName")}`,
      tag: "v-text-field",
      type: "text",
      model: "storageUsername",
    },
    {
      id: 9,
      col: 4,
      label: `${translate("storagePassword")}`,
      tag: "v-text-field",
      type: "text",
      model: "storagePassword",
    },
    {
      id: 10,
      col: 2,
      slot: "active",
    },
  ];
}
