























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { TPlan } from "../../types/Company/TPlan";
import { translate } from "../../plugins/i18n/index";
import { mask } from "vue-the-mask";
import { TProduct } from "../../types/Company/TProduct";
import { TproductPlan } from "../../types/CompanyProduct/TProducPLan";
import { ICompany } from "../../types/Company/ICompany";
import { IGetUser } from "../../types/IGetUser";
import moment from "moment";
import { mapGetters } from "vuex";
@Component({
  components: {},
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },

  directives: { mask },
})
export default class CompanyInput extends Request {
  @Prop() readonly productList: TProduct[];
  @Prop() readonly entity: any;
  @Prop() readonly list: ICompany[];
  @Prop() readonly loadData: () => void;
  readonly header = `${translate("editProduct")}`;
  companyId = this.$route.query.companyId;
  dialog: boolean = false;
  localEntity: ICompany = {};
  listProducts: TproductPlan[] = [];
  listPlans: TPlan[] = [];
  selectedPlan: any = null;
  selectedProduct: TProduct = {};
  getUser: any;
  user: IGetUser;
  editorUserId: number = null;

  async onSave(): Promise<any> {
    this.dialog = false;
    return await this.newList();
  }

  load(): void {
    if (this.entity) {
      this.localEntity = {
        ...this.entity,
      };
    }
  }
  async newList() {
    const user = this.getUser();
    this.editorUserId = user.id;

    let result;

    if (this.localEntity.id) {
      Object.assign(this.entity, this.localEntity);
      this.entity.companyProductPlan.productId = this.selectedProduct.id;
      this.entity.companyProductPlan.planId = this.selectedPlan;
      this.entity.companyProductPlan.editorUserId = this.editorUserId;

      result = await this.put(
        this.paths.companyProductPlanPath,
        this.entity.companyProductPlan
      );
    } else {
      result = await this.post(this.paths.createCommpanyProductPlanPath, {
        ...this.entity,
        productId: this.selectedProduct.id,
        planId: this.selectedPlan?.id,
        companyId: this.companyId,
        editorUserId: this.editorUserId,
      });
    }

    if (!this.localEntity.id) {
      this.selectedProduct = null;
      this.selectedPlan = null;
    }

    result.updatedAt = moment(result.updatedAt).format("DD/MM/YYYY");
    this.loadData();
  }
  async chargePlan(value) {
    const productId = value.id;
    const result = this.productList;
    this.listPlans = result.find((i) => i.id === productId).plans;
  }
  disableItem(item) {
    const isSelected = this.list.find((x) => x.id === item.id);
    if (isSelected) return true;
    if (item.active === true) {
      return false;
    } else {
      return true;
    }
  }
  cancelDialog() {
    if (!this.localEntity.id) {
      this.selectedProduct = null;
      this.selectedPlan = null;
    }
    this.dialog = false;
  }
  async mounted() {
    this.load();
    this.productList.map((item) => {
      this.listProducts.push({
        plans: item.plans,
        product: item,
      });
    });
    for (let index = 0; index < this.listProducts.length; index++) {
      const entityId = this.entity && this.entity.id ? this.entity.id : null;
      if (entityId) {
        if (this.listProducts[index].product.id === entityId) {
          this.selectedProduct = this.listProducts[index].product;
          this.listPlans = this.listProducts[index].plans;
          this.selectedPlan = this.entity.plan.id;
        }
      }
    }
  }
}
