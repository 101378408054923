







































































































































































































import { Component, Watch } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { Options } from "../../types/Options";
import { TCompanyTicket } from "../../types/Ticket/TCompanyTicket";
import { HeaderItem } from "../../types/HeaderItem";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Breadcrumb } from "../../types/Breadcrumb";
import { ITicket } from "../../types/Ticket/ITicket";
import { ITicketDetails } from "../../types/Ticket/ITicketDetails";
import { paths } from "../../services/apiPaths";
import {
  BaseBreadcrumb,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";

@Component({
  components: {
    BaseBreadcrumb,
    DatePicker,
    PageHeader,
  },
})
export default class TicketList extends Request {
  path: string = paths.zendeskTicket;
  title: string = `${translate("tickets")}`;
  list: ITicket[] = [];
  searchCompany: string = "";
  entriesCompany: TCompanyTicket[] = [];
  dynamicHeader: HeaderItem[]  = [];
  company: TCompanyTicket = {};
  menuStartDate: boolean = false;
  menuEndDate: boolean = false;
  originalEndDate: string = "";
  originalStartDate: string = "";
  startDate: string = null;
  endDate: string = null;
  expanded = [];
  listTicketDetails: ITicketDetails = {};

  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("tickets")}`,
      disabled: true,
    },
  ];
  isLoading: boolean = false;
  valid: boolean = false;
  search: string = "";

  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page: number = 1;
  pageCount: number = 0;
  itemsPerPage: number = 10;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-ticket",
    titlePage: `${translate("tickets")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    crudDetail: "false",
  };
  headerTicket: HeaderItem[] = [
    {
      text: `${translate("fullResolutionTimeInMinutes")}`,
      align: "center",
      value: "fullResolutionTimeInMinutes",
    },
    {
      text: `${translate("agentWaitTimeInMinutes")}`,
      align: "center",
      value: "agentWaitTimeInMinutes",
    },
    {
      text: `${translate("requesterWaitTimeInMinutes")}`,
      align: "center",
      value: "requesterWaitTimeInMinutes",
    },
    {
      text: `${translate("reopens")}`,
      align: "center",
      value: "reopens",
    },
    {
      text: `${translate("replies")}`,
      align: "center",
      value: "replies",
    },
    {
      text: `${translate("createdAt")}`,
      align: "center",
      value: "formattedDatecreatedAt",
    },
    {
      text: `${translate("solvedAt")}`,
      align: "center",
      value: "formattedDatesolvedAt",
    },
  ];

  async seach(item: boolean) {
    if (item == true) {
      this.page = 1;
    }

    let result;

    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";

    if (!sortBy) {
      result = await this.get(
        this.paths.zendeskTicket +
          this.company.zendeskId +
          `?&take=${this.itemsPerPage}&skip=${
            this.page
          }&orderByParam=${"id"}&orderOrientation=${orientation}` +
          `&startDate=${this.originalStartDate}` +
          `&endDate=${this.originalEndDate}`
      );
    } else {
      result = await this.get(
        this.paths.zendeskTicket +
          this.company.zendeskId +
          `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}` +
          `&startDate=${this.originalStartDate}` +
          `&endDate=${this.originalEndDate}`
      );
    }

    if (result && result?.result) {
      this.list = result?.result?.map((ticket) => ({
        ...ticket,
        formattedDate: moment(ticket.updatedAt).format("DD/MM/YYYY"),
      }));
    }
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);

    this.dynamicHeader = [
      {
        text: `${translate("titleTemplate")}`,
        align: "start",
        value: "title",
      },

      {
        text: `${translate("Ticket")}`,
        align: "start",
        value: "zdTicketId",
      },
      {
        text: `${translate("priority")}`,
        align: "center",
        value: "priority",
      },
      {
        text: `${translate("type")}`,
        align: "start",
        value: "type",
      },
      {
        text: `${translate("updatedAt")}`,
        align: "center",
        value: "formattedDate",
      },
      {
        text: `${translate("status")}`,
        align: "center",
        value: "status",
      },

      { text: "", value: "data-table-expand" },
    ];
  }
  translatePriority(priority) {
    switch (priority) {
      case "low":
        return this.$t("low");
      case "high":
        return this.$t("high");
      case "urgent":
        return this.$t("urgent");
      case "normal":
        return "Normal";
      default:
        return priority;
    }
  }
  getTypeTranslation(type) {
    switch (type) {
      case "question":
        return this.$t("question");
      case "incident":
        return this.$t("incident");
      case "task":
        return this.$t("task");
      case "problem":
        return this.$t("problem");
      default:
        return type;
    }
  }
  getPriorityStyle(priority) {
    let color;
    switch (priority) {
      case "low":
        color = "#C0C0C0";
        break;
      case "high":
        color = "#DAA520";
        break;
      case "urgent":
        color = "#A52A2A";
        break;
      default:
        color = "";
    }

    return { color };
  }

  updateOptions(newOptions: any) {
    this.options = { ...this.options, ...newOptions };

    if (this.startDate && this.endDate) {
      this.page = 1;

      const isFormattedDateSelected =
        this.options?.sortBy?.includes("formattedDate");

      if (isFormattedDateSelected) {
        this.options.sortBy = ["updatedAt"];
      }

      this.seach(true);
    }
  }

  async mounted() {
    const companyList = await this.get(
      this.paths.companyListZendesk + `?take=${this.itemsPerPage}&skip=${1}`
    );
    this.entriesCompany = companyList.result;
  }
  async loadDetails({ item }): Promise<void> {
    const resultMetrics = await this.get(
      this.paths.zendeskTicketMetrics + item.id
    );

    if (resultMetrics.solvedAt) {
      this.listTicketDetails = {
        ...resultMetrics,
        formattedDatesolvedAt: moment(resultMetrics.solvedAt).format(
          "DD/MM/YYYY"
        ),
        formattedDatecreatedAt: moment(resultMetrics.createdAt).format(
          "DD/MM/YYYY"
        ),
      };
    } else {
      this.listTicketDetails = {
        ...resultMetrics,
        formattedDatecreatedAt: moment(resultMetrics.createdAt).format(
          "DD/MM/YYYY"
        ),
        formattedDatesolvedAt: "",
      };
    }
  }
  @Watch("searchCompany")
  async searchCompanies(val) {
    if (val && val.length >= 3) {
      let code = val.substring(0, 4);
      const companies = await this.get(
        this.paths.companyListZendesk +
          `?take=${this.itemsPerPage}&skip=${1}&searchValue=${code}`
      );
      return (this.entriesCompany = companies.result);
    }
    if (val && val.length === 0) {
      const companies = await this.get(
        this.paths.companyListZendesk + `?take=${this.itemsPerPage}&skip=${1}`
      );
      return (this.entriesCompany = companies.result);
    }
  }
  async handleClear() {
    const companies = await this.get(
      this.paths.companyListZendesk + `?take=${this.itemsPerPage}&skip=${1}`
    );
    return (this.entriesCompany = companies.result);
  }
  @Watch("originalStartDate")
  formatStartDate(value) {
    this.startDate = moment(value).format("DD/MM/YYYY");
  }
  @Watch("originalEndDate")
  formatEndDate(value) {
    this.endDate = moment(value).format("DD/MM/YYYY");
  }
  getStatusTranslation(status) {
    switch (status) {
      case "new":
        return this.$t("new");
      case "open":
        return this.$t("open");
      case "pending":
        return this.$t("pending");
      case "hold":
        return this.$t("hold");
      case "solved":
        return this.$t("solved");
      case "closed":
        return this.$t("closed");
      default:
        return status;
    }
  }
  getStatusClass(status) {
    let color;
    switch (status) {
      case "new":
        color = "#FFA500";
        break;
      case "open":
        color = "#DC143C";
        break;
      case "pending":
        color = "#1E90FF";
        break;
      case "hold":
        color = "#000000";
        break;
      case "solved":
        color = "#4F4F4F";
        break;
      case "closed":
        color = "#696969";
        break;
      default:
        color = "#C0C0C0";
        break;
    }
    return color;
  }
  get companiesWithCodeAndSocialName() {
    return this.entriesCompany.map((company) => ({
      ...company,
      codeAndSocialName: `${company.code} - ${company.socialName}`,
    }));
  }
  get validDateInverted() {
    let dateStart = moment.tz(
      this.startDate,
      "DD/MM/YYYY",
      "America/Sao_Paulo"
    );
    let dateEnd = moment.tz(this.endDate, "DD/MM/YYYY", "America/Sao_Paulo");

    return dateStart.isAfter(dateEnd);
  }
  get header() {
    return this.dynamicHeader;
  }
  get listTicket() {
    return this.listTicketDetails;
  }
  get missingcompany() {
    return !this.company;
  }
  get missingStartDate() {
    return !this.startDate;
  }
  get missingEndDate() {
    return !this.endDate;
  }
  get saveDisabled() {
    return (
      this.missingEndDate ||
      this.missingStartDate ||
      this.missingcompany ||
      this.validDateInverted
    );
  }
}
