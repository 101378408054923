













































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { TProfileFunctionalityAction } from "../../types/Profile/TProfileFunctionalityAction";
import { TAction } from "../../types/Profile/TAction";

@Component({
  components: {},
})
export default class ProfileActions extends Request {
  @Prop() selected: any[]
  @Prop() actionList: TAction[];
  @Prop() companyId: number;
  @Prop() functionalities: TProfileFunctionalityAction[];
  @Prop() readonly profileId: number;
  isSelected:  any[] = []
  selectedAll:any = null;


  isChecked(funcId:number) {
    this.isSelected = this.isSelected.filter((e) => {
      e != funcId;
      return this.$emit("selected-actions", this.isSelected);
    });
  }
  getIndexAct(actions:TAction[], id:number) {
    return actions.findIndex((el) => el.id == id);
  }

  async load() {
    const result = await this.get(this.paths.profilePath, {
      companyId: this.companyId,
    });

    const checkboxes = result[0].find(
      (i) => i.id === this.profileId
    ).profileFunctionalityActions;
    checkboxes.map((item) => {
      return this.isSelected.push([item.functionalityId, item.actionId]);
    });
  }

  async mounted() {
    this.isSelected = this.selected
    if (this.profileId) {
      this.load();
    }
  }
}
