






























































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import MassiveInput from "./MassiveInput.vue";
import { HeaderItem } from "../../types/HeaderItem";
import { page } from "../../types/Page";
import { Options } from "../../types/Options";
import { Breadcrumb } from "../../types/Breadcrumb";
import Request from "../../services/request";
import { itemsPerPage } from "../../types/itemsPerPage";
import { translate } from "../../plugins/i18n/index";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import moment from "moment";
import { mapGetters } from "vuex";
@Component({
  components: {
    MassiveInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class MassiveList extends Request {
  companyId: any = this.$route.query.companyId;
  page:page = {
    title: `${translate("massiveList")}`,
    icon: null,
  };
  deletePath:string = "";
  search: string = "";
  itemsPerPageList:itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  pag:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  };
  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("massiveList")}`,
      disabled: true,
    },
  ];
  getUser: any;
  user: any;
  list: any = [];
  itemsToTable: any = [];
  header:HeaderItem[] = [
    { text: `${translate("massiveName")}`, value: "name" },
    { text: `${translate("startDate")}`, value: "dateStart" },
    { text: `${translate("endDate")}`, value: "dateEnd" },
    { text: `${translate("status")}`, value: "active" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-hammer-screwdriver",
    titlePage: `${translate("massiveList")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  variables: any = [];

  async loadData() {
    if (this.getUser().companyId.id) {
      this.user = this.getUser();
      this.companyId = this.user.companyId.id;
    } else {
      this.user = this.getUser();
      this.companyId = this.user.companyId;
    }

    this.deletePath = this.paths.massivePath;

    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";
    let Result;

    if (!sortBy) { 
      Result = await this.get(
      this.paths.massivePagePath +
        this.companyId +
        `?&take=${this.itemsPerPage}&skip=${this.pag}&orderByParam=${'id'}&orderOrientation=${orientation}`
    );
     }else{
      Result = await this.get(
      this.paths.massivePagePath +
        this.companyId +
        `?&take=${this.itemsPerPage}&skip=${this.pag}&orderByParam=${sortBy}&orderOrientation=${orientation}`
    );
     }

    
    if (Result.result) {
      Result.result.forEach((element) => {
        element.dateEnd = moment(element.dateEnd).format("DD/MM/YYYY hh:mm");
        element.dateStart = moment(element.dateStart).format(
          "DD/MM/YYYY  hh:mm"
        );
      });
    }
    this.pageCount = Math.ceil(Result.count / this.itemsPerPage);
    this.list = Result.result;
  }
 // async mounted(): Promise<any> {
 //   this.loadData();
 // }
 updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }

}
