var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-padding-zero"},[_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"size":60,"width":4,"indeterminate":"","color":"primary"}}),_c('div',{staticClass:"center"},[_c('div',{staticClass:"ring"}),_c('span',{staticClass:"text-loading"},[_vm._v(_vm._s(_vm.$t("loading")))])])],1),_c('BaseBreadcrumb',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}}),_c('PageHeader',{attrs:{"header":_vm.pageHeader}}),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"showExpand":true,"headers":_vm.header,"hide-default-footer":"","pageHeaderConfig":_vm.pageHeader,"items":_vm.list,"tagActive":"active","single-expand":true,"expanded":_vm.expanded,"item-key":"id","search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"item-expanded":_vm.loadDetails,"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){_vm.page=$event},"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pl-5 pr-5"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"search-input":_vm.searchCompany,"label":_vm.$t('codeCompany'),"outlined":"","item-text":"codeAndSocialName","item-value":"zendeskId","clearable":true,"items":_vm.companiesWithCodeAndSocialName,"return-object":true},on:{"update:searchInput":function($event){_vm.searchCompany=$event},"update:search-input":function($event){_vm.searchCompany=$event},"change":_vm.handleClear},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.codeAndSocialName)+" ")]}}]),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menuStartDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"outlined":"","label":_vm.$t('startDate'),"prepend-icon":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.originalStartDate),callback:function ($$v) {_vm.originalStartDate=$$v},expression:"originalStartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menuEndDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('endDate'),"prepend-icon":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.originalEndDate),callback:function ($$v) {_vm.originalEndDate=$$v},expression:"originalEndDate"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.saveDisabled},on:{"click":function($event){return _vm.seach(true)}}},[_vm._v(_vm._s(_vm.$t("search"))+" ")])],1),_c('v-col',{staticClass:"mt-40",attrs:{"cols":"12"}},[(_vm.validDateInverted)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("searchStart"))+" ")]):_vm._e()])],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":_vm.getStatusClass(item.status)}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.getStatusTranslation(item.status)))])])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getTypeTranslation(item.type)))])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(_vm.getPriorityStyle(item.priority))},[_vm._v(" "+_vm._s(_vm.translatePriority(item.priority)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.headerTicket,"items":[_vm.listTicketDetails],"tagActive":"active","hide-default-footer":true}})],1)]}}],null,true)})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(_vm._s(_vm.$t("itemsPerPage")))]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"v-model":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.seach},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}])})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa-arrow-left","next-icon":"fa-arrow-right","color":"#d3075e","length":_vm.pageCount,"circle":"","total-visible":10},on:{"input":_vm.seach},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }