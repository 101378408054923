var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-padding-zero"},[_c('BaseBreadcrumb',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}}),(_vm.newList.companyProductPlan)?_c('v-dialog',{attrs:{"max-width":"30%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("productStatus"))+" "),_c('v-spacer')],1),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('reasonToBlock'),"placeholder":_vm.$t('reasonToBlock'),"outlined":"","disabled":""},model:{value:(_vm.newList.companyProductPlan.reason),callback:function ($$v) {_vm.$set(_vm.newList.companyProductPlan, "reason", $$v)},expression:"newList.companyProductPlan.reason"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","disabled":"","label":_vm.$t('observation')},model:{value:(_vm.newList.companyProductPlan.observation),callback:function ($$v) {_vm.$set(_vm.newList.companyProductPlan, "observation", $$v)},expression:"newList.companyProductPlan.observation"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.close"))+" ")])],1)],1)],1):_vm._e(),_c('List',{attrs:{"header":_vm.header,"pageHeaderConfig":_vm.pageHeader,"items":_vm.list,"itemCnpj":true,"customColumns":_vm.customColumns,"item-key":"id","page":_vm.page,"itemsPerPage":_vm.itemsPerPage,"pageCount":_vm.pageCount,"updateOptions":_vm.updateOptions},scopedSlots:_vm._u([{key:"companyProductPlan.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":item.companyProductPlan.active === true ? '#4CAF50' : '#F90000'}},[_c('span',[_vm._v(" "+_vm._s(item.companyProductPlan.active === true ? _vm.$t("active") : _vm.$t("canceled")))])])]}},{key:"version",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resolveVersion(item.version))+" ")]}},{key:"companyProductPlan.isBlocked",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v("mdi-dots-vertical ")]),_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":item.companyProductPlan.isBlocked === true ? '#F90000' : '#4CAF50'}},[_c('span',[_vm._v(" "+_vm._s(item.companyProductPlan.isBlocked === true ? _vm.$t("yes") : _vm.$t("no")))])])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.createdAt))]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"icons"},[(_vm.isLoaded)?_c('CompanyProductInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PPE', 'UP']),expression:"['PPE', 'UP']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"productList":_vm.productList,"list":_vm.list,"loadData":_vm.loadData,"entity":item}}):_vm._e(),_c('ProductConfig',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PCF', 'UP']),expression:"['PCF', 'UP']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"list":{},"entity":{},"resultConfig":item.companyProductConfig,"companyProductPlanId":item.companyProductPlan.id}}),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['CHA', 'VI']),expression:"['CHA', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
            name: 'ChannelsList',
            query: {
              companyId: item.id,
              companyProductPlanId: item.companyProductPlan.id,
            },
          }}},[_c('v-icon',{attrs:{"dense":"","title":_vm.$t('whatsappChannel')}},[_vm._v(" mdi-whatsapp ")])],1),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['REP', 'VI']),expression:"['REP', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
            name: 'Reports',
            query: {
              companyId: _vm.companyId,
              companyProductPlanId: item.companyProductPlan.id,
              scope: item.scope,
              item: item,
              entity: _vm.entity,
            },
          }}},[_c('v-icon',{attrs:{"dense":"","title":_vm.$t('reports')}},[_vm._v(" mdi-book-open-page-variant ")])],1),_c('LockUnlock',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['LUL', 'UP']),expression:"['LUL', 'UP']",modifiers:{"permission":true}}],staticClass:"mr-3",attrs:{"entity":item,"companyId":_vm.companyId}}),_c('DeleteCompanyProductPlan',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['DPD', 'UP']),expression:"['DPD', 'UP']",modifiers:{"permission":true}}],attrs:{"entityDelete":item}}),(item.scope === 'OMI')?_c('DirectAuthentication',{attrs:{"companyProductPlanId":item.companyProductPlan.id}}):_vm._e()],1)]}}],null,true)},[(_vm.entity)?_c('template',{slot:"sideItem"},[_vm._v(" "+_vm._s(_vm.entity.code)+" - "+_vm._s(_vm.entity.socialName)+" ")]):_vm._e(),_c('template',{slot:"mainButton"},[(_vm.isLoaded)?_c('CompanyProductInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PPE', 'CR']),expression:"['PPE', 'CR']",modifiers:{"permission":true}}],attrs:{"productList":_vm.productList,"list":_vm.list,"loadData":_vm.loadData,"entity":{}}}):_vm._e()],1),_c('template',{slot:"pagination"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(_vm._s(_vm.$t("itemsPerPage")))]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.loadPagination},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa-arrow-left","next-icon":"fa-arrow-right","color":"#d3075e","length":_vm.pageCount,"circle":"","total-visible":10},on:{"input":_vm.loadPagination},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }