

















































































































































































































































import { Component, Prop } from "vue-property-decorator";
import { TUser } from "../../types/User/TUser";
import Request from "../../services/request";
import { TProfileFunctionalityUser } from "../../types/User/TProfileFunctionalityUser";
import { IProduct } from "../../types/Product/IProduct";
import { IGetUser } from "../../types/IGetUser";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";

@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class TypeUserInput extends Request {
  @Prop() readonly entity: TUser;
  @Prop() readonly list: TUser[];
  @Prop() readonly loadData: () => void;
  @Prop() readonly profileList: TProfileFunctionalityUser[];
  @Prop() readonly companyProductList: IProduct[];

  user: IGetUser = {};
  getUser: any;
  show1: boolean = false;
  show2: boolean = false;
  dialogConfirmation: boolean = false;
  resultIdDuplicate: any;
  duplicateId: any = {};
  tab = null;
  profile: any = [];
  profiles: any = [];
  editorUserId: number;

  companyId = Number(this.$route.query.companyId);
  localEntity: any = {};
  items = [
    { id: 1, name: `${translate("user")}` },
    { id: 2, name: `${translate("profile")}` },
  ];
  typeUserList = [
    { value: "C", text: `${translate("common")}` },
    { value: "I", text: `${translate("integration")}` },
  ];
  dialog = false;
  header = `${translate("editUser")}`;

  async onSave(): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;

    let result;
    if (this.profile.length > 0) {
      for (let index = 0; index < this.profile.length; index++) {
        if (
          this.profile[index] &&
          this.profile[index].id &&
          this.profile[index].productId
        ) {
          const profile = {
            profileId: this.profile[index].id,
            productId: this.profile[index].productId,
            scope: "MKP",
          };
          this.profiles.push(profile);
        }
      }
    }

    this.localEntity.profiles = this.profiles;
    this.localEntity.companyId = this.companyId;
    this.localEntity.firstName = this.localEntity.firstName.trim();
    this.localEntity.lastName = this.localEntity.lastName.trim();
    this.localEntity.secret = this.localEntity.secret.trim();
    this.localEntity.confirmPassword = this.localEntity.confirmPassword.trim();
    this.localEntity.email = this.localEntity.email.trim();
    if (this.entity.id) {
      result = await this.put(this.paths.userSavePath + this.localEntity.id, {
        ...this.localEntity,
        editorUserId: this.editorUserId,
      });
    } else {
      result = await this.save(this.paths.userSavePath, {
        ...this.localEntity,
        editorUserId: this.editorUserId,
      });
    }
    if (result.isDeleted === true) {
      this.dialogConfirmation = true;
      this.resultIdDuplicate = result.idDuplicated;
    }

    this.dialog = result.message ? true : false;
    this.loadData();

    if (!this.entity.id) {
      if (!result.message) {
        this.localEntity = {};
      }
    }
    this.tab = 0;
    return result;
  }

  async confirmReactivateUser() {
    this.dialogConfirmation = false;

    const resultReactive = await this.save(this.paths.reactiveUserPath, {
      id: this.resultIdDuplicate,
    });
    this.loadData();
    return resultReactive;
  }

  setProfile() {
    if (this.localEntity.typeUser === "I") {
      this.profileList.map((profile) => {
        if (profile.name === "Integração") {
          this.localEntity.profileId = profile.id;
        }
      });
    }
  }
  load(): void {
    if (this.entity.id) {
      this.localEntity = {
        ...this.entity,
      };
    }
  }
  async mounted(): Promise<void> {
    this.load();

    if (this.localEntity.userProfiles) {
      this.companyProductList.forEach((Product, companyProductIndex) => {
        const localEntityProfile = this.localEntity.userProfiles.flatMap(
          (userProfile) => userProfile
        );

        const teste = localEntityProfile.find(
          (localEntityProfile) => localEntityProfile.productId == Product.id
        );
        if (teste) {
          this.profile[companyProductIndex] = { id: teste.profileId };
        }
      });
    }
  }
  cancelDialog() {
    if (!this.entity.id) {
      this.localEntity = {};
    }

    this.dialog = false;
    this.load();
  }

  get missingPassword() {
    return !this.localEntity.secret;
  }
  get missingConfirmPassword() {
    return (
      !!this.localEntity.secret &&
      (!this.localEntity.confirmPassword ||
        this.localEntity.secret != this.localEntity.confirmPassword)
    );
  }
  get missingFirstName() {
    return !this.localEntity.firstName;
  }
  get missingLastName() {
    return !this.localEntity.lastName;
  }
  get missingEmail() {
    return !this.localEntity.email;
  }
  get missingTypeUser() {
    return !this.localEntity.typeUser;
  }
  get missingPhone() {
    return !this.localEntity.phone;
  }
  get missingPrifileId() {
    return !this.localEntity.phone;
  }
  get nextDisabled() {
    return (
      this.missingFirstName ||
      this.missingLastName ||
      this.missingEmail ||
      this.missingTypeUser ||
      this.missingPhone
    );
  }
  get saveDisabled() {
    return (
      this.missingPassword ||
      this.missingConfirmPassword ||
      this.missingFirstName ||
      this.missingLastName ||
      this.missingEmail ||
      this.missingTypeUser ||
      this.missingPhone ||
      this.missingPrifileId
    );
  }
}
