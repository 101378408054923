





















































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import TypeUserInput from "./TypeUserInput.vue";
import { IUser } from "../../types/User/IUser";
import { TProfileFunctionalityUser } from "../../types/User/TProfileFunctionalityUser";
import { IGetUser } from "../../types/IGetUser";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { Options } from "../../types/Options";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import { itemsPerPage } from "../../types/itemsPerPage";
import { page } from "../../types/Page";
import { IProduct } from "../../types/Product/IProduct";
import { paths } from "../../services/apiPaths";
import { mapGetters } from "vuex";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
@Component({
  components: {
    TypeUserInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },

  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class UserList extends Request {
  deletePath: string = "";
  entity = this.$route.query.item;
  companyId = this.$route.query.companyId;
  list: IUser[] = [];
  companyProductList: IProduct[] = [];
  profileList: TProfileFunctionalityUser[] = [];
  search: string = "";
  getUser: any;
  user: IGetUser = {};
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  pag: number = 1;
  pageCount: number = 0;
  itemsPerPage: number = 10;
  page: page = {
    title: `${translate("title.userList")}`,
    icon: null,
  };
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };

  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.company")}`,
      to: "/company/",
      disabled: false,
    },
    {
      text: `${translate("title.userList")}`,
      disabled: true,
    },
  ];
  header: HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "firstName" },
    { text: `${translate("email")}`, align: "center", value: "email" },
    { text: `${translate("phone")}`, align: "center", value: "phone" },
    { text: `${translate("status")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-account-multiple-plus",
    titlePage: `${translate("users")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  async send(item: string) {
    return await this.get(
      this.paths.forgotPasswordPath + `?email=${item}`
    ).then(() => {
      this.$message.successMessage(`${translate("ConfirmYourRegistration")}`);
    });
  }
  async loadData(): Promise<void> {
    this.companyId = this.$route.query.companyId;
    this.deletePath = paths.deleteUserPath;
    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";

    let result;
    if (!sortBy) {
      result = await this.get(
        this.paths.userByCompanyPageListPath +
          `${this.companyId}` +
          `?&take=${this.itemsPerPage}&skip=${
            this.pag
          }&orderByParam=${"id"}&orderOrientation=${orientation}`
      );
    } else {
      result = await this.get(
        this.paths.userByCompanyPageListPath +
          `${this.companyId}` +
          `?&take=${this.itemsPerPage}&skip=${this.pag}&orderByParam=${sortBy}&orderOrientation=${orientation}`
      );
    }

    if (result) {
      this.list = result.result;
    }
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
  }
  async mounted() {
    this.user = this.getUser();

    const companyProduct = await this.get(
      this.paths.profileProductPath + this.companyId
    );

    this.companyProductList = companyProduct;

    let profile = await this.get(this.paths.profilePath);
    for (let index = 0; index < this.companyProductList.length; index++) {
      const element = this.companyProductList[index];

      const profileByCompany = profile[0].filter((profile) => {
        return profile.companyId === this.companyId;
      });
      this.profileList[index] = profileByCompany.filter((profile) => {
        return profile.productId === element.id;
      });
    }
  }

  async copyIntegrationData() {
    const result = await this.post(this.paths.validationPath, {
      grant_type: "password",
      client_id: localStorage.getItem("client_id"),
      username: this.user.email,
      client_secret: localStorage.getItem("password"),
      scope: "MKP",
      password: localStorage.getItem("password"),
    });
    const resultResponse = JSON.stringify(result);
    navigator.clipboard.writeText(resultResponse);
  }

  updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }
}
