




































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import WhatsappConfigInput from "./WhatsappConfigInput.vue";
import { Broker } from "../../types/Broker";
import { page } from "../../types/Page";
import Request from "../../services/request";
import { Breadcrumb } from "../../types/Breadcrumb";
import { translate } from "../../plugins/i18n/index";
import { HeaderItem } from "../../types/HeaderItem";
import { paths } from "../../services/apiPaths";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import ChannelsList from "../channels/ChannelsList.vue";
@Component({
  components: {
    WhatsappConfigInput,
    ChannelsList,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class WhatsappConfigList extends Request {
  path:string = paths.brokerPath;
  page:page = {
    title: `${translate("whatsappConfig")}`,
    icon: null,
  };

  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("whatsappConfig")}`,
      disabled: true,
    },
  ];
  customColumns = [
    {
      slotName: "officialApi",
      itemValue: "officialApi",
    },
    {
      slotName: "isVerified",
      itemValue: "isVerified",
    },
  ];
  header:HeaderItem[] = [
    {
      text: `${translate("phoneNumber")}`,
      align: "start",
      value: "phoneNumber",
    },
    {
      text: `${translate("officialApi")}`,
      align: "center",
      value: "officialApi",
    },
    {
      text: `${translate("isVerified")}`,
      align: "center",
      value: "isVerified",
    },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  companyId: number = null;
  companyProductPlanId: number = null;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-whatsapp",
    titlePage: `${translate("whatsappConfig")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: Broker[] = [];
  async mounted(): Promise<void> {
    this.companyId = Number(this.$route.query.companyId);
    this.companyProductPlanId = Number(this.$route.query.companyProductPlanId);
    const result = await this.get(this.paths.whatsappConfigPath);
    if (result) {
      this.list = result[0];
    }
  }
}
