










































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import TemplateInput from "./TemplateInput.vue";
import { Template } from "./Template";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import moment from "moment";
@Component({
  components: {
    TemplateInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class TemplateList extends Request {
  page = {
    title: `${translate("templateList")}`,
    icon: null,
  };
  deletePath: string = "";
  breadcrumbs = [
    {
      text: `${translate("templateList")}`,
      disabled: true,
    },
  ];

  list: Template[] = [];
  header = [
    { text: `${translate("from")}`, value: "from" },
    { text: `${translate("subject")}`, value: "subject" },
    { text: `${translate("updatedAt")}`, value: "updatedAt" },
    { text: `${translate("status")}`, value: "active" },
    // {
    //   text: `${translate("messageTemplate")}`,
    //   align: "center",
    //   value: "message",
    // },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-at",
    titlePage: `${translate("templateList")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  variables: any;
  async mounted() {
    const templateResult = await this.get(this.paths.templatePath);
    this.list = templateResult[0];
    this.list.forEach((template) => {
      template.message = Buffer.from(template.message).toString();
      template.updatedAt = moment(template.updatedAt).format("DD/MM/YYYY");
    });

    this.deletePath = this.paths.templatePath;
  }
}
