
















































































































import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { IOmieConfig } from "../../types/Omieconfig/IOmieConfig";
import { TOmieConfig } from "../../types/Omieconfig/TOmieConfig"
import { Breadcrumb } from "../../types/Breadcrumb";
import { page } from "../../types/Page";
import { translate } from "../../plugins/i18n/index";
import {
  BaseBreadcrumb,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";

@Component({
  components: {
    BaseBreadcrumb,
    PageHeader,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ConfigurationOmieInput extends Request {
  @Prop() readonly entity: IOmieConfig;

  page: page = {
    title: `${translate("addConfiguration")}`,
    icon: "null",
  };
  valid: boolean = false;
  show1: boolean = false;
  show2: boolean = false;
  show3: boolean = false;
  editorUserId: any = null;
  getUser: any;
  token: string = "";
  localEntity: TOmieConfig = {
    token: '',
    url: '',
    password:'',
    user: '',
    zabbixToken:'',
    zabbixUrl: '',
  };
  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("startPage")}`,
      to: "/dashboard/",
      disabled: false,
    },
    {
      text: `${translate("addConfiguration")}`,
      disabled: true,
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-cog",
    titlePage: `${translate("addConfiguration")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    crudDetail: "false",
  };
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async onSave(): Promise<any> {
    const user = this.getUser();

    this.editorUserId = user.id;

    const result = await this.save(this.paths.configurationOmie, {
      omieSecret: this.localEntity.token,
      zendeskUsername: this.localEntity.user,
      zendeskDomain: this.localEntity.url,
      zendeskPassword: this.localEntity.password,
      zabbixDomain: this.localEntity.zabbixUrl,
      zabbixToken: this.localEntity.zabbixToken,
      editorUserId: this.editorUserId,
    });
    this.loadData();
    return result;
  }
  mounted() {
    this.loadData();
  }
  async loadData() {
    this.load();

    const resultOmie = await this.get(this.paths.configurationOmieActive);
    if (resultOmie) {
      this.localEntity.token = resultOmie.omieSecret;
      this.localEntity.url = resultOmie.zendeskDomain;
      this.localEntity.user = resultOmie.zendeskUsername;
      this.localEntity.password = resultOmie.zendeskPassword;
      this.localEntity.zabbixUrl = resultOmie.zabbixDomain;
      this.localEntity.zabbixToken = resultOmie.zabbixToken;
    } else {
      this.localEntity.token = null;
      this.localEntity.url = null;
      this.localEntity.user = null;
      this.localEntity.password = null;
      this.localEntity.zabbixDomain = null;
      this.localEntity.zabbixToken = null;
    }
    this.valid = true;
  }
  get missingToken() {
    return !this.localEntity.token;
  }
  get missingZabbixUrl() {
    return !this.localEntity.zabbixUrl;
  }
  get missingZabbixToken() {
    return !this.localEntity.zabbixToken;
  }
  get missingUrl() {
    return !this.localEntity.url;
  }
  get missingUser() {
    return !this.localEntity.user;
  }
  get missingPassword() {
    return !this.localEntity.password;
  }
  get saveDisabled() {
    return (
      this.missingToken ||
      this.missingPassword ||
      this.missingUser ||
      this.missingUrl ||
      this.missingZabbixToken ||
      this.missingZabbixUrl
    );
  }
}
