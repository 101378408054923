






































































































































































































































import { Component, Watch } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import MaintenanceInput from "./MaintenanceInput.vue";
import MaintenanceView from "./MaintenanceView.vue";
import { IMaintenance } from "../../types/Maintenance/IMaintenance";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import Authorizations from "./Authorizations.vue";
import PlanDetailList from "./../planDetail/PlanDetailList.vue";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import {
  BaseBreadcrumb,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { itemsPerPage } from "../../types/itemsPerPage";
import { IProduct } from "../../types/Product/IProduct";
import { Options } from "../../types/Options";
import { TSelect } from "../../types/Infrastructure/TSelect";
import { TUserMaintenance } from "../../types/Maintenance/TUserMaintenance";
import { TStatusList } from "../../types/Maintenance/TStatusList";
import { TManutentionAuthorization } from "../../types/Maintenance/TManutentionAuthorization";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import ChangeStatus from "./ChangeStatus.vue";
import { mapGetters } from "vuex";
import { ITechnicalNote } from "../../types/TechnicalNote/ITechnicalNote";
import AssociateTechnicalNote from "./AssociateTechnicalNote.vue";
import DownloadTechnicalNote from "../technicalNote/DownloadTechnicalNote.vue";

@Component({
  components: {
    MaintenanceInput,
    List,
    ConfirmDelete,
    PageHeader,
    BaseBreadcrumb,
    PlanDetailList,
    MaintenanceView,
    Authorizations,
    ChangeStatus,
    AssociateTechnicalNote,
    DownloadTechnicalNote,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class MaintenanceList extends Request {
  path: string = paths.maintenancePath;
  user: any;
  companyId = this.$route.query.companyId;
  getUser: any;
  itemId = this.$route.query.item;
  deletePath: string = "";
  entriesCompany: any = [];
  technicalNoteList: ITechnicalNote[] = [];
  selectList: string = "";
  selectListStatus: number[] | number = [];
  title = `${translate("maintenance")}`;
  resultAuthorizationslist: TManutentionAuthorization[] = [];
  resultProductList: IProduct[] = [];
  searchHeader: string = "";
  resultUsersList: TUserMaintenance[] = [];
  search: string = "";
  selectSeach: TSelect[] = [
    {
      id: 1,
      name: `${translate("description")}`,
      value: "description",
    },
    {
      id: 2,
      name: `${translate("ticketMatrix")}`,
      value: "ticketMatrix",
    },
    {
      id: 3,
      name: `${translate("requester")}`,
      value: "manutentionRequesterUser.firstName",
    },
    {
      id: 4,
      name: `${translate("status")}`,
      value: "status",
    },
    {
      id: 5,
      name: `${translate("executor")}`,
      value: "manutentionExecutors.firstName",
    },
    {
      id: 6,
      name: `${translate("clientResponsable")}`,
      value: "manutentionClients.officialName",
    },
  ];
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page: number = 1;
  pageCount: number = 0;
  itemsPerPage: number = 10;
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  };
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("maintenance")}`,
      disabled: true,
      to: "/maintenance/",
    },
  ];
  selectStatus: TStatusList[] = [
    {
      description: `${translate("waitingAuthorization")}`,
      id: 1,
    },
    {
      description: `${translate("scheduled")}`,
      id: 2,
    },
    {
      description: `${translate("running")}`,
      id: 3,
    },
    {
      description: `${translate("canceled")}`,
      id: 4,
    },
    {
      description: `${translate("completed")}`,
      id: 5,
    },
    {
      description: `${translate("notAuthorized")}`,
      id: 6,
    },
  ];
  header: HeaderItem[] = [
    {
      text: `${translate("description")}`,
      align: "start",
      value: "description",
    },
    {
      text: `${translate("clientResponsable")}`,
      align: "center",
      value: "manutentionClients",
    },

    {
      text: `${translate("ticketMatrix")}`,
      align: "center",
      value: "ticketMatrix",
    },
    {
      text: `${translate("requester")}`,
      align: "center",
      value: "manutentionRequesterUser.firstName",
    },

    {
      text: `${translate("executor")}`,
      align: "center",
      value: "manutentionExecutors.firstName",
    },
    {
      text: `${translate("status")}`,
      align: "center",
      value: "status",
    },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-wrench",
    titlePage: `${translate("maintenance")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  list: IMaintenance[] = [];

  async loadData() {
    const technicalNote = await this.get(
      this.paths.technicalNotePath + `?&take=${this.itemsPerPage}&skip=${1}`
    );
    this.technicalNoteList = technicalNote[0];
    if (this.getUser().companyId.id) {
      this.user = this.getUser();
      this.companyId = this.user.companyId.id;
    } else {
      this.user = this.getUser();
      this.companyId = this.user.companyId;
    }
    this.deletePath = this.paths.maintenancePath;

    const resultAuthorizations = await this.get(this.paths.maintenancePath);
    this.resultAuthorizationslist = resultAuthorizations;

    const users = await this.get(
      this.paths.manutenceUserCompanyPath +
        this.companyId +
        `?orderByParam=firstName&orderOrientation=ASC`
    );
    this.resultUsersList = users;

    let result;

    const sortByValid = this.options?.sortBy[0];
    const sortBy =
      sortByValid === "manutentionClients"
        ? "manutentionClients.officialName"
        : sortByValid;
    const paramSearch =
      this.selectList === "status" ? this.selectListStatus : this.search;
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";

    if (this.itemId) {
      result = await this.get(
        this.paths.redirectionMaintenancePath + this.itemId
      );

      this.list = result;
    } else {
      if (!sortBy) {
        result = await this.get(
          this.paths.maintenancePageListPath +
            `?&take=${this.itemsPerPage}&skip=${
              this.page
            }&orderByParam=${"id"}&orderOrientation=${orientation}&companyId=${
              this.companyId
            }&searchParam=${this.selectList}&searchValue=${paramSearch}`
        );
      } else {
        result = await this.get(
          this.paths.maintenancePageListPath +
            `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}&companyId=${this.companyId}&searchParam=${this.selectList}&searchValue=${paramSearch}`
        );
      }

      this.pageCount = Math.ceil(result.count / this.itemsPerPage);

      this.list = result.result;
    }
  }
  shortenText(text, maxLength) {
    if (text != null && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  }
  formatarNamesExecutores(executores) {
    const orientation = this.options?.sortDesc[0];

    if (!executores) return "";

    const sortedNames = executores.map((executor) => {
      if (
        executor &&
        executor.executorUser &&
        executor.executorUser.firstName
      ) {
        return `${executor.executorUser.firstName} ${executor.executorUser.lastName}`;
      }
    });

    if (orientation) {
      sortedNames.sort((a, b) => b.localeCompare(a));
    } else {
      sortedNames.sort((a, b) => a.localeCompare(b));
    }

    return sortedNames.join(", ");
  }

  formatarNamesRequester(requester) {
    if (requester) {
      return `${requester.firstName} ${requester.lastName}`;
    } else {
      return "";
    }
  }

  formatarNamesManutentionClients(clients) {
    const orientation = this.options?.sortDesc[0];
    if (!clients) return "";

    const sortedNames = [];

    clients.forEach((client) => {
      if (client && client.clientCompany && client.clientCompany.officialName) {
        sortedNames.push(client.clientCompany.officialName);
      }
    });

    if (orientation) {
      sortedNames.sort((a, b) => b.localeCompare(a));
    } else {
      sortedNames.sort((a, b) => a.localeCompare(b));
    }

    return sortedNames.join(", ");
  }
  clearSearch() {
    if (this.selectList && this.search != "") {
      this.search = "";
      this.loadData();
    } else if (
      this.selectList != "status" &&
      (this.selectListStatus === 1 ||
        this.selectListStatus === 2 ||
        this.selectListStatus === 3 ||
        this.selectListStatus === 4 ||
        this.selectListStatus === 5 ||
        this.selectListStatus === 6)
    ) {
      this.selectListStatus = [];
      this.loadData();
    }
  }
  getStatusTranslation(status) {
    switch (status) {
      case 1:
        return this.$t("waitingAuthorization");
      case 2:
        return this.$t("scheduled");
      case 3:
        return this.$t("running");
      case 4:
        return this.$t("canceled");
      case 5:
        return this.$t("completed");
      case 6:
        return this.$t("notAuthorized");
      default:
        return status;
    }
  }
  getStatusClass(status) {
    let color;
    switch (status) {
      case 1:
        color = "#DAA520";
        break;
      case 2:
        color = "#6959CD";
        break;
      case 3:
        color = "#3CB371";
        break;
      case 4:
        color = "#DC143C";
        break;
      case 5:
        color = "#778899";
        break;
      case 6:
        color = "#D2691E";
        break;
      default:
        color = "";
        break;
    }
    return color;
  }
  @Watch("search")
  onSearchChange(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      this.page = 1;
    }
  }
  get companiesWithCodeAndSocialName() {
    return this.entriesCompany.map((company) => ({
      ...company,
      codeAndSocialName: `${company.code} - ${company.socialName}`,
    }));
  }
  entriesCompanyEmited(companies) {
    this.entriesCompany = [...this.entriesCompany, ...companies];
  }
  
  async updateOptions(newOptions: Options) {
    this.options = { ...this.options, ...newOptions };
    
  
    if (this.getUser().companyId.id) {
      this.user = this.getUser();
      this.companyId = this.user.companyId.id;
    } else {
      this.user = this.getUser();
      this.companyId = this.user.companyId;
    }

    const product = await this.get(
      this.paths.functionalityProductCompanyPath + this.companyId
    );
    this.resultProductList = product;


    this.loadData();
  }

  }

