






























import { Component } from "vue-property-decorator";
import {
  BaseBreadcrumb,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { Breadcrumb } from "../../types/Breadcrumb";
import { page } from "../../types/Page";
import Cards from "./charts/Cards.vue";
import VueApexCharts from "vue-apexcharts";
import TableCriticals from "./TableCriticals.vue";
import CriticalityAlarms from "./CriticalityAlarms.vue";

@Component({
  components: {
    BaseBreadcrumb,
    PageHeader,
    Cards,
    VueApexCharts,
    CriticalityAlarms,
    TableCriticals
  },
})
export default class Dashboard extends Request {
  page:page = {
    title: `${translate("overview")}`,
    icon: null,
  };
  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("overview")}`,
      disabled: true,
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-view-dashboard",
    titlePage: `${translate("title.dashboard")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  seriesData = [];
  chartOptions = {};
  async mounted() {
    const result = await this.get(this.paths.dashboardErpPath);

    const dataArray = Object.keys(result).map((key) => ({
      title: key,
      value: result[key].count,
      id: result[key].id,
    }));
    const colors = dataArray.map(() => this.getColor());
    this.seriesData = [
      {
        name: "valor",
        data: dataArray.map((item, index) => ({
          x: item.title,
          y: item.value,
          id: item.id,
          fillColor: colors[index],
        })),
      },
    ];
    this.chartOptions = {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#A9A9A9"],
        },
      },
      xaxis: {
        categories: dataArray.map((item) => item.title),
        position: "bottom",
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#A9A9A9",
          },
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#A9A9A9",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
          style: {
            colors: this.getColor(),
          },
        },
      },
      fill: {
        colors: colors,
      },
    };
  }
  getColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  resolveSize(item) {
    const normalizedValue = item / 100;
    return `--size: ${normalizedValue}`;
  }
  handleClick(_event, _chartContext, config) {
    const idDoItemClicado = this.seriesData[0].data[config.dataPointIndex].id;

    this.$router.push({
      name: "ReportErps",
      query: {
        id: idDoItemClicado,
        item: this.seriesData[0].data[config.dataPointIndex],
      },
    });
  }
}
