




























































































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import { ICompany } from "../../types/Company/ICompany";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { TProduct } from "../../types/Company/TProduct";
import { TListProduct } from "../../types/Company/TListProduct";
import { TCompanyProductPlan } from "../../types/Company/TCompanyProductPlan";
import { TPlan } from "../../types/Company/TPlan";
import { SelectPlan } from "../../types/SelectPlan";
import { IGetUser } from "../../types/IGetUser";
import { mask } from "vue-the-mask";
import { i18n } from "../../plugins/i18n/index";
import DeleteCompanyProductPlan from "../../components/commonComponents/DeleteCompanyProductPlan.vue";
import axios from "axios";
import { mapGetters } from "vuex";
@Component({
  components: { DeleteCompanyProductPlan },
  directives: { mask },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class CompanyInput extends Request {
  @Prop() readonly entity: ICompany;
  @Prop() readonly companyProductPlan: TCompanyProductPlan;
  @Prop() readonly list: ICompany[];
  @Prop() readonly loadData: () => void;
  @Prop() readonly productList: TProduct[];
  @Prop() readonly isView: boolean;
  readonly header = `${translate("addCompany")}`;
  cpfNotValid: boolean;
  selectedPlan: SelectPlan[] = [];
  planList: TPlan[] = [];
  dialog: boolean = false;
  localEntity: ICompany = {};
  listProducts: TListProduct[] = [];
  active: boolean = true;
  getUser: any;
  user: IGetUser;
  editorUserId: number;
  tab: number = null;
  items = [
    { id: 1, name: `${translate("title.company")}` },
    { id: 2, name: `${translate("products")}` },
  ];

  async onSave(): Promise<any> {
    this.user = this.getUser();
    this.editorUserId = this.user.id;

    let result;
    if (this.listProducts.length > 0) {
      const product = this.listProducts.map((x) => {
        return {
          planId: x.plan.id,
          productId: x.product,
          code: this.entity.code,
          editorUserId: this.editorUserId,
        };
      });
      this.selectedPlan = product;
    }

    this.localEntity.plans = this.selectedPlan;

    if (this.localEntity.id) {
      result = await this.post(this.paths.companyProductPath, {
        ...this.localEntity,
        editorUserId: this.editorUserId,
      });
    } else {
      result = await this.save(this.paths.companyProductPath, {
        ...this.localEntity,
        editorUserId: this.editorUserId,
      });
    }

    if (!this.localEntity.id) {
      if (!result.message) {
        this.localEntity = {};
        this.localEntity.officialName = "";
        this.localEntity.active = null;
        this.localEntity.cnpj = "";
        this.localEntity.code = "";
        this.localEntity.listProducts = [];
        this.selectedPlan = [];
      }
    }

    switch (result.message) {
      case "DUPLICATED_CNPJ":
      case "DUPLICATED_CODE":
        this.dialog = true;
        break;
      default:
        this.dialog = false;
    }
    this.tab = 0;
    this.loadData();
    return result;
  }
  load(): void {
    if (this.entity.id) {
      this.localEntity = {
        ...this.entity,
      };
    }
  }

  closeDialog() {
    if (!this.localEntity.id) {
      this.localEntity = {
        officialName: "",
        active: null,
        cnpj: "",
        code: "",
        listProducts: [],
      };
      this.listProducts = [];
      this.selectedPlan = [];
    }

    this.dialog = false;
    this.tab = 0;
  }
  async chargePlan(value, i) {
    for (let index = 0; index < this.listProducts.length; index++) {
      const productId = value;
      const result = this.productList;
      this.listProducts[i].planList = result.find(
        (i) => i.id === productId
      ).plans;
      this.listProducts[i].planList;
    }
  }
  async mounted() {
    if (this.entity.id) {
      this.localEntity = this.entity;
      if (this.localEntity.product.length) {
        this.localEntity.product.map((item) => {
          const productItem = this.productList.find((i) => i.id === item.id);
          if (productItem) {
            this.listProducts.push({
              plan: item.plan,
              product: item.id,
              planList: productItem.plans,
            });
          }
        });
      }
    }

    this.load();
    if (this.listProducts.length === 0) {
      this.listProducts.push({
        planList: [],
        product: null,
      });
    }
  }
  addComponent(): void {
    this.listProducts.push({
      planList: [],
      product: null,
    });
  }
  removeComponent(index) {
    this.listProducts.splice(index, 1);
  }
  disableItem(item) {
    const isSelected = this.listProducts.find((x) => x.product === item.id);
    if (isSelected) return true;
    if (item.active === true) {
      return false;
    } else {
      return true;
    }
  }
  getActiveProductList(index) {
    if (
      this.companyProductPlan &&
      this.companyProductPlan[index] &&
      !this.companyProductPlan[index].companyProductPlan.active
    ) {
      return this.productList;
    } else {
      return this.productList.filter((product) => product.active === true);
    }
  }

  disablePlan(item) {
    if (item.active === true) {
      return false;
    } else {
      return true;
    }
  }
  CNPJ(val) {
    if (val && val.length == 18) {
      let cnpj = val.trim();

      cnpj = cnpj.replace(/\./g, "");
      cnpj = cnpj.replace("-", "");
      cnpj = cnpj.replace("/", "");
      cnpj = cnpj.split("");

      let v1 = 0;
      let v2 = 0;
      let aux = false;

      for (let i = 1; cnpj.length > i; i++) {
        if (cnpj[i - 1] != cnpj[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        this.cpfNotValid = false;
        return false || i18n.t("invalidcpfcnpj");
      }

      for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v1 += cnpj[i] * p1;
        } else {
          v1 += cnpj[i] * p2;
        }
      }

      v1 = v1 % 11;

      if (v1 < 2) {
        v1 = 0;
      } else {
        v1 = 11 - v1;
      }

      if (v1 != cnpj[12]) {
        this.cpfNotValid = false;
        return false || i18n.t("invalidcpfcnpj");
      }

      for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v2 += cnpj[i] * p1;
        } else {
          v2 += cnpj[i] * p2;
        }
      }

      v2 = v2 % 11;

      if (v2 < 2) {
        v2 = 0;
      } else {
        v2 = 11 - v2;
      }

      if (v2 != cnpj[13]) {
        this.cpfNotValid = false;
        return false || i18n.t("invalidcpfcnpj");
      } else {
        this.cpfNotValid = true;
        return true;
      }
    } else {
      this.cpfNotValid = false;
      return false || i18n.t("invalidcpfcnpj");
    }
  }
  async searchCep() {
    if (this.localEntity.zipCode.length >= 8) {
      const result: any = await axios.get(
        `https://viacep.com.br/ws/${this.localEntity.zipCode}/json/`
      );
      this.localEntity.district = result.data.bairro;
      this.localEntity.zipCode = result.data.cep;
      this.localEntity.address = result.data.logradouro;
      this.localEntity.city = result.data.localidade;
      this.localEntity.state = result.data.uf;
    }
  }
  get missingSocialName() {
    return !this.localEntity.socialName;
  }
  get missingOfficialName() {
    return !this.localEntity.officialName;
  }
  get missingCnpj() {
    return !this.localEntity.cnpj;
  }
  get validCnpj() {
    const result = this.CNPJ(this.localEntity.cnpj);
    if (result === "invalidcpfcnpj") {
      return true;
    } else {
      return false;
    }
  }
  get missingCode() {
    return !this.localEntity.code;
  }
  get missingPlan() {
    if (!this.listProducts || this.listProducts.length === 0) {
      return true;
    }
    return this.listProducts.some((listProduct) => {
      return !listProduct.product || !listProduct.plan;
    });
  }
  get nextDisabled() {
    return (
      this.validCnpj ||
      this.missingSocialName ||
      this.missingOfficialName ||
      this.missingCnpj ||
      this.missingCode
    );
  }
  get saveDisabled() {
    return (
      this.missingSocialName ||
      this.missingOfficialName ||
      this.missingCnpj ||
      this.missingCode ||
      this.missingPlan
    );
  }
  get disabledIsView() {
    return this.isView;
  }
  get getTitle(){{
    return this.isView ? translate('toView') : translate('edit')
  }}
}
