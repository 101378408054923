import { BaseGroup } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/commonComponents/IBaseItem";
import { translate } from "../plugins/i18n";

const sidebarItems: BaseGroup = [
  // dashboard
  {
    header: `${translate("title.dashboard")}`,
    type: "H",
    permissions: ["DAS", "VI"],
  },

  {
    type: "I",
    icon: "mdi-view-dashboard",
    title: `${translate("title.dashboard")}`,
    to: "/dashboard",
    permissions: ["DAS", "VI"],
  },

  ///gestão
  {
    header: `${translate("title.manager")}`,
    type: "H",
    permissions: [
      { code: "MAS", action: "VI" },
      { code: "EMP", action: "VI" },
      { code: "PRO", action: "VI" },
      { code: "MNT", action: "VI" },
      { code: "NOT", action: "VI" },
      { code: "CAI", action: "VI" },
      { code: "TCN", action: "VI" },
    ],
  },
  {
    type: "I",
    icon: "mdi-office-building",
    title: `${translate("title.company")}`,
    to: "/company",
    permissions: ["EMP", "VI"],
  },

  {
    type: "I",
    icon: "mdi-cart-outline",
    title: `${translate("product")}`,
    to: "/product",
    permissions: ["PRO", "VI"],
  },
  {
    type: "I",
    icon: "mdi-wrench",
    title: `${translate("maintenance")}`,
    to: "/maintenance",
    permissions: ["MNT", "VI"],
  },
  {
    type: "I",
    icon: "mdi-hammer-screwdriver",
    title: `${translate("massiveList")}`,
    to: "/massive",
    permissions: ["MAS", "VI"],
  },
  {
    type: "I",
    icon: "mdi-bell-ring", //alter
    title: `${translate("title.notification")}`,
    to: "/notification",
    permissions: ["NOT", "VI"],
  },

  {
    type: "I",
    icon: "mdi-note-outline",
    title: `${translate("technicalNote")}`,
    to: "/technical-note",
    permissions: ["TCN", "VI"],
  },

  {
    type: "I",
    icon: "mdi-inbox-arrow-down",
    title: `${translate("inbox")}`,
    to: "/maintenance-notification",
    permissions: ["CAI", "VI"],
  },

  ///relatorio
  {
    header: `${translate("reports")}`,
    type: "H",
    permissions: [
      { code: "REL", action: "VI" },
      { code: "AUD", action: "VI" },
      { code: "FIN", action: "VI" },
      { code: "REL", action: "VI" },
      { code: "RFI", action: "VI" },
      { code: "CLO", action: "VI" },
      { code: "INF", action: "VI" },
      { code: "TCK", action: "VI" },
      { code: "CPR", action: "VI" },
    ],
  },
  {
    type: "I",
    icon: "mdi-text-box-multiple", //alter
    title: `${translate("audit")}`,
    to: "/audit",
    permissions: ["AUD", "VI"],
  },
  {
    type: "I",
    icon: "mdi-ticket", //alter
    title: `${translate("tickets")}`,
    to: "/ticket",
    permissions: ["TCK", "VI"],
  },
  {
    type: "I",
    icon: "mdi-lan-pending",
    title: `${translate("infra")}`,
    to: "/infra",
    permissions: ["INF", "VI"],
  },

  {
    type: "I",
    icon: "mdi-finance", //alter
    title: `${translate("financial")}`,
    to: "/financial",
    permissions: ["FIN", "VI"],
  },
  {
    type: "I",
    icon: "mdi-chart-timeline",
    title: `${translate("report")}`,
    to: "/omni-customers",
    permissions: ["CLO", "VI"],
  },

  {
    type: "I",
    icon: "mdi-chart-line", //alter
    title: `${translate("generalReportOmini")}`,
    to: "/report-omini",
    permissions: ["REL", "VI"],
  },

  {
    type: "I",
    icon: "mdi-chart-areaspline", //alter
    title: `${translate("financialReportAudit")}`,
    to: "/report-audit-financial",
    permissions: ["RFI", "VI"],
  },
  {
    type: "I",
    icon: "mdi-chart-areaspline",
    title: `${translate("MetaxChatbos")}`,
    to: "/company-report",
    permissions: ["CPR", "VI"],
  },

  // {
  //   type: "I",
  //   icon: "mdi-chart-line", //alter
  //   title: `${translate("generalReportMatrix")}`,
  //   to: "/report-matrix",
  //   permissions: ["RPM", "VI"],
  // },

  // GDH

  {
    header: "GDH",
    type: "H",
    permissions: [
      { code: "VAC", action: "VI" },
      { code: "DEP", action: "VI" },
    ],
  },
  {
    type: "I",
    icon: "mdi-bag-suitcase",
    title: `${translate("vacation")}`,
    to: "/vacation",
    permissions: ["VAC", "VI"],
  },
  {
    type: "I",
    icon: "mdi-sitemap",
    title: `${translate("sector")}`,
    to: "/department",
    permissions: ["DEP", "VI"],
  },

  //config

  {
    header: `${translate("addConfiguration")}`,
    type: "H",
    permissions: ["CFG", "VI"],
  },

  {
    type: "I",
    icon: "mdi-cog", //alter
    title: `${translate("addConfiguration")}`,
    to: "/omie-config",
    permissions: ["CFG", "VI"],
  },

  // { header: `${translate("title.notification")}`, type: "H",  permissions: [ { code: "BRO", action: "VI" },
  // { code: "TEM", action: "VI" }, { code: "TPT", action: "VI" },]},
  // {
  //   type: "I",
  //   icon: "mdi-artstation",
  //   title: `${translate("broker")}`,
  //   to: "/broker",
  //   permissions:["BRO","VI"]
  // },
  // {
  //   type: "I",
  //   icon: "mdi-at",
  //   title: `${translate("templateList")}`,
  //   to: "/template-list",
  //   permissions: ["TEM", "VI"],
  // },
  // {
  //   type: "I",
  //   icon: "mdi-at",
  //  title: `${translate("templateTypeList")}`,
  //  to: "/template-type-list",
  //
  //   permissions: ["TPT", "VI"],
  // },

  // {
  //   type: "I",
  //   icon: "mdi-variable",
  //   title: `${translate("variableList")}`,
  //   to: "/variable",
  //   permissions: ["VAR", "VI"],
  // },
];

export default sidebarItems;
