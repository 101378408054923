















































































import { Component, Prop } from "vue-property-decorator";

import { Variable } from "./Variable";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import moment from "moment";
@Component

export default class ProductInput extends Request {
  @Prop() readonly entity: Variable;
  @Prop() readonly list: Variable[];
  readonly header = `${translate("templateType")}`;
  active: boolean;
  subtitle: string | undefined = "";
  description: string | undefined = "";
  path: string | undefined = "";
  updatedAt: string = null;
  dialog = false;
  localEntity:any = {};
  async onSave(): Promise<any> {
    this.dialog = false;
    return this.newList();
  }
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  } 
  async newList() {
    Object.assign(this.entity, this.localEntity);
    this.entity.subtitle = this.subtitle;
    this.entity.description = this.description;
    this.entity.path = this.path;
    this.entity.active = this.active;
    const result = await this.save(this.paths.variablePath, this.entity);
    const dbEntity = result;
    dbEntity.updatedAt = moment(dbEntity.updatedAt).format("DD/MM/YYYY");

    if (!this.localEntity.id) {
      this.list.push({
        ...this.entity,
        id: dbEntity.id,
        active: dbEntity.active,
        updatedAt: dbEntity.updatedAt,
      });
      this.localEntity = {};
    }
  }
  async mounted() {
    this.updatedAt = moment(this.updatedAt).format("DD/MM/YYYY");
    this.subtitle = this.entity.subtitle;
    this.description = this.entity.description;
    this.path = this.entity.path;
    this.active = this.entity.active;
    this.load();
  }
  close() {
    this.subtitle = "";
    this.description = "";
    this.path = "";
    this.active = true;
    this.dialog = false;
  }
}
