






































































































































import { Component, Prop } from "vue-property-decorator";
import { IPlan } from "../../types/Plan/IPlan";
import { PlanDetail } from "../../types/Plan/PlanDetail";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

@Component({
  directives: { mask },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class PlanInput extends Request {
  @Prop() readonly entity: IPlan;
  @Prop() readonly list: IPlan[];
  @Prop() readonly loadData: () => void;
  readonly header = `${translate("editPlan")}`;
  dialog: boolean = false;
  price: any = null;
  primaryOffer: boolean = false;
  description: string = '';
  name: string = "";
  productId = this.$route.query.productId;
  active: boolean = true;
  editorUserId: number;
  getUser: any;
  listPlanDetails: PlanDetail[] = [];
  localEntity: any = {};

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async onSave(): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;
    const planDetail = this.listPlanDetails.map((x) => {
      return {
        id: x.id,
        name: x.name,
        value: x.value,
        editorUserId:  x.editorUserId ? x.editorUserId : this.editorUserId,
      };
    });

    if (this.entity.id) {
      this.entity.planDetails = planDetail;
      this.entity.active = this.active;
      this.entity.price = this.price;
      this.entity.name = this.name;
      this.entity.description = this.description;
      this.entity.productId = Number(this.productId);
      this.entity.editorUserId = this.editorUserId;
      const result = await this.post(
        this.paths.planSavePath + `?id=${this.entity.id}`,
        { ...this.entity, editorUserId: this.editorUserId }
      );
      this.entity.active = result.active;
      this.entity.planDetails = result.planDetails;
      this.loadData();
      return result;
    } else {
      this.entity.planDetails = planDetail;
      this.entity.active = this.active;
      this.entity.price = this.price;
      this.entity.name = this.name;
      this.entity.description = this.description;
      this.entity.productId = Number(this.productId);
      this.entity.editorUserId = this.editorUserId;
      const result = await this.save(this.paths.planSavePath, {
        ...this.entity,
        editorUserId: this.editorUserId,
      });
      this.entity.active = result.active;
      this.entity.planDetails = result.planDetails;
      this.loadData();
      return result;
    }
  }

  async saveAndClear() {
    const result = await this.onSave();
    if (!this.entity.id) {
      this.listPlanDetails = [];
      this.listPlanDetails.push({
        name: "",
        value: "",
      });
      this.active = true;
      this.price = "";
      this.name = "";
      this.description = "";
      this.primaryOffer = false;
    }

    this.dialog = false;

    return result;
  }

  async mounted() {
    this.load();
    this.listPlanDetails.push({
      name: "",
      value: "",
    });
    if (this.entity.id) {
      this.active = this.entity.active;
      this.price = this.entity.price;
      this.name = this.entity.name;
      this.primaryOffer = this.entity.primaryOffer;

      this.description = this.entity.description;
      if (this.entity.planDetails) {
        this.listPlanDetails = this.entity.planDetails;
      }
    }
  }
  logicDelete(index) {
    if (index > -1) {
      this.listPlanDetails.splice(index, 1);
    }
  }
  addComponent(): void {
    this.listPlanDetails.push({
      name: "",
      value: "",
      editorUserId: this.editorUserId,
    });
  }
  cancelDialog() {
    if (!this.entity.id) {
      this.listPlanDetails = [];
      this.listPlanDetails.push({
        name: "",
        value: "",
      });
      this.active = false;
      this.price = "";
      this.name = "";
      this.description = "";
      this.primaryOffer = false;
    }
    this.dialog = false;
  }

  get missingName() {
    return !this.name;
  }

  get missingPrice() {
    return !this.price;
  }

  get saveDisabled() {
    return this.missingName || this.missingPrice;
  }
}
