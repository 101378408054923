








































































import MatrixReport from "@matrixbrasil/matrix-lib-report/src/views/MatrixReport.vue";
import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import VueApexCharts from "vue-apexcharts";
@Component({
  components: {
    VueApexCharts,
    MatrixReport,
  },
})
export default class StatisticalReport extends Request {
  @Prop() attendencyReport: any;
  @Prop() conversationReport: any;
  companyId: any = this.$route.query.companyId;
  title = `${translate("reports")}`;
  originalEndDate = "";
  itemsDownload = [];

  headerConversationStatistical = [
    {
      text: `${translate("directionType")}`,
      value: "nameDirection",
    },
    {
      text: `${translate("sectionType")}`,
      value: "nameType",
    },
    {
      text: `${translate("amount")}`,
      value: "totalValue",
    },
  ];
  headerAtendencyStatistical = [
    {
      text: `${translate("amount")}`,
      value: "totalValue",
    },
  ];
  customColumns = [
    {
      slotName: "nameType",
      itemValue: "nameType",
    },
    {
      slotName: "nameDirection",
      itemValue: "nameDirection",
    },
  ];

  path: string = "";
  resolveNameTipe(nameType) {
    switch (nameType) {
      case "Nível Gratuito":
        return `${translate("freeTier")}`;
      case "Regular":
        return `${translate("regular")}`;
      case "Entrada gratuita":
        return `${translate("FREE_ENTRY_POINT")}`;
    }
  }
  downloadCsvConversation() {
    this.conversationReport.map((item) => {
      item.nameDirection = `${translate(item.nameDirection)}`;
      item.nameType = `${translate(item.nameType)}`;
    });
    this.conversationReport.sort((a, b) =>
      a.requiredDate > b.requiredDate ? 1 : -1
    );
    return this.conversationReport;
  }

  downloadCsvAttendency() {
    this.attendencyReport.map((item) => {
      item.nameDirection = `${translate(item.nameDirection)}`;
      item.nameType = `${translate(item.nameType)}`;
    });
    this.attendencyReport.sort((a, b) =>
      a.requiredDate > b.requiredDate ? 1 : -1
    );
    return this.attendencyReport;
  }

  onSort(isDesc, value) {
    if (isDesc == true) {
      let isDescResul = isDesc;
      let index = value[0];
      console.log(isDescResul, index);
    } else {
      let isDescResul = isDesc;
      let index = value[0];
      console.log(isDescResul, index);
    }
  }
}
