










































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import TemplateTypeInput from "./TemplateTypeInput.vue";
import { TemplateType } from "./Template";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import moment from "moment";
@Component({
  components: {
    TemplateTypeInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class TemplateList extends Request {
  page = {
    title: `${translate("templateTypeList")}`,
    icon: null,
  };
  deletePath: string = "";
  breadcrumbs = [
    {
      text: `${translate("templateTypeList")}`,
      disabled: true,
    },
  ];

  list: TemplateType[] = [];
  header = [
    { text: `${translate("name")}`, value: "name" },
    { text: `${translate("status")}`, value: "active" },
    { text: `${translate("updatedAt")}`, value: "updatedAt" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-cart-outline",
    titlePage: `${translate("templateTypeList")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };


  async mounted() {
    const templateTypeResult = await this.get(this.paths.templateTypePath);
    this.list = templateTypeResult[0];
    this.list.forEach((element) => {
      element.updatedAt = moment(element.updatedAt).format("DD/MM/YYYY");
    });

    this.deletePath = this.paths.templateTypePath;
  }
}
