






































































































































import { Component, Watch } from "vue-property-decorator";
import {
  PageHeader,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import Request from "../../services/request";
import { page } from "../../types/Page";
import { translate } from "../../plugins/i18n/index";
import { Breadcrumb } from "../../types/Breadcrumb";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { TCompanyNotification } from "../../types/Notification/TCompanyNotification";
import UploadDialog from "./components/UploadDialog.vue";
import AllData from "./components/AllData.vue";
import MissingData from "./components/MissingData.vue";

@Component({
  components: {
    PageHeader,
    BaseBreadcrumb,
    DatePicker,
    UploadDialog,
    AllData,
    MissingData,
  },
})
export default class CompanyReportAttendency extends Request {
  page: page = {
    title: `${translate("companyReportAttendency")}`,
    icon: null,
  };
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("metaXchatbot")}`,
      disabled: true,
    },
  ];
  items = [
    { id: 1, name: `${translate("all")}`, isFull: true },
    { id: 2, name: `${translate("missingData")}`, isFull: false },
  ];
  count: number = 0;
  tab: number = 0;
  originalMonth: string = "";
  month: string = "";
  year: string = "";
  menuDate: boolean = false;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-chart-areaspline",
    titlePage: `${translate("companyReportAttendency")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  tableIdPdf: string = "";
  entriesCompany: TCompanyNotification[] = [];
  itemsPerPage: number = 10;
  company: any = null;
  searchCompany: string = "";
  listSearch: any = [];
  updateList: boolean = false;
  isFull: boolean = true;
  pagePagination: number = 1;

  async mounted() {
    const companyList = await this.get(
      this.paths.companyPagePath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    const currentDate = new Date();
    this.entriesCompany = companyList.result;
    this.month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    this.year = currentDate.getFullYear().toString();
  }
  async loadData(isFull, isFirst?) {
    isFirst ? (this.pagePagination = 1) : null;
    const params = new URLSearchParams();
    const currentDate = new Date();
    const month = this.month
      ? this.month
      : (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = this.year ? this.year : currentDate.getFullYear().toString();
    params.append("month", month);
    params.append("year", year);
    params.append("take", this.itemsPerPage.toString());
    params.append("skip", String(this.pagePagination));
    if (this.company?.code) params.append("searchParam", this.company.code);
    params.append("orderByParam", "code");
    params.append("orderOrientation", "ASC");
    params.append("isFull", String(isFull));
    const result = await this.get(
      `${this.paths.companyReporttPath}?${params.toString()}`
    );
    this.listSearch = result.result;
    this.count = result.count;
    this.updateList = true;
  }
  uploadPagination(value) {
    this.pagePagination = value.page;
    this.itemsPerPage = value.itemsPerPage;
    this.loadData(this.isFull);
  }
  clearList() {
    this.listSearch = [];
  }
  tableId(id) {
    this.tableIdPdf = id;
  }
  updateMonth(value) {
    this.month = moment(value).format("MM");
  }

  get companiesWithCodeAndSocialName() {
    const companies = this.entriesCompany.map((company) => ({
      ...company,
      codeAndSocialName: `${company.code} - ${company.socialName}`,
    }));

    companies.unshift({
      codeAndSocialName: `0 - ${translate("all")}`,
    });

    return companies;
  }

  customFilter(item, queryText, itemText) {
    item;
    const searchText = queryText.toLowerCase();
    const itemValue = itemText.toLowerCase();
    return itemValue.includes(searchText);
  }
  @Watch("searchCompany")
  async searchCompanies(val) {
    if (val && val.length >= 3) {
      const list = await this.get(
        this.paths.companyPagePath +
          `?take=${this.itemsPerPage}&skip=${1}&searchValue=${val}`
      );
      this.entriesCompany = [...this.entriesCompany, ...list.result];
    }
  }
}
