
























































































































import { Component, Watch } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { mapGetters } from "vuex";
import { IMaintenanceNotification } from "../../types/MaintenanceNotification/IMaintenanceNotification";
import { page } from "../../types/Page";
import { HeaderItem } from "../../types/HeaderItem";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Breadcrumb } from "../../types/Breadcrumb";
import { Options } from "../../types/Options";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import moment from "moment";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PageHeader,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class maintenanceNotificationList extends Request {
  page: page = {
    title: `${translate("inbox")}`,
    icon: null,
  };
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  };
  expanded = [];
  search: string = "";
  deletePath: string = "";
  dynamicHeader: HeaderItem[] = [];

  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  itemId = this.$route.query.item;
  pageCount: number = 0;
  itemsPerPage: number = 10;
  page1: number = 1;
  editorUserId: number = null;
  getUser: any;
  breadcrumbs:Breadcrumb[]  = [
    {
      text: `${translate("inbox")}`,
      disabled: false,
    },
  ];

  list: IMaintenanceNotification[] = [];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-inbox-arrow-down",
    titlePage: `${translate("inbox")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  async loadData(): Promise<void> {
    this.deletePath = this.paths.notificationMaintenance;
    const user = this.getUser();
    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc?.[0] === true ? "DESC" : "ASC";
    let result;
    if (this.itemId) {
      result = await this.get(this.paths.notificationMaintenance + this.itemId);

      if (result) {
        const formattedDate = moment(result.createdAt).format("DD/MM/YYYY");

        this.list = {
          ...result,
          formattedDate: formattedDate,
        };
      }
      this.dynamicHeader = [
        //{ align: "center", value: "result.isChecked" },
        {
          text: `${translate("titleTemplate")}`,
          align: "start",
          value: "result.title",
        },
        {
          text: `${translate("description")}`,
          align: "center",
          value: "result.description",
        },

        { text: `${translate("createdAt")}`, value: "result.formattedDate" },

        {
          text: `${translate("actions")}`,
          value: "actions",
          align: "center",
          sortable: false,
          width: "15%",
        },
      ];
    } else {
      if (!sortBy) {
        result = await this.get(
          this.paths.notificationMaintenance +
            "by-user/" +
            `${user.id}` +
            `?&take=${this.itemsPerPage}&skip=${
              this.page1
            }&orderByParam=${"id"}&orderOrientation=${orientation}`
        );
      } else {
        result = await this.get(
          this.paths.notificationMaintenance +
            "by-user/" +
            `${user.id}` +
            `?&take=${this.itemsPerPage}&skip=${this.page1}&orderByParam=${sortBy}&orderOrientation=${orientation}`
        );
      }

      if (result) {
        this.list = result.result.map((notification) => ({
          ...notification,
          formattedDate: moment(notification.createdAt).format("DD/MM/YYYY"),
        }));
      }
      this.pageCount = Math.ceil(result.count / this.itemsPerPage);

      this.SeeAllNotification();
    }
    this.dynamicHeader = [
      //{ align: "center", value: "isChecked" },
      { text: `${translate("titleTemplate")}`, align: "start", value: "title" },
      {
        text: `${translate("description")}`,
        align: "center",
        value: "description",
      },

      { text: `${translate("createdAt")}`, value: "formattedDate" },
      // { text: `${translate("status")}`, value: "active" },

      {
        text: `${translate("actions")}`,
        value: "actions",
        align: "center",
        sortable: false,
        width: "15%",
      },
    ];
  }

  async SeeAllNotification() {
    const user = this.getUser();

    return await this.patch(
      this.paths.notificationCheckAllPath + `${user.id}`,
      {
        isChecked: true,
      }
    );
  }

  //  translateisChecked(isChecked) {
  //    switch (isChecked) {
  //      case false:
  //        return ' ';
  //      case true:
  //       return ' ';
  //
  //      default:
  //        return isChecked;
  //    }
  //  }
  getIsCheckedStyle(isChecked) {
    let color;
    switch (isChecked) {
      case false:
        color = "";
        break;
      case true:
        color = "";
        break;
      default:
        color = "";
    }

    return { color };
  }

  iconIsChecked(isChecked) {
    switch (isChecked) {
      case false:
        return "mdi-email-outline"; //mdi-email-open-outlin'
      case true:
        return "mdi-email-outline";

      default:
        return isChecked;
    }
  }

  updateOptions(newOptions: any) {
    this.options = { ...this.options, ...newOptions };

    const isFormattedDateSelected =
      this.options?.sortBy?.includes("formattedDate");

    if (isFormattedDateSelected) {
      this.options.sortBy = ["updatedAt"];
    }
    this.page1 = 1
    this.loadData();
  }

  @Watch("$route", { immediate: true, deep: true })
  onRouteChange() {
    this.itemId = this.$route.query.item;

    if (this.itemId !== null) {
      this.loadData();
    }
    
  }
  get header() {
    return this.dynamicHeader;
  }
}
