























































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { IPlan } from "../../types/Plan/IPlan";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import moment from "moment";
import { conclusion, description, observation } from "./editorOptions";
import {
  ITechnicalNote,
  CompanyTechnicalNote,
} from "../../types/TechnicalNote/ITechnicalNote";
import { TCompanyNotification } from "../../types/Notification/TCompanyNotification";

@Component({
  directives: { mask },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class TechnicalNoteInput extends Request {
  @Prop() readonly entity: IPlan;
  @Prop() readonly list: IPlan[];
  @Prop() readonly entriesProduct: any[];
  @Prop() readonly entriesCompanyList: TCompanyNotification[];
  @Prop() readonly loadData: () => void;
  @Prop() readonly manutentionId: string;
  @Prop() readonly companyTechnicalNotesToEdit: CompanyTechnicalNote[];
  conclusion = conclusion;
  description = description;
  observation = observation;
  readonly header = `${translate("technicalNote")}`;
  entriesCompany: TCompanyNotification[] = [];
  entriesProductList: any[] = [];
  dialog: boolean = false;
  menuStartDate: boolean = false;
  menuEndDate: boolean = false;
  originalEndDate: string = "";
  originalStartDate: string = "";
  startDate: string = "";
  endDate: string = "";
  getUser: any;
  localEntity: ITechnicalNote = {};
  companyId: number = null;
  itemsPerPage: number = 10;
  searchCompany: string = "";
  searchProduct: string = "";
  companyTechnicalNotes: CompanyTechnicalNote[] = [];
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async onSave(): Promise<any> {
    let result: ITechnicalNote = {};
    const companyTechnicalNotes = this.companyTechnicalNotes.map((x) => {
      return {
        companyId: x.companyId,
      };
    });

    const user = this.getUser();
    if (this.manutentionId) {
      this.localEntity.manutentionId = this.manutentionId;
    }
    this.localEntity.editorUserId = user?.id;
    this.localEntity.startDate = this.originalStartDate;
    this.localEntity.endDate = this.originalEndDate;
    this.localEntity.companyTechnicalNotes = companyTechnicalNotes;

    if (this.localEntity.id) {
      result = await this.put(
        this.paths.technicalNoteUpdatePath + this.localEntity.id,
        {
          ...this.localEntity,
        }
      );
    } else {
      result = await this.post(this.paths.technicalNoteCreatePath, {
        ...this.localEntity,
      });
    }

    if (!this.localEntity.id) {
      this.companyTechnicalNotes = [];
      this.localEntity.ticketZendesk = null;
      this.localEntity.subject = "";
      this.localEntity.startDate = "";
      this.localEntity.endDate = "";
      this.localEntity.conclusion = "";
      this.localEntity.observation = "";
      this.localEntity.description = "";
    }
    this.loadData();
    this.dialog = false;
    return result;
  }
  async mounted() {
    this.load();
    if (this.manutentionId && !this.localEntity.id) {
      this.dialog = true;
    }
    if (this.companyTechnicalNotesToEdit?.length) {
      this.companyTechnicalNotes = this.companyTechnicalNotesToEdit;
    }
    if (this.localEntity?.id) {
      this.originalStartDate = moment(this.localEntity.startDate).format(
        "YYYY/MM/DD"
      );
      this.originalEndDate = moment(this.localEntity.startDate).format(
        "YYYY/MM/DD"
      );
    }
  }
  cancelDialog() {
    if (!this.localEntity.id) {
      this.companyTechnicalNotes = [];
      this.localEntity.ticketZendesk = null;
      this.localEntity.subject = "";
      this.localEntity.startDate = "";
      this.localEntity.endDate = "";
      this.localEntity.conclusion = "";
      this.localEntity.observation = "";
      this.localEntity.description = "";
    }
    this.dialog = false;
  }

  async handleClearProduct() {
    const product = await this.get(
      this.paths.productPath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    return (this.entriesProductList = product.result);
  }
  @Watch("originalStartDate")
  formatStartDate(value) {
    this.localEntity.startDate = moment(value).format("DD/MM/YYYY");
  }
  @Watch("originalEndDate")
  formatEndDate(value) {
    this.localEntity.endDate = moment(value).format("DD/MM/YYYY");
  }
  @Watch("searchCompany")
  async searchCompanies(val) {
    if (val && val.length >= 3) {
      const list = await this.get(
        this.paths.companyPagePath +
          `?take=${this.itemsPerPage}&skip=${1}&searchValue=${val}`
      );
      this.entriesCompany = [...this.entriesCompany, ...list.result];
    }
    return this.$emit("entries-company", this.entriesCompany);
  }
  @Watch("searchProduct")
  async searchProducts(val) {
    if (val && val.length >= 3) {
      let code = val.substring(0, 4);
      const products = await this.get(
        this.paths.productPath +
          `?take=${this.itemsPerPage}&skip=${1}&searchValue=${code}`
      );
      return (this.entriesProductList = products.result);
    }
    if (val && val.length === 0) {
      const products = await this.get(
        this.paths.productPath + `?take=${this.itemsPerPage}&skip=${1}`
      );
      return (this.entriesProductList = products.result);
    }
  }
  get companiesWithCodeAndSocialName() {
    if (this.entriesCompanyList?.length > 0) {
      return this.entriesCompanyList.map((company) => ({
        ...company,
        companyId: company.id,
        codeAndSocialName: `${company.code} - ${company.socialName}`,
      }));
    } else {
      return this.entriesCompany.map((company) => ({
        ...company,
        companyId: company.id,
        codeAndSocialName: `${company.code} - ${company.socialName}`,
      }));
    }
  }
  get productList() {
    if (this.entriesProductList?.length > 0) {
      return this.entriesProductList;
    } else {
      return this.entriesProduct;
    }
  }
  isFieldEmpty(field, entity) {
    return !entity[field];
  }
  get saveDisabled() {
    const requiredFields = [
      "ticketZendesk",
      "productId",
      "subject",
      "description",
      "observation",
      "conclusion",
      "startDate",
      "endDate",
    ];

    const allFields = { ...this.localEntity };

    const datesFilled = this.localEntity.startDate && this.localEntity.endDate;
    return (
      requiredFields.some((field) => this.isFieldEmpty(field, allFields)) ||
      !datesFilled
    );
  }
  customFilter(item, queryText, itemText) {
    item;
    const searchText = queryText.toLowerCase();
    const itemValue = itemText.toLowerCase();
    return itemValue.includes(searchText);
  }
  removeCompanyType(id) {
    const index = this.companyTechnicalNotes.indexOf(id);
    if (index !== -1) {
      this.companyTechnicalNotes.splice(index, 1);
    }
  }
}
