import { tokenStore } from "../LocalStorage";

export const auth = {
  namespaced: true,
  state: () => ({
    user: null,
    permissions: {},
    logged: false,
    stateLoaded: true,
    productId: null,
    userId: null,
    isSecretTemporary: false,
  }),

  mutations: {
    SET_USER_ID(state, value): void {
      state.userId = value;
    },
    SET_PRODDUCT_ID(state, value): void {
      state.productId = value;
    },
    SET_USER(state, value): void {
      state.user = value;
    },
    SET_PERMISSIONS(state, value): void {
      state.permissions = value;

      state.stateLoaded = true;
    },
    SET_LOGGED(state, value): void {
      state.logged = value;
    },
    SET_SECRET(state, value): void {
      state.isSecretTemporary = value;
    },
  },
  actions: {
    setSecret(context, data):void {
  
      context.commit("SET_SECRET", data);
    },
    setLogin(context, data): void {
      tokenStore.set(data.token);
      context.commit("SET_LOGGED", true);
    },
    setPermissions(context, data): void {
      context.commit("SET_PERMISSIONS", data);
    },
    setUser(context, data) {
      context.commit("SET_USER", data);
    },
    setProductId(context, data): void {
      context.commit("SET_PRODDUCT_ID", data);
    },
    setUserId(context, data) {
      context.commit("SET_USER_ID", data);
    },
  },
  getters: {
    getUser(state): void {
      return state.user;
    },
    getSecret(state): void {
      return state.isSecretTemporary;
    },
    getProductId(state): void {
      return state.productId;
    },
    getUserId(state): void {
      return state.userId;
    },
    getPermissions(state): Array<any> {
      return state.permissions;
    },
    isLogged(state): boolean {
      return state.logged;
    },
  },
};
