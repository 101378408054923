export const paths = {
  //company-manager
  companyPath: "/company-manager/company/",
  companyDeletePath: "/company-manager/company-delete/",
  companyProductPath: "/company-manager/company/save/",
  userPath: "/company-manager/user/",
  clientPath: "/company-manager/client/",
  companyListPath: "/company-manager/company-list/",
  userSavePath: "/company-manager/user/save/",
  deleteUserPath: "/company-manager/user/delete/",
  omiePath: "/company-manager/integration-omie/getAll",
  reactiveUserPath: "/company-manager/user/reactivate/",
  pagiinationCountPath: "/company-manager/company-page",
  userByCompanyPath: "/company-manager/user/list",
  userByCompanylistPath: "/company-manager/user/list/not-deleted",
  userByCompanyPageListPath: "/company-manager/user/page/by-company/",
  companyPagePath: "/company-manager/company/page",
  deleteLogicCompanyPath: "/company-manager/company/delete-logic/",
  companyListZendesk: "/company-manager/company/with-zendesk/page",
  companyInactivePath: "/company-manager/company/change-active/",
  functionalityProductCompanyPage: "/company-manager/functionality/page/by-product/",
  rolePath: "/company-manager/role/",
  profilePath: "/company-manager/profile/",
  profileSavePath: "/company-manager/profile/save/",
  profileUpdatePath: "/company-manager/profile/update/",
  profileFunctionalityActionPath: "/company-manager/profile-functionality-action/",
  profilePageList: "/company-manager/profile/page/by-company/",
  actionPath: "/company-manager/action/",
  functionalityPath: "/company-manager/functionality/",
  functionalityByProductPath: "/company-manager/functionality/list-by-product/",
  functionalityActionPath: "/company-manager/functionality-action/",
  functionalityActionCreatePath: "/company-manager/functionality/create-complete",
  functionalityActionUpdatePath: "/company-manager/functionality/update-complete/",
  notificationListCompany: "/company-manager/company/by-product/",
  companyByTechnicalNotePath: "/company-manager/company/by-technical-note/",
  //company-manager


  //marketplace
  logSheetPath: '/marketplace/log-sheet-report-meta',
  reportConversationPath: "/marketplace/company-conversation/reports",
  NewReportConversationPath: "/marketplace/new-company-conversation/reports",
  reportAttendencyPath: "/marketplace/company-attendency/reports",
  deleteProcutPlanPath: "/marketplace/delete",
  deleteLogicProductPath: "/marketplace/product/delete-logic",
  planSavePath: "/marketplace/plan/save",
  planByProductPath: "/marketplace/plan-by-product/page/",
  companyProductList: "/marketplace/company-product/list-by-company/",
  companyProductPageList: "/marketplace/company-product/page/list-by-company/",
  commpanyProductPlanList: "/marketplace/company-product-plan/list",
  createCommpanyProductPlanPath:"/marketplace/company-product-plan/create",
  companyProductConfig: "/marketplace/company-product-config/",
  companyProductConfigListPath: "/marketplace/company-product-config/list/",
  profileProductPath: "/marketplace/product/with-profile/by-company/",
  functionalityProductCompanyPath: "/marketplace/product/by-company/",
  planPath: "/marketplace/plan/",
  planDetailPath: "/marketplace/plan-detail/",
  productPath: "/marketplace/product/",
  productPagePath: "/marketplace/product/page/",
  productPlanListPath: "/marketplace/product/with-plans/",
  productSavePath: "/marketplace/product/",
  productUpdatePath: "/marketplace/product/update/",
  productSaveValidEscopePath: "/marketplace/product/save",
  maintenanceSavePath: "/marketplace/manutention/save/",
  maintenancePath: "/marketplace/manutention/",
  maintenanceStatusPath: "/marketplace/manutention/update-status/",
  maintenanceUpdatePath: "/marketplace/manutention/update/",
  manutenceUserCompanyPath: "/company-manager/user/by-company/order-by/",
  maintenanceAuthorizationPath: "/marketplace/manutention-authorization/",
  maintenanceListPath: "/marketplace/manutention/list",
  maintenanceListByCompanyPath: "/marketplace/manutention/by-company/",
  companyProductPlanPath: "/marketplace/company-product-plan/",
  omniBlockPath: "/marketplace/ommi/block",
  maintenancePageListPath: "/marketplace/manutention/page",
  configurationOmie: "/marketplace/configuration/create",
  configurationOmieActive: "/marketplace/configuration/get-one",
  generalReportOminiPathList: "/marketplace/general-report-omini/list-by-date",
  generalReportOminiPath: "/marketplace/general-report-omini",
  generalReportOminiPathNew: "/marketplace/new-general-report-omini/list-by-date",
  integrationListByCompanyPath: "/marketplace/integration/page/list-by-company/",
  integrationPathSave: "/marketplace/integration/create",
  integrationErpPath: "/marketplace/erp/",
  integrationPath: "/marketplace/integration",
  integrationUpDatePath: "/marketplace/integration/update",
  vacationListPath: "/marketplace/vacation/list",
  vacationDeletePath: "/marketplace/vacation/delete",
  vacationUpdatePath: "/marketplace/vacation/update/",
  vacationCreatePath: "/marketplace/vacation/create",
  vacationPageListPath: "/marketplace/vacation/page",
  departmentPath: "/marketplace/department",
  departmentPathList: "/marketplace/department/list-by-company",
  departmentDeletePath: "marketplace/department/delete",
  departmentUsersPathList: "/marketplace/department-users/list-by-department/",
  departmentUsersPath: "/marketplace/department-users/",
  departmentUsersPathSave: "marketplace/department-users/save/",
  departmentPagePathList: "/marketplace/department/page/by-company/",
  dashboardCountProductsPath: "/marketplace/dashboard/products",
  companyProductPlanByActivePath: "/marketplace/company-product-plan/by-product-and-blocked/",
  dashboardErpPath: "/marketplace/dashboard/erps",
  integrationByErpPath: "/marketplace/integration/by-erp/",
  configPath: "/marketplace/product-config/",
  whatsappConfigPath: "/marketplace/whatsapp-config/",
  productGetOnePath: "/marketplace/product/by-scope",
  reportFilePath: "/marketplace/report-file",
  reportFileCreatePath: "/marketplace/report-file/create",
  versionPath: "/marketplace/omini-version/",
  notificationCreateSend: "/marketplace/company-notification/create-send",
  notificationGetAll: "/marketplace/company-notification/page",
  notificationSendAll: "/marketplace/company-notification/send",
  notificationCompanyGetOne: "/marketplace/company-product-notification/",
  notificationListView: "/marketplace/company-product-notification/by-company-notification/",
  notificationDelete: "/marketplace/company-notification/",
  ominiCustomersPath: '/marketplace/company-product-plan/report',
  technicalNotePath: "/marketplace/technical-note/",
  technicalNoteCreatePath: "/marketplace/technical-note/create/",
  technicalNoteUpdatePath: "/marketplace/technical-note/update/",
  technicalNoteListPath: "/marketplace/technical-note/page",
  companyReporttPath: "/marketplace/company-report-compare/page",
  companyReportDetailsPath: "/marketplace/company-report-compare/details",
  //marketplace




  //auth
  pathChangePassword: "/auth/change-password/",
  loginPath: "/auth/login/",
  logoutPath: "/auth/logout/",
  forgotPasswordPath: "/auth/forgot-password/temporay-password",
  validationPath: "/auth/validation/",
  //auth

  //notification
  templatePath: "/notification/template/",
  templateCreatePath: "/notification/template-create/",
  templateTypePath: "/notification/template-type/",
  variablePath: "/notification/variable/",
  attachmentPath: "/notification/attachment-template/",
  //notification



  //zendesk
  zendeskTicket: "/zendesk/ticket/by-organization/",
  zendeskTicketMetrics: "/zendesk/ticket-metric/by-ticket/",
  // zendesk

  //zabbix
  zabbixEventPath: "/zabbix/event/",
  zabbixGroupPath: "/zabbix/group/",
  zabbixHostPath: "/zabbix/host/",
  zabbixAlertGroupPath: '/zabbix/event/page-with-user/',
  zabbixMetricsAlertPath: '/zabbix/event/metric/',
  zabbixMetricsPath: '/zabbix/event/metrics',
  //zabbix

  //massive

  massivePath: "/massive/massive/",
  massivePagePath: "/massive/massive-by-company/page/",
  massivePathSave: "/massive/massive/save/",
  //massive

  //channel
  channelDetailPath: "/channel-manager/channel-detail/",
  channelPath: "/channel-manager/channel/",
  brokerPath: "/channel-manager/broker/",
  channelCompletePath: "/channel-manager/channel-complete/",
  //channel

  //notification Maintenance
  notificationMaintenance: "/marketplace/notification/",
  notificationMaintenanceCheck: "/marketplace/notification/not-checked/by-user/",
  notificationCheckAllPath: "/marketplace/notification/check/by-user/",
  redirectionMaintenancePath: "/marketplace/manutention/get-one/",
  authorizationPath: "/marketplace/manutention-authorization/",

  // Audit financial
  financialAuditPath: "/marketplace/general-report-omini-log/list-by-date",
  // Audit financial
  // storage
  uploadReportPath: "/storage/upload/report"
  // storage

};
