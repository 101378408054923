var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.entity && _vm.entity.id)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","title":"Editar"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn__content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","title":"Criar"}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticClass:"mb-3",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.entity.id ? _vm.$t("edit") + _vm.header : _vm.$t("add") + _vm.header)+" "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","rules":[_vm.$rules.fieldsRequired],"label":_vm.$t('from')},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('subject'),"rules":[_vm.$rules.fieldsRequired]},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.templateTypeList,"label":_vm.$t('templateType'),"outlined":"","item-text":"name","item-value":"id","return-object":false,"rules":[_vm.$rules.fieldsRequired]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.templateTypeId),callback:function ($$v) {_vm.templateTypeId=$$v},expression:"templateTypeId"}})],1),_c('v-col',{attrs:{"cols":_vm.entity.id ? 4 : 6}},[_c('v-select',{attrs:{"items":_vm.variableList,"label":_vm.$t('variables'),"outlined":"","item-text":"subtitle","item-value":"id","rules":[_vm.$rules.fieldsRequired],"multiple":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subtitle)+" ")]}}]),model:{value:(_vm.variable),callback:function ($$v) {_vm.variable=$$v},expression:"variable"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-file-input',{attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-paperclip","outlined":"","accept":"image/*","label":_vm.$t('attachment'),"multiple":""},on:{"change":_vm.attachmentFileChange},model:{value:(_vm.attachmentFile),callback:function ($$v) {_vm.attachmentFile=$$v},expression:"attachmentFile"}})],1),(_vm.entity.id)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":_vm.$t('active'),"outlined":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('quill-editor',{attrs:{"content":_vm.message,"options":_vm.editorOption},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"black","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info"},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }