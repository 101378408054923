var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-padding-zero"},[_c('BaseBreadcrumb',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}}),_c('List',{attrs:{"header":_vm.header,"pageHeaderConfig":_vm.pageHeader,"items":_vm.list,"customColumns":_vm.customColumns,"item-key":"id","page":_vm.page,"itemsPerPage":_vm.itemsPerPage,"pageCount":_vm.pageCount,"updateOptions":_vm.updateOptions},scopedSlots:_vm._u([{key:"mainButton",fn:function(){return [_c('ProductInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PRO', 'CR']),expression:"['PRO', 'CR']",modifiers:{"permission":true}}],attrs:{"list":_vm.list,"loadData":_vm.loadData,"entity":{}}})]},proxy:true},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":item.active === true ? '#4CAF50' : '#F90000'}},[_c('span',[_vm._v(" "+_vm._s(item.active === true ? _vm.$t("active") : _vm.$t("inactive")))])])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"icons"},[_c('ProductInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PRO', 'UP']),expression:"['PRO', 'UP']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"entity":item,"loadData":_vm.loadData,"list":_vm.list}}),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PLN', 'VI']),expression:"['PLN', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
            name: 'PlanList',
            query: { productId: item.id, productName: item.name, item: item },
          }}},[_c('v-icon',{attrs:{"small":"","title":_vm.$t('plan')}},[_vm._v(" mdi-notebook-multiple")])],1),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['FUN', 'VI']),expression:"['FUN', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
            name: 'FunctionalityList',
            query: { productId: item.id, item: item },
          }}},[_c('v-icon',{attrs:{"small":"","title":_vm.$t('functionalityByProduct')}},[_vm._v(" mdi-cog-transfer-outline ")])],1),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PCF', 'VI']),expression:"['PCF', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
            name: 'ProductConfigList',
            query: { productId: item.id, item: item },
          }}}),_c('DeleteProduct',{attrs:{"entityDelete":item}})],1)]}},{key:"pagination",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(_vm._s(_vm.$t("itemsPerPage")))]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.loadData},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa-arrow-left","next-icon":"fa-arrow-right","color":"#d3075e","length":_vm.pageCount,"circle":"","total-visible":10},on:{"input":_vm.loadData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }