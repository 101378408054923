



































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { IProduct } from "../../types/Product/IProduct";
import { IMaintenance } from "../../types/Maintenance/IMaintenance";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { TUserMaintenance } from "../../types/Maintenance/TUserMaintenance";
import { TExecutor } from "../../types/Maintenance/TExecutor";
import { IGetUser } from "../../types/IGetUser";
import { TManutentionAuthorization } from "../../types/Maintenance/TManutentionAuthorization";
import { mask } from "vue-the-mask";
import moment from "moment";
import { mapGetters } from "vuex";
@Component({
  directives: { mask },
  components: {
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class MaintenanceInput extends Request {
  @Prop() readonly resultAuthorizations: TManutentionAuthorization;
  @Prop() readonly resultProduct: IProduct[];
  @Prop() readonly resultUsers: TUserMaintenance[];
  @Prop() readonly entity;
  @Prop() readonly list: IMaintenance[];
  @Prop() readonly manutentionId: number;
  @Prop() readonly comapanieId: number;
  @Prop() loadData: () => void;

  readonly header = `${translate("editMaintenance")}`;
  productList: IProduct[] = [];
  selectedProduct: number = null;
  date: string = null;
  dialog: boolean = null;
  menu: boolean = false;
  companyTypeIds: any = [];
  companieId: any;
  entriesCompany: any = [];
  entriesUsers: any = [];
  clientUser: TUserMaintenance[] = [];
  searchCompany: string = "";
  searchAuthorizations: string = "";
  searchUsers: string = "";
  startDateTime: string = "";
  startDateMenu: string = "";
  valid: boolean = false;
  endDateTime: string = "";
  endtDateMenu: boolean = false;
  isSendToAll: boolean = false;
  requester: string = "";
  itemsPerPage: number = 10;
  page: number = 1;
  pageCount: number = 0;
  touchEdit: boolean = false;
  getUser: any;
  user: IGetUser;
  edit: string = `${translate("editMaintenancea")}`;
  description: string = "";
  editorUserId: number;
  resultFind: IMaintenance = null;
  technicalManagerList: TUserMaintenance[] = [];
  technicalManager: TExecutor[] = [];
  authorizations: any[] = [];
  authorizationsList: TUserMaintenance[] = [];
  localEntity: IMaintenance = {};

  async onSave(): Promise<any> {
    this.user = this.getUser();
    this.editorUserId = this.user.id;
    this.entity.manutentionExecutors = [];

    let companies = [];

    for (const companyId of this.companyTypeIds) {
      if (companyId === 0) {
        this.isSendToAll = true;
        companies = [];
      } else {
        companies.push({ companyId: companyId });
      }
    }

    if (!this.entity.id) {
      const user = this.$store.getters["auth/getUser"];
      const requester = user.id;
      this.entity.productId = this.selectedProduct;
      this.entity.date = this.date;
      this.entity.initialTime = this.startDateTime;
      this.entity.finalTime = this.endDateTime;
      this.entity.requester = requester;
      this.entity.manutentionExecutors = this.technicalManager.map(
        (userId) => ({ userId })
      );
      this.entity.status = 1;
      this.entity.manutentionClients = companies;
      this.entity.description = this.description;

      this.entity.manutentionAuthorizations = this.authorizations.map(
        (userId) => ({
          userId,
        })
      );

      const result = await this.save(this.paths.maintenanceSavePath, {
        ...this.entity,
        editorUserId: this.editorUserId,
        isAll: this.isSendToAll,
      });

      if (!this.entity.id) {
        this.dialog = !this.dialog;
        this.selectedProduct = null;
        this.startDateTime = "";
        this.endDateTime = "";
        this.technicalManager = null;
        this.authorizations = null;
        this.date = "";
        this.description = "";
        this.entity.explanation = "";
        this.entity.risk = "";
        this.entity.ascUser = "";
        this.entity.ascOs = "";
        this.entity.ticketMatrix = "";
        this.entity.clientUser = "";
      }
      this.loadData();
      return result;
    } else {
      const user = this.$store.getters["auth/getUser"];
      const requester = user.id;
      this.entity.productId = this.selectedProduct;
      this.entity.manutentionClients = companies;
      this.entity.initialTime = this.startDateTime;
      this.entity.finalTime = this.endDateTime;
      this.entity.requester = requester;
      this.entity.description = this.description;
      this.entity.manutentionExecutors = this.technicalManager.map(
        (userId) => ({ userId })
      );
      this.entity.status = 1;

      if (this.authorizations && this.authorizations.length > 0) {
        const newAuthorizations = this.authorizations.map((userId) => ({
          userId,
        }));
        for (let i = 0; i < this.entity.manutentionAuthorizations.length; i++) {
          const existingAuthorization =
            this.entity.manutentionAuthorizations[i];
          const isMarked = newAuthorizations.some(
            (authorization) =>
              authorization.userId === existingAuthorization.userId
          );
          if (!isMarked) {
            this.entity.manutentionAuthorizations.splice(i, 1);
            i--;
          }
        }
        for (const newAuthorization of newAuthorizations) {
          const exists = this.entity.manutentionAuthorizations.some(
            (authorization) => authorization.userId === newAuthorization.userId
          );
          if (!exists && newAuthorization.userId) {
            this.entity.manutentionAuthorizations.push(newAuthorization);
          }
        }
      }

      const result = await this.save(this.paths.maintenanceUpdatePath, {
        ...this.entity,
        editorUserId: this.editorUserId,
        isAll: this.isSendToAll,
      });

      this.dialog = !this.dialog;
      this.loadData();

      return result;
    }
  }
  async onProductSelected(productId) {
    let companyList;

    if (productId === true) {
      this.selectedProduct = this.entity.productId;
      companyList = await this.get(
        this.paths.notificationListCompany + this.selectedProduct
      );
    } else {
      companyList = await this.get(
        this.paths.notificationListCompany + productId
      );
    }

    const newItem = {
      id: 0,
      officialName: ("0" + " - " + this.$t("sendToAll")) as string,
      code: "0",
    };

    companyList.unshift(newItem);

    this.entriesCompany = companyList;
  }
  async mounted() {
    if (this.getUser().companyId.id) {
      this.user = this.getUser();
      this.companieId = this.user.companyId.id;
    } else {
      this.user = this.getUser();
      this.companieId = this.user.companyId;
    }

    if (this.entity.id) {
      this.selectedProduct = this.entity.productId;
      this.date = moment(new Date(this.entity.date)).format("DD/MM/YYYY");
      this.startDateTime = this.entity.initialTime;
      this.endDateTime = this.entity.finalTime;
      this.companyTypeIds = this.entity.manutentionClients.map(
        (item) => item.companyId
      );
      this.description = this.entity.description;
      this.technicalManager = this.entity.manutentionExecutors.map(
        (executor) => executor.executorUser.id
      );

      this.authorizations = this.entity.manutentionAuthorizations.map(
        (userID) => userID.authorizationUser.id
      );
    }

    const user = this.$store.getters["auth/getUser"];
    this.requester = user.firstName;
    this.productList = this.resultProduct;
    this.technicalManagerList = this.resultUsers;
    this.authorizationsList = this.resultUsers;
  }
  cancelDialog() {
    this.dialog = !this.dialog;
    if (!this.entity.id) {
      this.selectedProduct = null;
      this.startDateTime = "";
      this.endDateTime = "";
      this.technicalManager = null;
      this.authorizations = null;
      this.date = "";
      this.description = "";
      this.entity.explanation = "";
      this.entity.risk = "";
      this.entity.ascUser = "";
      this.entity.ascOs = "";
      this.entity.ticketMatrix = "";
      this.entity.clientUser = "";
    }
  }

  removeTechnicalManager(id) {
    const index = this.technicalManager.indexOf(id);
    if (index !== -1) {
      this.technicalManager.splice(index, 1);
    }
  }
  removeAuthorizations(id) {
    const index = this.authorizations.indexOf(id);
    if (index !== -1) {
      this.authorizations.splice(index, 1);
    }
  }
  removeCompanyType(id) {
    const index = this.companyTypeIds.indexOf(id);
    if (index !== -1) {
      this.companyTypeIds.splice(index, 1);
    }
  }

  @Watch("searchCompany")
  async searchCompanies(val) {
    if (val && val.length >= 3) {
      const list = await this.get(
        this.paths.notificationListCompany +
          this.selectedProduct +
          `&searchValue=${val}`
      );

      if (list && list.result && Array.isArray(list.result)) {
      this.entriesUsers = [...this.entriesUsers, ...list.result];
    }
    }

    return this.$emit("entries-company", this.entriesCompany);
  }

  @Watch("searchUsers")
  async searchUser(val) {
    if (val && val.length >= 3) {
      const list = await this.get(
        this.paths.manutenceUserCompanyPath +
          this.companieId +
          `?orderByParam=firstName&orderOrientation=ASC&searchValue=${val}`
      );
      if (list && list.result && Array.isArray(list.result)) {
      this.entriesUsers = [...this.entriesUsers, ...list.result];
    }
    }
    return this.$emit("entries-users", this.entriesUsers);
  }

  @Watch("searchAuthorizations")
  async searchAhthorization(val) {
    if (val && val.length >= 3) {
      const list = await this.get(
        this.paths.manutenceUserCompanyPath +
          this.companieId +
          `?orderByParam=firstName&orderOrientation=ASC&searchValue=${val}`
      );
      if (list && list.result && Array.isArray(list.result)) {
      this.entriesUsers = [...this.entriesUsers, ...list.result];
    }
    }
    return this.$emit("entries-users", this.entriesUsers);
  }
  customFilter(item, queryText, itemText) {
    item;
    const searchText = queryText.toLowerCase();
    const itemValue = itemText.toLowerCase();
    return itemValue.includes(searchText);
  }

  close() {
    this.dialog = false;
  }

  get saveProduct() {
    return !!this.entity.productId;
  }
  get missingStartTime() {
    return !this.startDateTime;
  }
  get missingAuthorizations() {
    return !this.authorizations || this.authorizations.length === 0;
  }

  get missingEndTime() {
    return !this.endDateTime;
  }

  get missingDate() {
    return !this.date;
  }
  get missingDescription() {
    return !this.description;
  }

  get missingProduct() {
    return !this.selectedProduct;
  }
  get missingCompanyTypeIds() {
    return !this.companyTypeIds || this.companyTypeIds.length === 0;
  }
  get saveDisabled() {
    return (
      this.missingProduct ||
      this.missingCompanyTypeIds ||
      this.missingDate ||
      this.missingStartTime ||
      this.missingEndTime ||
      this.missingAuthorizations ||
      this.missingDescription
    );
  }
}
