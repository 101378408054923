var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":"60%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.entity && _vm.entity.id)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","title":_vm.$t('edit')}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn__content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","title":"Criar"}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.entity.id ? _vm.$t("edit") + _vm.header : _vm.$t("add") + _vm.header)+" "),_c('v-spacer')],1),_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"10"}},[[_c('v-autocomplete',{attrs:{"items":_vm.userList,"label":_vm.$t('name'),"outlined":"","item-text":"fullNames","item-value":"id","return-object":true,"disabled":_vm.entity.id ? true : false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullNames)+" ")]}}]),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})]],2),_c('v-col',{attrs:{"cols":"2"}},[[_c('v-select',{attrs:{"items":_vm.itemsPeriod,"label":_vm.$t('type'),"outlined":"","item-text":"name","item-value":"id","return-object":true,"disabled":_vm.entity.id ? true : false},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})]],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.period == 2 || _vm.period.id == 2 ? 6 : _vm.period ? 4 : 6}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.localEntity.permission,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.localEntity, "permission", $event)},"update:return-value":function($event){return _vm.$set(_vm.localEntity, "permission", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('vestingPeriod'),"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.localEntity.permissionFormated),callback:function ($$v) {_vm.$set(_vm.localEntity, "permissionFormated", $$v)},expression:"localEntity.permissionFormated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.localEntity.permission),callback:function ($$v) {_vm.$set(_vm.localEntity, "permission", $$v)},expression:"localEntity.permission"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.localEntity.permission)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.period == 2 || _vm.period.id == 2 ? 6 : _vm.period ? 4 : 6}},[_c('v-menu',{ref:"menuLimit",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('dateLimit'),"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.localEntity.limitFormated),callback:function ($$v) {_vm.$set(_vm.localEntity, "limitFormated", $$v)},expression:"localEntity.limitFormated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuLimit),callback:function ($$v) {_vm.menuLimit=$$v},expression:"menuLimit"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.localEntity.limit),callback:function ($$v) {_vm.$set(_vm.localEntity, "limit", $$v)},expression:"localEntity.limit"}})],1)],1),(
              _vm.period.id == 1 || _vm.period.id == 2 || _vm.period == 1 || _vm.period == 2
            )?_c('v-col',{attrs:{"cols":_vm.period == 2 || _vm.period.id == 2 ? 6 : 4}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.localEntity.date30,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.localEntity, "date30", $event)},"update:return-value":function($event){return _vm.$set(_vm.localEntity, "date30", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.period == 1 || _vm.period.id == 1
                      ? _vm.$t('vacation') + ' ' + _vm.$t('thirtyDays')
                      : _vm.$t('vacation') + ' ' + _vm.$t('fifteenDays'),"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.localEntity.date30Formated),callback:function ($$v) {_vm.$set(_vm.localEntity, "date30Formated", $$v)},expression:"localEntity.date30Formated"}},'v-text-field',attrs,false),on))]}}],null,false,2444219185),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.localEntity.date30),callback:function ($$v) {_vm.$set(_vm.localEntity, "date30", $$v)},expression:"localEntity.date30"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.localEntity.date30)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1):_vm._e(),(_vm.period.id == 2 || _vm.period == 2)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"return-value":_vm.localEntity.date15,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.localEntity, "date15", $event)},"update:return-value":function($event){return _vm.$set(_vm.localEntity, "date15", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('vacation') + ' ' + _vm.$t('fifteenDays'),"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.localEntity.date15Formated),callback:function ($$v) {_vm.$set(_vm.localEntity, "date15Formated", $$v)},expression:"localEntity.date15Formated"}},'v-text-field',attrs,false),on))]}}],null,false,2223282172),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.localEntity.date15),callback:function ($$v) {_vm.$set(_vm.localEntity, "date15", $$v)},expression:"localEntity.date15"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu3 = false}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu3.save(_vm.localEntity.date15)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"black","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info","disabled":_vm.saveDisabled},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }