




































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import { translate } from "../../../plugins/i18n/index";

import { HeaderItem } from "../../../types/HeaderItem";
import { TranslateResult } from "vue-i18n";
import { itemsPerPage } from "../../../types/itemsPerPage";
@Component
export default class AllData extends Request {
  @Prop() listSearch: any;
  @Prop() year: any;
  @Prop() month: any;

  @Prop() updateList: any;
  header: HeaderItem[] = [
    { text: `${translate("code")}`, align: "center", value: "code" },
    { text: `${translate("month")}`, align: "center", value: "month" },
    { text: `${translate("year")}`, align: "center", value: "year" },
  ];
  tableMissingId = "missingTable";

  singleExpand = false;
  itemsPerPage: number = 10;
  list: any = [];
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page: number = 1;
  pageCount: number = 1;
  search: string = "";

  reportCompare: any = {};
  reportMeta: any = {};
  reportChatBots: any = {};
  defineColor(value) {
    return value === true ? "#ff0000" : "#32906b";
  }

  translateMonth = (month: number): TranslateResult => {
    return this.monthTranslations[month] || "";
  };

  monthTranslations: { [key: number]: TranslateResult } = {
    1: translate("january"),
    2: translate("february"),
    3: translate("march"),
    4: translate("april"),
    5: translate("may"),
    6: translate("june"),
    7: translate("july"),
    8: translate("august"),
    9: translate("september"),
    10: translate("october"),
    11: translate("november"),
    12: translate("december"),
  };
  get validatedMissingDataList() {
    const dataList = this.listSearch;

    return dataList.map((item) => {
      return {
        ...item,
        marketing: item.marketing ?? "--",
        service: item.service ?? "--",
        utility: item.utility ?? "--",
        code: item.code ?? "--",
        month: item.month ? this.translateMonth(item.month) : "--",
        year: item.year ?? "--",
        authentication: item.authentication ?? "--",
      };
    });
  }
}
