import Vue from "vue";
import MassiveList from "@/views/massive/MassiveList.vue";
import AuditList from "@/views/audit/AuditList.vue";
import VueRouter from "vue-router";
import store from "../store";
import Request, { setTokenHeader } from "../services/request";
import { paths } from "../services/apiPaths";
import { tokenStore } from "../store/LocalStorage";
import Layout from "@/layouts/full-layout/Layout.vue";
import InfrastructureList from '@/views/infrastructure/InfrastructureList.vue'
import Blanklayout from "@/layouts/blank-layout/Blanklayout.vue";
import FullLogin from "@/views/auth/FullLogin.vue";
import FullForgotPassword from "@/views/auth/FullForgotPassword.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import UserList from "@/views/user/UserList.vue";
import ProfileList from "@/views/profile/ProfileList.vue";
import CompanyList from "@/views/company/CompanyList.vue";
import ProductList from "@/views/product/ProductList.vue";
import PlanList from "@/views/plan/PlanList.vue";
import FunctionalityList from "@/views/functionality/FunctionalityList.vue";
import ProductConfigList from "@/views/product-config/ProductConfigList.vue";
import ChannelsList from "@/views/channels/ChannelsList.vue";
import BrokerList from "@/views/broker/BrokerList.vue";
import TemplateList from "@/views/templates/TemplateList.vue";
import TemplateTypeList from "@/views/templateType/TemplateTypeList.vue";
import PlanDetailList from "@/views/planDetail/PlanDetailList.vue";
import MaintenanceList from "@/views/maintenance/MaintenanceList.vue";
import VariableList from "@/views/variable/VariableList.vue";
import CompanyProductList from "@/views/companyProduct/CompanyProductList.vue";
import WhatsappConfigList from "@/views/whatsappConfig/WhatsappConfigList.vue";
import SendForgotPassword from "@/views/auth/SendForgotPassword.vue";
import Reports from "@/views/report/Reports.vue";
import FinancialList from "@/views/financial/FinancialList.vue";
import FinancinalAuditReportList from "@/views/financial/FinancinalAuditReportList.vue";
import GeneralReportOminiList from "@/views/financial/GeneralReportOminiList.vue";
import IntegrationList from "@/views/integration/IntegrationList.vue";
import VacationList from "@/views/vacation/VacationList.vue";
import DepartmentList from "@/views/department/DepartmentList.vue";
import OmieConfigInput from "@/views/omie-config/OmieConfigInput.vue";
import NotificationList from "@/views/notification/NotificationList.vue";
import TicketList from "@/views/ticket/TicketList.vue";
import ReportMatrix from '@/views/reportMatrix/ReportMatrix.vue'
import ReportErps from '@/views/reportMatrix/ReportErps.vue'
import OmniCustomersReport from '@/views/OmniCustomersReport/OmniCustomersReport.vue'
import maintenanceNotificationList from "@/views/maintenanceNotification/maintenanceNotificationList.vue";
import TechnicalNoteList from '@/views/technicalNote/TechnicalNoteList.vue'
import CompanyReport from '@/views/companyReport/CompanyReport.vue'
import HistoryMetaXChatbots from '@/views/companyReport/HistoryMetaXChatbots.vue'

Vue.use(VueRouter);

const http = new Request();
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: Layout,
    children: [
      {
        name: "Reports",
        path: "reports",
        component: Reports,
      },
      {
        name: "InfrastructureList",
        path: "infra",
        component: InfrastructureList,
      },
      {
        name: "UserList",
        path: "user",
        component: UserList,
      },
      {
        name: "IntegrationList",
        path: "integration",
        component: IntegrationList,
      },
      {
        name: "Dashboard",
        path: "dashboard",
        component: Dashboard,
      },
      {
        name: "CompanyList",
        path: "company",
        component: CompanyList,
      },
      {
        name: "CompanyProductList",
        path: "company-product",
        component: CompanyProductList,
      },
      {
        name: "ProductList",
        path: "product",
        component: ProductList,
      },
      {
        name: "FunctionalityList",
        path: "functionality",
        component: FunctionalityList,
      },
      {
        name: "ProductConfigList",
        path: "product-config",
        component: ProductConfigList,
      },
      {
        name: "OmieConfigInput",
        path: "omie-config",
        component: OmieConfigInput,
      },
      {
        name: "PlanList",
        path: "plan",
        component: PlanList,
      },
      {
        name: "PlanDetailList",
        path: "plan-detail",
        component: PlanDetailList,
      },
      {
        name: "ProfileList",
        path: "profile",
        component: ProfileList,
      },
      {
        name: "ChannelsList",
        path: "channel",
        component: ChannelsList,
      },
      {
        name: "BrokerList",
        path: "broker",
        component: BrokerList,
      },
      {
        name: "TemplateList",
        path: "template-list",
        component: TemplateList,
      },
      {
        name: "TemplateTypeList",
        path: "template-type-list",
        component: TemplateTypeList,
      },
      {
        name: "VariableList",
        path: "variable",
        component: VariableList,
      },
      {
        name: "MaintenanceList",
        path: "maintenance",
        component: MaintenanceList,
      },
      {
        name: "WhatsappConfigList",
        path: "whatsapp-config",
        component: WhatsappConfigList,
      },
      {
        name: "MassiveList",
        path: "massive",
        component: MassiveList,
      },
      {
        name: "AuditList",
        path: "audit",
        component: AuditList,
      },
      {
        name: "FinancialList",
        path: "financial",
        component: FinancialList,
      },
      {
        name: "NotificationList",
        path: "notification",
        component: NotificationList,
      },
      {
        name: "TicketList",
        path: "ticket",
        component: TicketList,
      },
      {
        name: "GeneralReportOminiList",
        path: "report-omini",
        component: GeneralReportOminiList,
      },
      {
        name: "ReportMatrix",
        path: "report-matrix",
        component: ReportMatrix,
      },
      {
        name: "ReportErps",
        path: "report-erps",
        component: ReportErps,
      },


      {
        name: "VacationList",
        path: "vacation",
        component: VacationList,
      },
      {
        name: "DepartmentList",
        path: "department",
        component: DepartmentList,
      },

      {
        name: "OmniCustomersReport",
        path: "omni-customers",
        component: OmniCustomersReport,
      },

      {
        name: "maintenanceNotificationList",
        path: "maintenance-notification",
        component: maintenanceNotificationList,
      },

      {
        name: "TechnicalNoteList",
        path: "technical-note",
        component: TechnicalNoteList,
      },

      {
        name: "FinancinalAuditReportList",
        path: "report-audit-financial",
        component: FinancinalAuditReportList,
      },
      {
        name: "CompanyReport",
        path: "company-report",
        component: CompanyReport,
      },
      {
        name: "HistoryMetaXChatbots",
        path: "history-sheet",
        component: HistoryMetaXChatbots,
      },

    ],
  },


  {
    path: "/auth",
    component: Blanklayout,
    children: [
      {
        name: "FullLogin",
        path: "login",
        component: FullLogin,
      },
      {
        name: "FullForgotPassword",
        path: "forgotpassword",
        component: FullForgotPassword,
      },
      {
        name: "SendForgotPassword",
        path: "send-forgot-password",
        component: SendForgotPassword,
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});

const validateToken = async (token) => {
  const user = localStorage.getItem("role");

  setTokenHeader(token);
  const result = await http.get(paths.productGetOnePath + "?scope=MKP");


  const product = result.id;

  if (user && product) {
    return http.get(paths.rolePath + user + `?productId=${product}`);
  }
};

router.beforeResolve((to, from, next) => {
  if (from !== from) {
    return;
  }
  const localToken = tokenStore.get();
  if (to.name === "FullLogin") {
    if (localToken) {
      next("/");
    } else {
      tokenStore.set("");
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (from !== from) {
    return;
  }
  if (!store.getters["auth/isLogged"] && to.name === "FullLogin") {
    next();
    store.dispatch("general/endLoading");
  } else if (
    !store.getters["auth/isLogged"] &&
    to.name === "FullForgotPassword"
  ) {
    next();
    store.dispatch("general/endLoading");
  } else if (
    !store.getters["auth/isLogged"] &&
    to.name === "SendForgotPassword"
  ) {
    next();
    store.dispatch("general/endLoading");
  } else if (!store.getters["auth/isLogged"]) {
    store.dispatch("general/startLoading");
    const localToken = tokenStore.get();
    if (localToken) {
      validateToken(localToken)
        .then((response) => {
          store.dispatch("auth/setPermissions", response.permissions);
          store.dispatch("auth/setLogin", {
            user: response.user,
            token: localToken,
          });
          store.dispatch("auth/setUser", response.user);
          store.dispatch("general/endLoading");
          next("/");
        })
        .catch(() => {
          next("/auth/login");
        });
    } else {
      next("/auth/login");
    }
  } else {
    next();
  }
});

export default router;