































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { mapGetters } from "vuex";
import { TCompanyNotification } from "../../types/Notification/TCompanyNotification";
import { translate } from "../../plugins/i18n";
import jsPDF from "jspdf";
import moment from "moment";
import { IMaintenance } from "../../types/Maintenance/IMaintenance";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Authorizations extends Request {
  @Prop() readonly loadData: () => void;
  @Prop() readonly entity: IMaintenance;
  @Prop() readonly companyTechnicalNotes: TCompanyNotification;
  @Prop() readonly validMaintenance: boolean;
  entriesCompany: TCompanyNotification[] = [];
  dialog: boolean = false;
  companies: TCompanyNotification[] = [];
  loading: boolean = false;


  

  removeHtmlTags(htmlString) {
    const imgRegex = /<img\b[^>]*>/i;
    const imgTest = imgRegex.test(htmlString);
    if (imgTest) {
      const match = htmlString.match(imgRegex);

      const textResult = htmlString.replace(/<[^>]*>?/gm, "");

      return textResult + match;
    } else {
      return htmlString.replace(/<[^>]*>?/gm, "");
    }
  }

  async generatePDF(item) {
    const validCompany =
      this.validMaintenance === true
        ? this.companies.map((companyClient) => companyClient.clientCompany)
        : this.companies;

    for (const company of validCompany) {
      const doc = new jsPDF();
      this.loading = true;
      const logoUrl = require("../../assets/images/LOGO PRETA.png");
      const logo = await this.getImageDataFromUrl(logoUrl);

      const imgWidth = 50;
      const imgHeight = (logo.height * imgWidth) / logo.width;
      const marginLeft = doc.internal.pageSize.width - imgWidth - 10;
      const marginTop = 10;
      doc.addImage(logo, "PNG", marginLeft, marginTop, imgWidth, imgHeight);
      const title = String(translate("technicalNote"));
      doc.setFont("helvetica", "bold");
      doc.text(title, 10, 20);
      doc.setLineWidth(0.5);
      doc.line(10, 25, 200, 25);

      let jsonData;
      if (this.validMaintenance === true) {
        jsonData = {
          client: company.officialName,
          subject: item.technicalNote.subject,
          description: this.removeHtmlTags(item.technicalNote.description),
          observation: this.removeHtmlTags(item.technicalNote.observation),
          conclusion: this.removeHtmlTags(item.technicalNote.conclusion),
        };
      } else {
        jsonData = {
          client: company.officialName,
          subject: item.subject,
          description: this.removeHtmlTags(item.description),
          observation: this.removeHtmlTags(item.observation),
          conclusion: this.removeHtmlTags(item.conclusion),
        };
      }

      const clientTitle = `${String(translate("clientResponsable"))}: `;
      const subjectTitle = `${String(translate("subject"))}:`;
      const startDateTitle = `${String(translate("startDate"))}: `;
      const endDateTitle = `${String(translate("endDate"))}: `;
      const descriptionTitle = `${String(translate("incidentReport"))}: `;
      const observationTitle = `${String(translate("metricsObservations"))}: `;
      const conclusionTitle = `${String(translate("analysisConclusion"))}: `;

      doc.setFont("helvetica", "bold");
      doc.text(clientTitle, 10, 40);
      doc.text(subjectTitle, 10, 60);
      doc.text(startDateTitle, 10, 80);
      doc.text(endDateTitle, 10, 100);

      doc.setFont("helvetica", "normal");
      let startDate;
      let endDate;
      const client = ` ${jsonData.client}`;
      const subject = ` ${jsonData.subject}`;
      if (this.validMaintenance === true) {
        startDate = moment(item.technicalNote.startDate).format("DD/MM/YYYY");
        endDate = moment(item.technicalNote.endDate).format("DD/MM/YYYY");
      } else {
        startDate = String(item.startDate);
        endDate = String(item.endDate);
      }

      const description = `${jsonData.description}`;
      const observation = `${jsonData.observation}`;
      const conclusion = `${jsonData.conclusion}`;

      const maxWidth = 170;

      doc.text(client, 30, 40);
      doc.text(subject, 35, 60);
      doc.text(startDate, 45, 80);
      doc.text(endDate, 45, 100);

      let y = 130;

      const addTextAndImageWithPageBreak = async (
        text,
        title,
        startY,
        maxWidth
      ) => {
        const imgRegex = /<img\b[^>]*>/i;
        const match = text.match(imgRegex);

        doc.setFont("helvetica", "bold");
        doc.text(title, 10, startY);
        startY += 10;

        doc.setFont("helvetica", "normal");
        const lines = doc.splitTextToSize(text.replace(imgRegex, ""), maxWidth);
        lines.forEach((line) => {
          if (startY > doc.internal.pageSize.height - 20) {
            doc.addPage();
            startY = 20;
          }

          doc.text(line, 20, startY);
          startY += 10;
        });

        if (match) {
          const imgSource = match[0].match(/src\s*=\s*['"]([^'"]+)['"]/i)[1];
          const img = await this.getImageDataFromUrl(imgSource);
          const imgWidth = 120; // alterar tamanho do imagem download do  pdf (mais que 120 desformata)
          const imgHeight = (img.height * imgWidth) / img.width;
          const x = 20;

          if (startY + imgHeight > doc.internal.pageSize.height - 20) {
            doc.addPage();
            startY = 20;
          }

          doc.addImage(img, "PNG", x, startY, imgWidth, imgHeight);
          startY += imgHeight + 10;
        }

        return startY;
      };

      y = await addTextAndImageWithPageBreak(
        description,
        descriptionTitle,
        y,
        maxWidth
      );

      y += 10;
      y = await addTextAndImageWithPageBreak(
        observation,
        observationTitle,
        y,
        maxWidth
      );

      y += 10;
      y = await addTextAndImageWithPageBreak(
        conclusion,
        conclusionTitle,
        y,
        maxWidth
      );

      doc.save(String(company.officialName));
      this.loading = false;
    }

    this.dialog = false;
  }

  addText(doc, text, title, startY, maxWidth) {
    const lines = doc.splitTextToSize(text, maxWidth);
    doc.setFont("helvetica", "bold");
    doc.text(title, 10, startY);
    doc.setFont("helvetica", "normal");
    let y = startY + 10;
    lines.forEach((line) => {
      if (y > doc.internal.pageSize.height - 20) {
        doc.addPage();
        y = 20;
      }
      doc.text(line, 20, y);
      y += 10;
    });
    return y;
  }
  async getImageDataFromUrl(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }
  removeCompanyType(id) {
    const index = this.companies.findIndex((company) => company.id === id);
    if (index !== -1) {
      this.companies.splice(index, 1);
    }
  }

  get missingTechnical() {
    if (this.entity?.technicalNote?.id) {
      return false;
    } else {
      return true;
    }
  }

  get missingCompanies() {
    return this.companies.length === 0;
  }

  get saveDisabled() {
    return this.missingCompanies;
  }
}
