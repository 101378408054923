





















































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import PlanDetailList from "./../planDetail/PlanDetailList.vue";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";

@Component({
  components: {
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PlanDetailList,
  },
})
export default class ReportErps extends Request {
 
  title = `${translate("generalReportErps")}`;
  id: any = this.$route.query.id;
  item: any = this.$route.query.item;
  itemsPerPageList = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  customColumns = [
    {
      slotName: "isBlocked",
      itemValue: "isBlocked",
    },
  ];
  page = 1;
  pageCount = 0;
  itemsPerPage = 10;
  breadcrumbs = [
    {
      text: `${translate("title.dashboard")}`,
      disabled: false,
      to: "/dashboard/",
    },
    {
      text: `${translate("generalReportErps")}`,
      disabled: true,
    },
  ];
  header = [
    { text: `${translate("Erp")}`, align: "start", value: "erp" },
    { text: `${translate("name")}`, value: "codeAndSocialName" },
    { text: `${translate("cnpj")}`, align: "center", value: "cnpj" },
    { text: `${translate("status")}`, align: "center", value: "active" },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-notebook-multiple",
    titlePage: `${translate("generalReportErps")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  list: any = [];
  options: any = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  };
  async loadData(): Promise<void> {
    const result = await this.get(
      this.paths.integrationByErpPath +
        `${this.id}?take=${this.itemsPerPage}&skip=${this.page}`
    );
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
    this.list = result.result;
    this.list.map((item) => {
      item.erp = this.item.x;
    });

  }

  get listErps() {
    return this.list.map((company) => ({
      ...company,
      codeAndSocialName: `${company.code} - ${company.socialName}`,
    }));
  }

  downloadCsv(list) {
    return list;
  }
  async downloadExcel(listToDownload) {
    this.list = await this.downloadCsv(listToDownload);
    let result, keys;

    if (this.list === null || (!this.list && !this.list.length)) {
      return this.$message?.successMessage(translate("downloadReady"));
    }

    const headersToExport = ["codeAndSocialName", "cnpj", "active", "erp"];

    keys = Object.keys(this.list[0]).filter((key) =>
      headersToExport.includes(key)
    );

    result = "";
    result += keys
      .map((key) => {
        return key === "active" ? translate("status") : key;
      })
      .map((key) => translate(key))
      .join(",");
    result += "\n";

    this.list.forEach((item) => {
      result += keys
        .map((key) => {
          const cellValue =
            key === "active"
              ? item[key]
                ? translate("active")
                : translate("chip.isBlocked")
              : item[key];

          return typeof cellValue === "string" && cellValue.includes(",")
            ? `"${cellValue}"`
            : cellValue;
        })
        .join(",");
      result += "\n";
    });

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(result);
    hiddenElement.target = "_blank";

    hiddenElement.download = "relatório.csv";
    hiddenElement.click();
  }

  updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }
}
