var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.entity && _vm.entity.id)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","title":_vm.$t('edit')}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn__content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","title":"Criar"}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticClass:"mb-3",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.entity.id ? _vm.$t("edit") + _vm.header : _vm.$t("add") + _vm.header)+" "),_c('v-spacer')],1),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.productList,"label":_vm.$t('product'),"outlined":"","item-disabled":_vm.disableItem,"item-text":"name","return-object":true},on:{"change":function($event){return _vm.chargePlan(_vm.selectedProduct)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.listPlans,"label":_vm.$t('plan'),"outlined":"","item-text":"name","item-value":"id","return-object":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.selectedPlan),callback:function ($$v) {_vm.selectedPlan=$$v},expression:"selectedPlan"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"black","text":""},on:{"click":_vm.cancelDialog}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info"},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }