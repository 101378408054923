































































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import ProductInput from "./ProductInput.vue";
import { IProduct } from "../../types/Product/IProduct";
import Request from "../../services/request";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import { Options } from "../../types/Options";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import DeleteProduct from "../../components/commonComponents/DeleteProduct.vue";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import moment from "moment";

@Component({
  components: {
    ProductInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    DeleteProduct,
  },
})
export default class ProductList extends Request {
  path = paths.productPath;
  title = `${translate("product")}`;
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  breadcrumbs:Breadcrumb[]  = [
    {
      text: `${translate("product")}`,
      disabled: true,
    },
  ];
  customColumns = [
    {
      slotName: "active",
      itemValue: "active",
    },
  ];
  header: HeaderItem[]  = [
    { text: `${translate("name")}`, value: "name" },
    { text: `${translate("status")}`, align: "center", value: "active" },
    { text: `${translate("updatedAt")}`, align: "center", value: "updatedAt" },
    { text: `${translate("scope")}`, align: "center", value: "scope" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-cart-outline",
    titlePage: `${translate("product")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  list: IProduct[] = [];
  totalItems:number = 0;
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };

 // async mounted(): Promise<void> {
 //   await this.loadData();
 // }

  async loadData() {
    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";
    let result;

    if (!sortBy) {
      result = await this.get(
        this.paths.productPagePath +
          `?&take=${this.itemsPerPage}&skip=${
            this.page
          }&orderByParam=${"id"}&orderOrientation=${orientation}`
      );
    } else {
      result = await this.get(
        this.paths.productPagePath +
          `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}`
      );
    }

    if (result.result) {
      result.result.map((product) => {
        product.updatedAt = moment(product.updatedAt).format("DD/MM/YYYY");
      });
    }
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
    this.list = result.result;
  }

  updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }
}
