








































































































import { Component, Prop } from "vue-property-decorator";
import { Action,Functionality } from "../../types/Functionality";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { IGetUser } from "../../types/IGetUser";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { mapGetters } from "vuex";
@Component({
  components: {
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class FunctionalityInput extends Request {
  @Prop() readonly entity: Functionality;
  @Prop() readonly list: Functionality[];
  @Prop() readonly loadData: () => void;
  @Prop() readonly actions:Action[];

  dialog:boolean = false;
  selected: number[]= [];
  name:string = "";
  code:string = "";
  header = `${translate("title.addFunctionality")}`;
  productId = this.$route.query.productId;
  functionalityId: number;
  editorUserId: number;
  getUser: any;
  user:IGetUser;

  async mounted(): Promise<void> {


    if (this.entity.id) {
      if (this.entity.functionalityActions) {
        this.selected = this.entity.functionalityActions.map((item) => {
          return item.actionId;
        });
      }

      this.name = this.entity.name;
      this.code = this.entity.code;
    }
  }

  async onSave(): Promise<any> {
    this.user = this.getUser();
    this.editorUserId = this.user.id;

    let result;

    this.entity.name = this.name;
    this.entity.code = this.code;
    this.entity.productId = Number(this.productId);

    this.entity.functionalityActions = [];

    for (const actionId of this.selected) {
      const functionalityAction = {
        active: true,
        actionId,
        functionalityId: this.entity.id,
        editorUserId: this.editorUserId,
      };
      this.entity.functionalityActions.push(functionalityAction);
    }

    if (this.entity.id) {
      result = await this.put(
        this.paths.functionalityActionUpdatePath + this.entity.id,
        { ...this.entity, editorUserId: this.editorUserId }
      );
    } else {
      result = await this.save(this.paths.functionalityActionCreatePath, {
        ...this.entity,
        editorUserId: this.editorUserId,
      });
    }
    if (!this.entity.id) {
      this.selected = [];
      this.name = "";
      this.code = "";
    }

    this.dialog = false;
    this.loadData();
  
    return result;
  }

  uppercase() {
    this.code = this.code.toUpperCase();
  }
  cancelDialog() {
    if (!this.entity.id) {
      this.selected = [];
      this.name = "";
      this.code = "";
    }
    this.dialog = false;
  }
  get saveDisabled() {
    return !this.name || !this.code;
  }
}
