


























































































































import { Component, Prop } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import NotificationInput from "./NotificationInput.vue";

import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { mapGetters } from "vuex";
@Component({
  components: {
    NotificationInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PageHeader,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class NotificationList extends Request {
  @Prop() readonly entity: any;
  dialog = false;
  deletePath: string = "";
  notificationList: any;
  localEntity: any = {};
  editorUserId: any = null;
  getUser: any;
  colored: any = null;
  notificationViewList: any;
  list: any = [];
  itemsPerPageList = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  fakeCompanies: any;
  pageCount = 0;
  itemsPerPage = 10;
  page: number = 1;
  headerSend = [
    {
      text: `${translate("code")}`,
      align: "center",
      sortable: false,
      value: "company.code",
    },
    {
      text: `${translate("title.company")}`,
      align: "center",
      sortable: false,
      value: "company.socialName",
    },
    {
      text: `${translate("status")}`,
      align: "center",
      sortable: false,
      value: "wasSent",
    },
    {
      text: `${translate("Error")}`,
      align: "center",
      sortable: false,
      value: "logError",
    },
    // {
    //    text: `${translate("resend")}`,
    //    align: "center",
    //    value: "status",
    //  },
    //
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
    { text: "", value: "data-table-expand" },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-bell-ring",
    titlePage: `${translate("title.notification")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async mounted() {
    this.load();
  }
  async onSave(item): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;

    const result = await this.save(this.paths.notificationSendAll, {
      editorUserId: this.editorUserId,
      companyNotificationId: item.companyNotificationId,
      companyProductNotifications: [
        {
          companyProductPlanId: item.companyProductPlanId,
          id: item.id,
        },
      ],
    });

    this.dialog = false;
    return result;
  }
  async sendView() {
    const notificationListStatus = await this.get(
      this.paths.notificationListView +
        this.entity.id +
        `?&skip=${this.page}&take=${this.itemsPerPage}`
    );
    this.pageCount = Math.ceil(
      notificationListStatus.count / this.itemsPerPage
    );
    this.list = notificationListStatus.result;
    const notColor = this.list.some((elemento) => elemento.wasSent === false);
    if (notColor) {
      this.colored = true;
    }
    if (!notColor) {
      this.colored = false;
    }
  }
  async loadPagination() {
    const notificationListStatus = await this.get(
      this.paths.notificationListView +
        this.entity.id +
        `?&skip=${this.page}&take=${this.itemsPerPage}`
    );
    this.list = notificationListStatus.result;

    this.pageCount = Math.ceil(
      notificationListStatus.count / this.itemsPerPage
    );
  }

  get listSend() {
    return this.list;
  }
}
