






























































import { Component, Prop } from "vue-property-decorator";

import { TemplateType } from "./Template";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import moment from "moment";
@Component({})
export default class ProductInput extends Request {
  @Prop() readonly entity: TemplateType;
  @Prop() readonly list: TemplateType[];
  readonly header = `${translate("templateType")}`;
  active: any = null;
  name: string = "";
  updatedAt: string = null;
  dialog: boolean = false;
  localEntity: any = {};
  async onSave(): Promise<any> {
    this.dialog = false;
    return this.newList();
  }
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async newList() {
    Object.assign(this.entity, this.localEntity);
    this.entity.name = this.name;
    this.entity.active = this.active;
    const result = await this.save(this.paths.templateTypePath, this.entity);
    const dbEntity = result;
    dbEntity.updatedAt = moment(dbEntity.updatedAt).format("DD/MM/YYYY");

    if (!this.localEntity.id) {
      this.list.push({
        ...this.entity,
        id: dbEntity.id,
        active: dbEntity.active,
        updatedAt: dbEntity.updatedAt,
      });
      this.localEntity = {};
    }
  }
  async mounted() {
    this.updatedAt = moment(this.updatedAt).format("DD/MM/YYYY");
    this.name = this.entity.name;
    this.active = this.entity.active;
    this.load();
  }
  close() {
    if (!this.entity.id) {
      this.active = false;
      this.dialog = false;
      this.name = "";
    } else {
      this.dialog = false;
    }
  }
}
