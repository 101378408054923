
































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import PlanInput from "./PlanInput.vue";
import { IPlan } from "../../types/Plan/IPlan";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import { Breadcrumb } from "../../types/Breadcrumb";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Options } from "../../types/Options";
import PlanDetailList from "./../planDetail/PlanDetailList.vue";
import { HeaderItem } from "../../types/HeaderItem";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    PlanInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PlanDetailList,
  },
})
export default class PlanList extends Request {
  path:string = paths.planPath;
  productId: any = this.$route.query.productId;
  productName: any = this.$route.query.productName;
  title:string = `${translate("plan")}`;
  entity: any = this.$route.query.item;
  planKey:number = 1;
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };

  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("product")}`,
      disabled: false,
      to: "/product/",
    },
    {
      text: `${translate("plan")}`,
      disabled: true,
    },
  ];

  header:HeaderItem[] = [
    { text: `${translate("name")}`, value: "name" },
    { text: `${translate("status")}`, align: "center", value: "active" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-notebook-multiple",
    titlePage: `${translate("plan")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: IPlan[] = [];
  async loadData(): Promise<void> {
    this.planKey += 1;
    this.productName = String(this.$route.query.productName);
    this.productId = Number(this.$route.query.productId);

    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";

    let result;

    if (!sortBy) {
      result = await this.get(
        this.paths.planByProductPath +
          `${this.productId}` +
          `?&take=${this.itemsPerPage}&skip=${
            this.page
          }&orderByParam=${"id"}&orderOrientation=${orientation}`
      );
    } else {
      result = await this.get(
        this.paths.planByProductPath +
          `${this.productId}` +
          `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}`
      );
    }

    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
    this.list = result.result;
  }
  async mounted(): Promise<void> {
    this.loadData();
  }

  updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }
}
