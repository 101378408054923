import {translate} from '../../plugins/i18n'


export const observation = {
    toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        ["link", "image", "video"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["clean"],
        [{ video: true }],
        ["customButton"],
    ],
    clipboard: {
        matchers: [
            [
                "img",
                function (node, delta) {
                    return delta.compose([
                        { insert: { image: node.getAttribute("src") } },
                    ]);
                },
            ],
        ],
    },

    placeholder: translate("observation"),

    theme: "snow",
};

export const description = {
    toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        ["link", "image", "video"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["clean"],
        [{ video: true }],
        ["customButton"],
    ],
    clipboard: {
        matchers: [
            [
                "img",
                function (node, delta) {
                    return delta.compose([
                        { insert: { image: node.getAttribute("src") } },
                    ]);
                },
            ],
        ],
    },

    placeholder: translate("description"),

    theme: "snow",
};
export const conclusion = {
    toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        ["link", "image", "video"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["clean"],
        [{ video: true }],
        ["customButton"],
    ],
    clipboard: {
        matchers: [
            [
                "img",
                function (node, delta) {
                    return delta.compose([
                        { insert: { image: node.getAttribute("src") } },
                    ]);
                },
            ],
        ],
    },

    placeholder: translate("conclusion"),

    theme: "snow",
};