





































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import ProfileInput from "./ProfileInput.vue";
import { IProfile } from "../../types/Profile/IProfile";
import { ICompany } from "../../types/Company/ICompany";
import { itemsPerPage } from "../../types/itemsPerPage";
import { TAction } from "../../types/Profile/TAction";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import { IProduct } from "../../types/Product/IProduct";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    ProfileInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class ProfileList extends Request {
  companyId: number = null;
  entity: ICompany = {};
  deletePath: string = "";
  productList: IProduct[] = [];
  actionList: TAction[] = [];
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  title = `${translate("title.profile")}`;

  breadcrumbs:Breadcrumb[]  = [
    {
      text: `${translate("title.company")}`,
      to: "/company/",
      disabled: false,
    },
    {
      text: `${translate("title.profile")}`,
      disabled: true,
    },
  ];

  header: HeaderItem[]  = [
    { text: `${translate("name")}`, align: "start", value: "name" },
    { text: `${translate("active")}`, align: "start", value: "active" },
    { text: `${translate("product")}`, align: "start", value: "product.name" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-folder-account-outline",
    titlePage: `${translate("profile")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: IProfile[] = [];
  async loadData() {
    this.companyId = Number(this.$route.query.companyId);

    this.deletePath = this.paths.profilePath;
    const result = await this.get(
      this.paths.profilePageList +
        this.companyId +
        `?&take=${this.itemsPerPage}&skip=${1}`
    );

    if (result) {
      this.list = result.result;
    }
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
  }

  async mounted(): Promise<void> {
    this.entity = this.$route.query.item as ICompany
    this.companyId = Number(this.$route.query.companyId);
    const product = await this.get(
      this.paths.functionalityProductCompanyPath + this.companyId
    );
    this.productList = product;

    const actionList = await this.get(this.paths.actionPath);
    this.actionList = actionList[0];
    this.loadData();
  }

  async loadPagination() {
    const profilePageList = await this.get(
      this.paths.profilePageList +
        this.companyId +
        `?take=${this.itemsPerPage}&skip=${this.page}`
    );
    if (profilePageList.result) {
      this.list = profilePageList.result;
    }
    this.pageCount = Math.ceil(profilePageList.count / this.itemsPerPage);
  }

  updateOptions() {
    //this.loadData();
  }
}
