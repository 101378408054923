





























































































































import { Component, Prop } from "vue-property-decorator";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views/index";
import { TCompanyProductConfig } from "../../types/Company/TCompanyProductConfig";
import { TCompanyProductPlan } from "../../types/Company/TCompanyProductPlan";
import { IGetUser } from "../../types/IGetUser";
import { infraTypeList,locationList } from "../../types/InfraLocationType";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
@Component({
  components: {
    InputPopup,
  },
  methods: {
      ...mapGetters({
        getUser: "auth/getUser",
      }),
    },
})
export default class ProductConfig extends Request {
  entity: any = {};
  @Prop() list: TCompanyProductConfig[];
  @Prop() productId: number;
  @Prop() readonly resultConfig:TCompanyProductPlan;
  @Prop() companyId: number;
  @Prop() companyProductPlanId: number;
  header = `${translate("addProductConfig")}`;
  dialog: boolean = false;
  active: boolean = true;
  isDedicated: boolean = true;
  timezoneList: string[] = [];
  timeZonePath: string[] = [];
  show1: boolean= false;
  getUser:any
  user: IGetUser ;
  editorUserId: number = null;
  infraTypeList:infraTypeList[] = [
    {
      id: 1,
      name: "Type 1 (1 Servidor Aplicação e Banco de Dados)",
      value: "1",
    },
    {
      id: 2,
      name: "Type 2 (1 Servidor Aplicação + 1 Servidor de Banco de Dados)",
      value: "2",
    },
    {
      id: 3,
      name: "Type 3 (1 Servidor Aplicação + 2 Servidores de Banco de Dados)",
      value: "3",
    },
  ];
  locationList: locationList[]= [
    { id: 1, name: "AWS Matrix", value: "A" },
    { id: 2, name: "Servidor Cliente Local", value: "L" },
    { id: 3, name: "AWS Cliente", value: "C" },
  ];
  async onSave(): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;
    this.entity.companyProductPlanId = this.companyProductPlanId;

    if (this.entity) {
      const result = await this.save(
        this.paths.companyProductConfig,
        {...this.entity,
        active: this.entity.active,
        isDedicated: this.entity.isDedicated === true ? "1" : "0",
        editorUserId:this.editorUserId
        }
      );
      this.dialog = false;
      return result;
    }
  }
  async mounted() {
    const result = this.resultConfig
    const resultTimeZone = (this.timeZonePath = moment.tz.names());
    this.timezoneList = resultTimeZone;
    if (result) {
     this.entity = result
  } else {
    this.entity = {}
  }
}
  
  get missingDomain() {
    return !this.entity.domain;
  }
  get missingRootUser() {
    return !this.entity.rootUser;
  }
  get missingRootPassword() {
    return !this.entity.rootPassword;
  }
  get missingTimeZone (){
    return !this.entity.timezone;
  }
  
  get missingInfraType() {
    return !this.entity.infraType;
  }
  get missingLocation() {
    return !this.entity.location;
  }
  saveDisabled() {
    return (
      this.missingDomain ||
      this.missingRootUser ||
      this.missingRootPassword ||
      this.missingInfraType ||
      this.missingLocation || this.missingTimeZone
    );
  }
}
