



















































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import BrokerInput from "./BrokerInput.vue";
import { Broker } from "../../types/Broker";
import Request from "../../services/request";
import { page } from "../../types/Page";
import { translate } from "../../plugins/i18n/index";
import { HeaderItem } from "../../types/HeaderItem";
import { Breadcrumb } from "../../types/Breadcrumb";
import { paths } from "../../services/apiPaths";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import ChannelsList from "../channels/ChannelsList.vue";
@Component({
  components: {
    BrokerInput,
    ChannelsList,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class BrokerList extends Request {
  path: string = paths.brokerPath;
  page: page = {
    title: `${translate("broker")}`,
    icon: null,
  };

  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("broker")}`,
      disabled: true,
    },
  ];

  header: HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "name" },
    { text: `${translate("status")}`, align: "center", value: "active" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-artstation",
    titlePage: `${translate("broker")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: Broker[] = [];
  async mounted(): Promise<void> {
    const result = await this.get(this.paths.brokerPath);
    if (result) {
      this.list = result[0];
    }
  }
}
