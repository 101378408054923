var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-padding-zero"},[_c('BaseBreadcrumb',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}}),_c('List',{attrs:{"header":_vm.header,"pageHeaderConfig":_vm.pageHeader,"data":"formattedList","items":_vm.list,"itemCnpj":true,"tagActive":"active","customColumns":_vm.customColumns,"item-key":"id","page":_vm.page,"itemsPerPage":_vm.itemsPerPage,"pageCount":_vm.pageCount,"updateOptions":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.isOmini",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":item.isOmini === true ? '#4CAF50' : '#F90000',"dark":""}},[_c('span',{staticStyle:{"color":"#dcdcdc"}},[_vm._v(" "+_vm._s(item.isOmini === true ? _vm.$t("yes") : _vm.$t("no")))])])]}},{key:"item.isTalk",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":item.isTalk === true ? '#4CAF50' : '#F90000',"dark":""}},[_c('span',{staticStyle:{"color":"#dcdcdc"}},[_vm._v(" "+_vm._s(item.isTalk === true ? _vm.$t("yes") : _vm.$t("no")))])])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":item.active === true ? '#4CAF50' : '#F90000',"dark":""}},[_c('span',{staticStyle:{"color":"#dcdcdc"}},[_vm._v(" "+_vm._s(item.active === true ? _vm.$t("active") : _vm.$t("inactive")))])])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"icons"},[_c('IntegrationInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['INT', 'UP']),expression:"['INT', 'UP']",modifiers:{"permission":true}}],attrs:{"erpList":_vm.erpList,"entity":item,"list":_vm.list,"loadData":_vm.loadData}}),_c('ConfirmDelete',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['INT', 'DE']),expression:"['INT', 'DE']",modifiers:{"permission":true}}],attrs:{"path":_vm.deletePath,"entityId":item.id,"list":_vm.list}})],1)]}}],null,true)},[_c('template',{slot:"mainButton"},[_c('IntegrationInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['INT', 'CR']),expression:"['INT', 'CR']",modifiers:{"permission":true}}],attrs:{"erpList":_vm.erpList,"list":_vm.list,"loadData":_vm.loadData,"entity":{}}})],1),_c('template',{slot:"sideItem"},[_vm._v(" "+_vm._s(_vm.entity.code)+" - "+_vm._s(_vm.entity.socialName)+" ")]),_c('template',{slot:"pagination"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(_vm._s(_vm.$t("itemsPerPage")))]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.loadPagination},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa-arrow-left","next-icon":"fa-arrow-right","color":"#d3075e","length":_vm.pageCount,"circle":"","total-visible":10},on:{"input":_vm.loadPagination},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }