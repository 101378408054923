































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
@Component
export default class ConfirmDeleteDepartment extends Request {
  @Prop() path: string;
  @Prop() entityId: any;
  @Prop() list: any[];
  @Prop() messageDelete: string;
  newPaths: string;
  dialog = false;
  beforeMount() {
    this.newPaths = this.path;
  }
  async deleteItems() {
    await this.remove(this.newPaths + this.entityId, {});
    this.list.splice(
      this.list.findIndex((value) => value.id == this.entityId),
      1
    );
    this.dialog = false;
  }
}
