







































































































import { Component, Watch } from "vue-property-decorator";
import Request from "../../services/request";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { translate } from "../../plugins/i18n";

@Component({
  components: {
    VueApexCharts,
  },
})
export default class CriticalityAlarms extends Request {
  seriesData = [];
  chartOptions = {};
  menuStartDate: boolean = false;
  menuEndDate: boolean = false;
  originalEndDate: string = "";
  originalStartDate: string = "";
  startDate: string = "";
  endDate: string = "";
  initialDate: string = "";
  finalDate: string = "";
  isSearch: boolean = false;
  async mounted() {
    this.initialDate = moment(new Date()).format("YYYY-MM-DD");
    this.finalDate = moment(new Date()).format("YYYY-MM-DD");
    const result = await this.get(
      this.paths.zabbixMetricsPath +
        `?startDate=${this.initialDate}&endDate=${this.finalDate}`
    );
    this.initialDate = moment(this.initialDate).format("DD/MM/YYYY");
    this.finalDate = moment(this.finalDate).format("DD/MM/YYYY");
    const dataArray = Object.keys(result).map((key) => ({
      title: translate(key),
      value: result[key],
    }));

    const colors = dataArray.map(() => this.getColor());
    this.seriesData = [
      {
        name: "valor",
        data: dataArray.map((item, index) => ({
          x: item.title,
          y: item.value,

          fillColor: colors[index],
        })),
      },
    ];
    this.chartOptions = {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#FFF"],
        },
      },
      xaxis: {
        categories: dataArray.map((item) => item.title),
        position: "bottom",
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#FFF",
          },
          rotate: -70,
          trim: false,
          offsetX: 1,
          offsetY: 0,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
          style: {
            colors: this.getColor(),
          },
        },
      },
      fill: {
        colors: colors,
      },
    };
  }
  async seach() {
    const result = await this.get(
      this.paths.zabbixMetricsPath +
        `?startDate=${this.originalStartDate}&endDate=${this.originalEndDate}`
    );
    this.isSearch = true;
    this.resolveTitle();
    const dataArray = Object.keys(result).map((key) => ({
      title: translate(key),
      value: result[key],
    }));

    const colors = dataArray.map(() => this.getColor());
    this.seriesData = [
      {
        name: "valor",
        data: dataArray.map((item, index) => ({
          x: item.title,
          y: item.value,

          fillColor: colors[index],
        })),
      },
    ];
    this.chartOptions = {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#A9A9A9"],
        },
      },
      xaxis: {
        categories: dataArray.map((item) => item.title),
        position: "bottom",
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#A9A9A9",
          },
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#A9A9A9",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
          style: {
            colors: this.getColor(),
          },
        },
      },
      fill: {
        colors: colors,
      },
    };
  }
  getColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  resolveTitle() {
    let prefix = translate("criticalityAlarms");
    let fromLabel = translate("criticality.from");
    let toLabel = translate("criticality.to");

    if (!this.isSearch) {
      return `${prefix} ${fromLabel} ${this.initialDate} ${toLabel} ${this.finalDate}`;
    } else {
      return `${prefix} ${fromLabel} ${this.startDate} ${toLabel} ${this.endDate}`;
    }
  }
  get missingStartDate() {
    return !this.startDate;
  }
  get missingEndDate() {
    return !this.endDate;
  }
  get saveDisabled() {
    return this.missingEndDate || this.missingStartDate;
  }
  @Watch("originalStartDate")
  formatStartDate(value) {
    this.startDate = moment(value).format("DD/MM/YYYY");
  }
  @Watch("originalEndDate")
  formatEndDate(value) {
    this.endDate = moment(value).format("DD/MM/YYYY");
  }
}
