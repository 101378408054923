







































































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import NotificationInput from "./NotificationInput.vue";
import NotificationPopup from "./NotificationPopup.vue";
import { itemsPerPage } from "../../types/itemsPerPage";
import { INotification } from "../../types/Notification/INotification";
import { TCompanyNotification } from "../../types/Notification/TCompanyNotification";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import { Options } from "../../types/Options";
import { TProduct } from "../../types/Company/TProduct";
import { page } from "../../types/Page";
import { mapGetters } from "vuex";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import moment from "moment";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    NotificationInput,
    NotificationPopup,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PageHeader,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class NotificationList extends Request {
  page: page = {
    title: `${translate("title.notification")}`,
    icon: null,
  };
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  };
  search: string = "";
  deletePath: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];

  pageCount: number = 0;
  itemsPerPage: number = 10;
  page1: number = 1;
  localEntity: INotification = {};
  productId: TProduct[] = [];
  editorUserId: number = null;
  getUser: any;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.notification")}`,
      disabled: false,
    },
  ];
  entriesCompany: TCompanyNotification[] = [];
  list: INotification[] = [];
  header: HeaderItem[] = [
    { text: `${translate("user")}`, align: "start", value: "user.firstName" },
    //{ text: `${translate("lastName")}`,align: "center", value: "user.lastName" },
    { text: `${translate("titleTemplate")}`, align: "center", value: "title" },

    //{
    //   text: `${translate("messageTemplate")}`,
    //  align: "center",
    //   value: "message",
    // },
    { text: `${translate("updatedAt")}`, value: "formattedDate" },
    // { text: `${translate("status")}`, value: "active" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-bell-ring",
    titlePage: `${translate("title.notification")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  async mounted() {
    const scopeList = await this.get(
      this.paths.productGetOnePath + "?scope=" + "OMI"
    );
    this.productId = scopeList;

    const companyList = await this.get(
      this.paths.companyPagePath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    this.entriesCompany = companyList.result;
  }

  async loadData(): Promise<void> {
    this.deletePath = this.paths.notificationDelete;

    let templateResult;
    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";

    if (!sortBy) {
      templateResult = await this.get(
        this.paths.notificationGetAll +
          `?&take=${this.itemsPerPage}&skip=${
            this.page1
          }&orderByParam=${"id"}&orderOrientation=${orientation}`
      );
    } else {
      templateResult = await this.get(
        this.paths.notificationGetAll +
          `?&take=${this.itemsPerPage}&skip=${this.page1}&orderByParam=${sortBy}&orderOrientation=${orientation}`
      );
    }

    if (templateResult) {
      this.list = templateResult.result.map((notification) => ({
        ...notification,
        formattedDate: moment(notification.updatedAt).format("DD/MM/YYYY"),
      }));
    }
    this.pageCount = Math.ceil(templateResult.count / this.itemsPerPage);
  }

  async sendOn(item) {
    const user = this.getUser();
    this.editorUserId = user.id;

    const result = await this.save(this.paths.notificationSendAll, {
      editorUserId: this.editorUserId,
      companyNotificationId: item.id,
    });

    return result;
  }
  get companiesWithCodeAndSocialName() {
    const newItem = {
      id: 0,
      socialName: this.$t("sendToAll") as string,
      code: "0",
      companyProductPlanId: 0,
    };

    this.entriesCompany.unshift(newItem);

    return this.entriesCompany.map((company) => ({
      ...company,
      codeAndSocialName: `${company.code} - ${company.socialName}`,
    }));
  }
  entriesCompanyEmited(companies) {
    this.entriesCompany = [...this.entriesCompany, ...companies];
  }

  updateOptions(newOptions: any) {
    this.options = { ...this.options, ...newOptions };

    const isFormattedDateSelected =
      this.options?.sortBy?.includes("formattedDate");

    if (isFormattedDateSelected) {
      this.options.sortBy = ["updatedAt"];
    }

    this.loadData();
  }
}
