






























import { Component } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import { paths } from "../../services/apiPaths";
import { ForgotPassword } from "@matrixbrasil/matrix-lib-auth-frontend/src/views";
import { EventBus } from "../../services/eventBus";

@Component({
  components: { ForgotPassword },
})
export default class FullForgotPassword extends Request {
  mounted() {
    this.path = paths.forgotPasswordPath;
  }
  buttonText: string = `${translate("button.send")}`;
  emailText: string = `${translate("email")}`;
  phoneText: string = `${translate("phone")}`;
  path: string = "";
  scope: string = "MKP";
  grantType: string = "password";
  logo = require("@/assets/logos-new/LOGO 1.png");
  images = [
    {
      src: require("@/assets/images/IMG-02.jpg"),
    },
  ];
  valid: boolean = true;
  email: string = "";
  emailRules = [
    (v: string) => !!v || `${translate("emailIsRequired")}`,
    (v: string) => /.+@.+\..+/.test(v) || `${translate("validEmail")}`,
  ];
  created() {
    EventBus.$on("getStatus", (response) => {
      try {
        if (response === 200) {
          this.$message?.successMessage(translate("message.successSendMail"));
        } else {
          console.log('aq')
          this.$message?.errorMessage(translate("error.error"));
        }
      } catch (error) {
        console.log(error)
        this.$message?.errorMessage(translate("error.error"));
      }
    });
  }
  async forgotPassword(email: string) {
    return await this.get(this.path + `?email=${email}`);
  }
}
