var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.entity && _vm.entity.id)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","title":_vm.$t('toView')},on:{"click":function($event){return _vm.sendView()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn__content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","title":"Criar"}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticClass:"mb-3",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.entity.id ? _vm.$t("toView") + _vm.header : _vm.$t("add") + _vm.header)+" "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('titleTemplate'),"disabled":_vm.valid},model:{value:(_vm.localEntity.title),callback:function ($$v) {_vm.$set(_vm.localEntity, "title", $$v)},expression:"localEntity.title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"search-input":_vm.searchCompany,"items":_vm.entriesCompanyList,"label":_vm.$t('codeCompany'),"outlined":"","item-text":"codeAndSocialName","item-value":"id","multiple":"","filter":_vm.customFilter,"clearable":true},on:{"update:searchInput":function($event){_vm.searchCompany=$event},"update:search-input":function($event){_vm.searchCompany=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{key:item.id,on:{"click":function($event){return _vm.removeCompanyType(item.id)}}},[_vm._v(" "+_vm._s(item.codeAndSocialName)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.codeAndSocialName)+" "),_c('v-icon',{on:{"click":function($event){return _vm.removeCompanyType(item.id)}}},[_vm._v("mdi-close-circle")])],1)]}}]),model:{value:(_vm.companyTypeIds),callback:function ($$v) {_vm.companyTypeIds=$$v},expression:"companyTypeIds"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('quill-editor',{attrs:{"content":_vm.message,"options":_vm.editorOption,"disabled":_vm.valid},model:{value:(_vm.localEntity.message),callback:function ($$v) {_vm.$set(_vm.localEntity, "message", $$v)},expression:"localEntity.message"}})],1)],1)],1),(!_vm.valid)?_c('div',[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"black","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info","disabled":_vm.saveDisabled},on:{"click":function($event){return _vm.onSave()}}},[_c('v-icon',{staticClass:"icon-distance",attrs:{"small":"","title":_vm.$t('resend')}},[_vm._v(" mdi-send ")]),_vm._v(" "+_vm._s(_vm.$t("button.send"))+" ")],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }