var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center ml-4",attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.resolveTitle()))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{staticClass:"custom-menu",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({
              width: '50px',
              height: '50px',
              top: '30px',
              left: '1rem',
            }),attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-filter-outline")])],1)]}}])},[_c('v-row',{staticStyle:{"background":"#2c1c3d"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"mt-5 ml-5 mb-2 mr-5"},[_vm._v(_vm._s(_vm.$t("filter")))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuStartDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5 mr-5",attrs:{"outlined":"","label":_vm.$t('startDate'),"prepend-icon":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.originalStartDate),callback:function ($$v) {_vm.originalStartDate=$$v},expression:"originalStartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuEndDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5 mr-5",attrs:{"outlined":"","label":_vm.$t('endDate'),"prepend-icon":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.originalEndDate),callback:function ($$v) {_vm.originalEndDate=$$v},expression:"originalEndDate"}})],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"primary ml-5 mb-5 mr-5",attrs:{"disabled":_vm.saveDisabled},on:{"click":_vm.seach}},[_vm._v(_vm._s(_vm.$t("search"))+" ")])],1)],1)],1),_c('apexchart',{style:({ width: '100%', height: '250px' }),attrs:{"type":"bar","options":_vm.chartOptions,"series":_vm.seriesData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }