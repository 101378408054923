




























































































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import { Options } from "../../types/Options";
import { HeaderItem } from "../../types/HeaderItem";
import { itemsPerPage } from "../../types/itemsPerPage";
import { IMonthList } from "../../types/IMonthList";
import { IGeneralOminiNew} from "../../types/GeneralOminiReport/IGeneralOminiNew";
import { IGeneralOminiList} from "../../types/GeneralOminiReport/IGeneralOminiList";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { Breadcrumb } from "../../types/Breadcrumb";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

@Component({
  components: {
    List,
    BaseBreadcrumb,
    DatePicker,
  },
})
export default class GeneralReportOminiList extends Request {
  path:string = paths.generalReportOminiPath;
  title:string = `${translate("generalReportOmini")}`;
  list: IGeneralOminiList[] | IGeneralOminiNew[] = [];
  selectedCode: string = '';
  filteredItems: IGeneralOminiList[] | IGeneralOminiNew[] = [];
  dynamicHeader: HeaderItem[] = [];
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  };
  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("generalReportOmini")}`,
      disabled: true,
    },
  ];
  customColumns = [
    {
      slotName: "monthList.month",
      itemValue: "monthList.month",
    },
  ];
  monthList: IMonthList[] = [
    { id: 1, title: `${translate("january")}` },
    { id: 2, title: `${translate("february")}` },
    { id: 3, title: `${translate("march")}` },
    { id: 4, title: `${translate("april")}` },
    { id: 5, title: `${translate("may")}` },
    { id: 6, title: `${translate("june")}` },
    { id: 7, title: `${translate("july")}` },
    { id: 8, title: `${translate("august")}` },
    { id: 9, title: `${translate("september")}` },
    { id: 10, title: `${translate("october")}` },
    { id: 11, title: `${translate("november")}` },
    { id: 12, title: `${translate("december")}` },
  ];
  month:string = "";
  year: string = "";
  isLoading:boolean = false;
  valid:boolean = false;
  search: string = "";
  itemsPerPageList : itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-chart-line",
    titlePage: `${translate("generalReportOmini")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    crudDetail: "false",
  };

  async seach(item:boolean) {

    if (item == true) {
      this.page =1 
    }
    let result;
    let monthInt = parseInt(this.month);
    let yearInt = parseInt(this.year);

    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";

    if (!sortBy) {
      if (this.selectedCode) {
        if (yearInt <= 2023 && monthInt < 6) {
          this.valid = true;
          result = await this.get(
            this.paths.generalReportOminiPathList +
              "?month=" +
              monthInt +
              "&year=" +
              yearInt +
              `&take=${this.itemsPerPage}&skip=${
                this.page
              }&orderByParam=${"id"}&orderOrientation=${orientation}&code=${
                this.selectedCode
              }`
          );
          this.pageCount = Math.ceil(result.count / this.itemsPerPage);
          this.list = result.result;
  
          this.filteredItems = this.list;

          this.dynamicHeader = [
            {
              text: `${translate("companyCode")}`,
              align: "start",
              value: "code",
            },
            {
              text: `${translate("year")}`,
              align: "start",
              value: "year",
              filter: this.filterYear,
            },
            {
              text: `${translate("month")}`,
              align: "center",
              value: "month",
              filter: this.filterMonth,
            },
            {
              text: `${translate("attendencyTotalValue")}`,
              align: "center",
              value: "attendencyTotalValue",
            },
            {
              text: `${translate("conversationUserRegular")}`,
              align: "center",
              value: "conversationUserRegular",
            },
            {
              text: `${translate("conversationUserFree")}`,
              align: "center",
              value: "conversationUserFree",
            },
            {
              text: `${translate("conversationCompanyRegular")}`,
              align: "center",
              value: "conversationCompanyRegular",
            },
            {
              text: `${translate("conversationCompanyFree")}`,
              align: "center",
              value: "conversationCompanyFree",
            },
            {
              text: `${translate("FREE_ENTRY_POINT")}`,
              align: "center",
              value: "conversationCompanyFreeEntryPoint",
            },
          ];
        } else if ((yearInt >= 2023 && monthInt >= 6) || yearInt >= 2024) {
          this.valid = true;
          result = await this.get(
            this.paths.generalReportOminiPathNew +
              "?month=" +
              monthInt +
              "&year=" +
              yearInt +
              `&take=${this.itemsPerPage}&skip=${
                this.page
              }&orderByParam=${"id"}&orderOrientation=${orientation}&code=${
                this.selectedCode
              }`
          );
          this.pageCount = Math.round(result.count / this.itemsPerPage);
          this.list = result.result;
   
          this.filteredItems = this.list;

          this.dynamicHeader = [
            {
              text: `${translate("companyCode")}`,
              align: "start",
              value: "code",
            },
            {
              text: `${translate("year")}`,
              align: "start",
              value: "year",
              filter: this.filterYear,
            },
            {
              text: `${translate("month")}`,
              align: "center",
              value: "month",
              filter: this.filterMonth,
            },
            {
              text: `${translate("attendencyTotalValue")}`,
              align: "center",
              value: "attendencyTotalValue",
            },
            {
              text: `${translate("conversationAuthenticationRegular")}`,
              align: "center",
              value: "conversationAuthenticationRegular",
            },
            {
              text: `${translate("conversationMarketingRegular")}`,
              align: "center",
              value: "conversationMarketingRegular",
            },
            {
              text: `${translate("conversationUtilityRegular")}`,
              align: "center",
              value: "conversationUtilityRegular",
            },
            {
              text: `${translate("conversationServiceFree")}`,
              align: "center",
              value: "conversationServiceFree",
            },

            {
              text: `${translate("conversationServiceRegular")}`,
              align: "center",
              value: "conversationServiceRegular",
            },
            {
              text: `${translate("conversationServiceFreeEntryPoint")}`,
              align: "center",
              value: "conversationServiceFreeEntryPoint",
            },
          ];
        }
      } else if (!this.selectedCode) {
        if (yearInt <= 2023 && monthInt < 6) {
          this.valid = true;
          result = await this.get(
            this.paths.generalReportOminiPathList +
              "?month=" +
              monthInt +
              "&year=" +
              yearInt +
              `&take=${this.itemsPerPage}&skip=${
                this.page
              }&orderByParam=${"id"}&orderOrientation=${orientation}`
          );
          this.pageCount = Math.ceil(result.count / this.itemsPerPage);
          this.list = result.result;
          this.filteredItems = this.list;

          this.dynamicHeader = [
            {
              text: `${translate("companyCode")}`,
              align: "start",
              value: "code",
            },
            {
              text: `${translate("year")}`,
              align: "start",
              value: "year",
              filter: this.filterYear,
            },
            {
              text: `${translate("month")}`,
              align: "center",
              value: "month",
              filter: this.filterMonth,
            },
            {
              text: `${translate("attendencyTotalValue")}`,
              align: "center",
              value: "attendencyTotalValue",
            },
            {
              text: `${translate("conversationUserRegular")}`,
              align: "center",
              value: "conversationUserRegular",
            },
            {
              text: `${translate("conversationUserFree")}`,
              align: "center",
              value: "conversationUserFree",
            },
            {
              text: `${translate("conversationCompanyRegular")}`,
              align: "center",
              value: "conversationCompanyRegular",
            },
            {
              text: `${translate("conversationCompanyFree")}`,
              align: "center",
              value: "conversationCompanyFree",
            },
            {
              text: `${translate("FREE_ENTRY_POINT")}`,
              align: "center",
              value: "conversationCompanyFreeEntryPoint",
            },
          ];
        } else if ((yearInt >= 2023 && monthInt >= 6) || yearInt >= 2024) {
          this.valid = true;
          result = await this.get(
            this.paths.generalReportOminiPathNew +
              "?month=" +
              monthInt +
              "&year=" +
              yearInt +
              `&take=${this.itemsPerPage}&skip=${
                this.page
              }&orderByParam=${"id"}&orderOrientation=${orientation}`
          );
          this.pageCount = Math.round(result.count / this.itemsPerPage);
          this.list = result.result;
          this.filteredItems = this.list;

          this.dynamicHeader = [
            {
              text: `${translate("companyCode")}`,
              align: "start",
              value: "code",
            },
            {
              text: `${translate("year")}`,
              align: "start",
              value: "year",
              filter: this.filterYear,
            },
            {
              text: `${translate("month")}`,
              align: "center",
              value: "month",
              filter: this.filterMonth,
            },
            {
              text: `${translate("attendencyTotalValue")}`,
              align: "center",
              value: "attendencyTotalValue",
            },
            {
              text: `${translate("conversationAuthenticationRegular")}`,
              align: "center",
              value: "conversationAuthenticationRegular",
            },
            {
              text: `${translate("conversationMarketingRegular")}`,
              align: "center",
              value: "conversationMarketingRegular",
            },
            {
              text: `${translate("conversationUtilityRegular")}`,
              align: "center",
              value: "conversationUtilityRegular",
            },
            {
              text: `${translate("conversationServiceFree")}`,
              align: "center",
              value: "conversationServiceFree",
            },

            {
              text: `${translate("conversationServiceRegular")}`,
              align: "center",
              value: "conversationServiceRegular",
            },
            {
              text: `${translate("conversationServiceFreeEntryPoint")}`,
              align: "center",
              value: "conversationServiceFreeEntryPoint",
            },
          ];
        }
      }
    } else {
      if (this.selectedCode) {
        if (yearInt <= 2023 && monthInt < 6) {
          this.valid = true;
          result = await this.get(
            this.paths.generalReportOminiPathList +
              "?month=" +
              monthInt +
              "&year=" +
              yearInt +
              `&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}&code=${this.selectedCode}`
          );
          this.pageCount = Math.ceil(result.count / this.itemsPerPage);
          this.list = result.result;
          this.filteredItems = this.list;

          this.dynamicHeader = [
            {
              text: `${translate("companyCode")}`,
              align: "start",
              value: "code",
            },
            {
              text: `${translate("year")}`,
              align: "start",
              value: "year",
              filter: this.filterYear,
            },
            {
              text: `${translate("month")}`,
              align: "center",
              value: "month",
              filter: this.filterMonth,
            },
            {
              text: `${translate("attendencyTotalValue")}`,
              align: "center",
              value: "attendencyTotalValue",
            },
            {
              text: `${translate("conversationUserRegular")}`,
              align: "center",
              value: "conversationUserRegular",
            },
            {
              text: `${translate("conversationUserFree")}`,
              align: "center",
              value: "conversationUserFree",
            },
            {
              text: `${translate("conversationCompanyRegular")}`,
              align: "center",
              value: "conversationCompanyRegular",
            },
            {
              text: `${translate("conversationCompanyFree")}`,
              align: "center",
              value: "conversationCompanyFree",
            },
            {
              text: `${translate("FREE_ENTRY_POINT")}`,
              align: "center",
              value: "conversationCompanyFreeEntryPoint",
            },
          ];
        } else if ((yearInt >= 2023 && monthInt >= 6) || yearInt >= 2024) {
          this.valid = true;
          result = await this.get(
            this.paths.generalReportOminiPathNew +
              "?month=" +
              monthInt +
              "&year=" +
              yearInt +
              `&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}&code=${this.selectedCode}`
          );
          this.pageCount = Math.round(result.count / this.itemsPerPage);
          this.list = result.result;
          this.filteredItems = this.list;

          this.dynamicHeader = [
            {
              text: `${translate("companyCode")}`,
              align: "start",
              value: "code",
            },
            {
              text: `${translate("year")}`,
              align: "start",
              value: "year",
              filter: this.filterYear,
            },
            {
              text: `${translate("month")}`,
              align: "center",
              value: "month",
              filter: this.filterMonth,
            },
            {
              text: `${translate("attendencyTotalValue")}`,
              align: "center",
              value: "attendencyTotalValue",
            },
            {
              text: `${translate("conversationAuthenticationRegular")}`,
              align: "center",
              value: "conversationAuthenticationRegular",
            },
            {
              text: `${translate("conversationMarketingRegular")}`,
              align: "center",
              value: "conversationMarketingRegular",
            },
            {
              text: `${translate("conversationUtilityRegular")}`,
              align: "center",
              value: "conversationUtilityRegular",
            },
            {
              text: `${translate("conversationServiceFree")}`,
              align: "center",
              value: "conversationServiceFree",
            },

            {
              text: `${translate("conversationServiceRegular")}`,
              align: "center",
              value: "conversationServiceRegular",
            },
            {
              text: `${translate("conversationServiceFreeEntryPoint")}`,
              align: "center",
              value: "conversationServiceFreeEntryPoint",
            },
          ];
        }
      } else if (!this.selectedCode) {
        if (yearInt <= 2023 && monthInt < 6) {
          this.valid = true;
          result = await this.get(
            this.paths.generalReportOminiPathList +
              "?month=" +
              monthInt +
              "&year=" +
              yearInt +
              `&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}`
          );
          this.pageCount = Math.ceil(result.count / this.itemsPerPage);
          this.list = result.result;
          this.filteredItems = this.list;

          this.dynamicHeader = [
            {
              text: `${translate("companyCode")}`,
              align: "start",
              value: "code",
            },
            {
              text: `${translate("year")}`,
              align: "start",
              value: "year",
              filter: this.filterYear,
            },
            {
              text: `${translate("month")}`,
              align: "center",
              value: "month",
              filter: this.filterMonth,
            },
            {
              text: `${translate("attendencyTotalValue")}`,
              align: "center",
              value: "attendencyTotalValue",
            },
            {
              text: `${translate("conversationUserRegular")}`,
              align: "center",
              value: "conversationUserRegular",
            },
            {
              text: `${translate("conversationUserFree")}`,
              align: "center",
              value: "conversationUserFree",
            },
            {
              text: `${translate("conversationCompanyRegular")}`,
              align: "center",
              value: "conversationCompanyRegular",
            },
            {
              text: `${translate("conversationCompanyFree")}`,
              align: "center",
              value: "conversationCompanyFree",
            },
            {
              text: `${translate("FREE_ENTRY_POINT")}`,
              align: "center",
              value: "conversationCompanyFreeEntryPoint",
            },
          ];
        } else if ((yearInt >= 2023 && monthInt >= 6) || yearInt >= 2024) {
          this.valid = true;
          result = await this.get(
            this.paths.generalReportOminiPathNew +
              "?month=" +
              monthInt +
              "&year=" +
              yearInt +
              `&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}`
          );
          this.pageCount = Math.round(result.count / this.itemsPerPage);
          this.list = result.result;
          this.filteredItems = this.list;

          this.dynamicHeader = [
            {
              text: `${translate("companyCode")}`,
              align: "start",
              value: "code",
            },
            {
              text: `${translate("year")}`,
              align: "start",
              value: "year",
              filter: this.filterYear,
            },
            {
              text: `${translate("month")}`,
              align: "center",
              value: "month",
              filter: this.filterMonth,
            },
            {
              text: `${translate("attendencyTotalValue")}`,
              align: "center",
              value: "attendencyTotalValue",
            },
            {
              text: `${translate("conversationAuthenticationRegular")}`,
              align: "center",
              value: "conversationAuthenticationRegular",
            },
            {
              text: `${translate("conversationMarketingRegular")}`,
              align: "center",
              value: "conversationMarketingRegular",
            },
            {
              text: `${translate("conversationUtilityRegular")}`,
              align: "center",
              value: "conversationUtilityRegular",
            },
            {
              text: `${translate("conversationServiceFree")}`,
              align: "center",
              value: "conversationServiceFree",
            },

            {
              text: `${translate("conversationServiceRegular")}`,
              align: "center",
              value: "conversationServiceRegular",
            },
            {
              text: `${translate("conversationServiceFreeEntryPoint")}`,
              align: "center",
              value: "conversationServiceFreeEntryPoint",
            },
          ];
        }
      }
    }
  }
  updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    if (this.month && this.year) {
      this.page = 1;
      this.seach(true);
    }
  }

  resolveMonth(month) {
    if (month == 1) {
      return (month = `${translate("january")}`);
    }
    if (month == 2) {
      return (month = `${translate("february")}`);
    }
    if (month == 3) {
      return (month = `${translate("march")}`);
    }
    if (month == 4) {
      return (month = `${translate("april")}`);
    }
    if (month == 5) {
      return (month = `${translate("may")}`);
    }
    if (month == 6) {
      return (month = `${translate("june")}`);
    }
    if (month == 7) {
      return (month = `${translate("july")}`);
    }
    if (month == 8) {
      return (month = `${translate("august")}`);
    }
    if (month == 9) {
      return (month = `${translate("september")}`);
    }
    if (month == 10) {
      return (month = `${translate("october")}`);
    }
    if (month == 11) {
      return (month = `${translate("november")}`);
    }
    if (month == 12) {
      return (month = `${translate("december")}`);
    }
  }
  filterMonth(value) {
    if (!this.month) {
      return true;
    }
    return value == this.month;
  }
  filterYear(value) {
    if (!this.year) {
      return true;
    }
    return value == this.year;
  }

  get header() {
    return this.dynamicHeader;
  }

  get missingMonth() {
    return !this.month;
  }
  get missingYear() {
    return !this.year;
  }

  get saveDisabled() {
    return this.missingYear || this.missingMonth;
  }
}
