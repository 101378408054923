


















































































































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import FinancialInput from "./FinancialInput.vue";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Options } from "../../types/Options";
import { TProduct } from "../../types/Company/TProduct";
import { IFinancial } from "../../types/Financial/IFinancial";
import { IMonthList } from "../../types/IMonthList";
import { Breadcrumb } from "../../types/Breadcrumb";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import ChannelsList from "../channels/ChannelsList.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapGetters } from "vuex";

@Component({
  components: {
    FinancialInput,
    ChannelsList,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    DatePicker,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class BrokerList extends Request {
  path:string = paths.brokerPath;
  companyId = this.$route.query.companyId;
  search: string = "";
  itemsPerPageList : itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  title:string = `${translate("financial")}`;
  list: IFinancial[] = [];
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  };
  breadcrumbs:Breadcrumb[]  = [
    {
      text: `${translate("financial")}`,
      disabled: true,
    },
  ];
  customColumns = [
    {
      slotName: "month",
      itemValue: "month",
    },
  ];
  monthList:IMonthList[] = [
    { id: 1, title: `${translate("january")}` },
    { id: 2, title: `${translate("february")}` },
    { id: 3, title: `${translate("march")}` },
    { id: 4, title: `${translate("april")}` },
    { id: 5, title: `${translate("may")}` },
    { id: 6, title: `${translate("june")}` },
    { id: 7, title: `${translate("july")}` },
    { id: 8, title: `${translate("august")}` },
    { id: 9, title: `${translate("september")}` },
    { id: 10, title: `${translate("october")}` },
    { id: 11, title: `${translate("november")}` },
    { id: 12, title: `${translate("december")}` },
  ];
  month:number = null;
  year:number = null;
  productList:TProduct[] = [];
  product:string = "";
  user: any;
  getUser: any;
  isLoading:boolean = false;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-finance",
    titlePage: `${translate("financial")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  async generateDownload(item) {
    const link = document.createElement("a");
    link.href = item;
    link.click();
    this.isLoading = false;
  }
  async loadData() {
    if (this.getUser().companyId.id) {
      this.user = this.getUser();
      this.companyId = this.user.companyId.id;
    } else {
      this.user = this.getUser();
      this.companyId = this.user.companyId;
    }

    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";

    let result;

    if (!sortBy) {
      result = await this.get(
        this.paths.reportFilePath +
          `?&take=${this.itemsPerPage}&skip=${
            this.page
          }&orderByParam=${"id"}&orderOrientation=${orientation}`
      );
    } else {
      result = await this.get(
        this.paths.reportFilePath +
          `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}`
      );
    }

    this.pageCount = Math.ceil(result[1].count / this.itemsPerPage);
    this.list = result[0];
 

    const products = await this.get(
      this.paths.functionalityProductCompanyPath + this.companyId
    );
    this.productList = products;
    
  }
  updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }

  resolveMonth(month) {
    if (month == 1) {
      return (month = `${translate("january")}`);
    }
    if (month == 2) {
      return (month = `${translate("february")}`);
    }
    if (month == 3) {
      return (month = `${translate("march")}`);
    }
    if (month == 4) {
      return (month = `${translate("april")}`);
    }
    if (month == 5) {
      return (month = `${translate("may")}`);
    }
    if (month == 6) {
      return (month = `${translate("june")}`);
    }
    if (month == 7) {
      return (month = `${translate("july")}`);
    }
    if (month == 8) {
      return (month = `${translate("august")}`);
    }
    if (month == 9) {
      return (month = `${translate("september")}`);
    }
    if (month == 10) {
      return (month = `${translate("october")}`);
    }
    if (month == 11) {
      return (month = `${translate("november")}`);
    }
    if (month == 12) {
      return (month = `${translate("december")}`);
    }
  }
  filterMonth(value) {
    if (!this.month) {
      return true;
    }
    return value == this.month;
  }
  filterProduct(value) {
    if (!this.product) {
      return true;
    }
    return value == this.product;
  }
  filterYear(value) {
    if (!this.year) {
      return true;
    }
    return value == this.year;
  }
  get header() {
    return [
      {
        text: `${translate("name")}`,
        align: "start",
        value: "nameProduct",
        filter: this.filterProduct,
      },
      {
        text: `${translate("year")}`,
        align: "center",
        value: "year",
        filter: this.filterYear,
      },
      {
        text: `${translate("month")}`,
        align: "center",
        value: "month",
        filter: this.filterMonth,
      },
      {
        text: `${translate("actions")}`,
        value: "actions",
        align: "center",
        sortable: false,
        width: "15%",
      },
    ];
  }
}
