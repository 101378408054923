












































































































import { Component } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import { paths } from "../../services/apiPaths";
import { PasswordConfirmation } from "@matrixbrasil/matrix-lib-auth-frontend/src/views";

@Component({
  components: { PasswordConfirmation },
})
export default class FullForgotPassword extends Request {
  mounted() {
    this.path = paths.forgotPasswordPath;
  }
  buttonText:string = `${translate("button.send")}`;
  emailText:string = `${translate("email")}`;
  phoneText:string = `${translate("phone")}`;
  path:string = "";
  codeModel: string = "";
  scope:string = "MKP";
  grantType:string = "password";
  password: string = null;
  confirmPassword: string = "";
  logo = require("@/assets/logos-new/LOGO 1.svg");
  show1: boolean = false;
  show2: boolean = false;
  isValid: boolean = false;
  absolute:boolean = true;
  overlay:boolean = false;
  isLoading:boolean = false;
  isLoadingCode:boolean = false;
  images = [
    {
      src: require("@/assets/images/IMG-02.jpg"),
    },
  ];

  validateVerificationCode(code:string) {
    this.isLoadingCode = true;
    if (code === "123456") {
      this.isLoadingCode = false;
      return (this.isValid = true);
    } else {
      this.$message.errorMessage(`${translate("incorrectCode")}`);
      this.isLoadingCode = false;
      return (this.isValid = false);
    }
  }

  get missingPassword() {
    return !this.password;
  }
  get missingConfirmPassword() {
    return (
      !!this.password &&
      (!this.confirmPassword || this.password != this.confirmPassword)
    );
  }
  get blockSendEmail() {
    return this.missingPassword || this.missingConfirmPassword;
  }
  get blockSendCodeVerification() {
    return this.codeModel.length < 6;
  }
}
