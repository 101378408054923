




































































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import IntegrationInput from "./IntegrationInput.vue";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import Request from "../../services/request";
import { IIntegration} from "../../types/Integration/IIntegration";
import { TErp} from "../../types/Integration/TErp";
import { translate } from "../../plugins/i18n/index";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import moment from "moment";

@Component({
  components: {
    IntegrationInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class IntegrationList extends Request {
  title = `${translate("integration")}`;
  companyId = this.$route.query.companyId;
  entity: IIntegration = {};
  deletePath: string = "";
  erpList: TErp[] = [];
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page: number = 1;
  pageCount: number = 0;
  itemsPerPage: number = 10;
  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("title.company")}`,
      to: "/company/",
      disabled: false,
    },
    {
      text: `${translate("integration")}`,
      disabled: true,
    },
  ];
  customColumns = [
    {
      slotName: "item.isTalk",
      itemValue: "item.isTalk",
    },
    {
      slotName: "item.isOmini",
      itemValue: "item.isOmini",
    },
    {
      slotName: "item.active",
      itemValue: "item.active",
    },
  ];
  header: HeaderItem[]  = [
    { text: `${translate("ERP")}`, value: "erp.name" },
    { text: `${translate("storagePort")}`, value: "port" },
    { text: `${translate("status")}`, align: "center", value: "item.active" },
    { text: `Omini`, align: "center", value: "item.isOmini" },
    { text: `Talk`, align: "center", value: "item.isTalk" },
    {
      text: `${translate("updatedAt")}`,
      align: "center",
      value: "formattedDate",
    },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-lan-pending",
    titlePage: `${translate("integration")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: IIntegration[] = [];
  async loadData(): Promise<void> {
    this.deletePath = this.paths.integrationPath + "/";
    const integrationResult = await this.get(
      this.paths.integrationListByCompanyPath +
        `${this.companyId}` +
        `?&take=${this.itemsPerPage}&skip=${1}`
    );

    if (integrationResult) {
      this.list = integrationResult.result.map((integration) => ({
        ...integration,
        formattedDate: moment(integration.updatedAt).format("DD/MM/YYYY"),
        erp: {
          ...integration.erp,
          name: integration.erp.name.toUpperCase(),
        },
      }));
    }

    this.pageCount = Math.ceil(integrationResult.count / this.itemsPerPage);
  }
  async mounted(): Promise<void> {
    if (this.$route.query.item) {
      const item = this.$route.query.item as IIntegration;
      this.entity = item;
    }
    const erp = await this.get(this.paths.integrationErpPath);
    this.erpList = erp[0].map((obj) => ({
      ...obj,
      name: obj.name.toUpperCase(),
    }));
    this.loadData();
  }

  async loadPagination() {
    const integrationPageList = await this.get(
      this.paths.integrationListByCompanyPath +
        this.companyId +
        `?take=${this.itemsPerPage}&skip=${this.page}`
    );
    if (integrationPageList.result) {
      this.list = integrationPageList.result.map((integration) => ({
        ...integration,
        formattedDate: moment(integration.updatedAt).format("DD/MM/YYYY"),
        erp: {
          ...integration.erp,
          name: integration.erp.name.toUpperCase(),
        },
      }));
    }
    this.pageCount = Math.ceil(integrationPageList.count / this.itemsPerPage);
  }

  updateOptions() {
    // this.loadData();
  }
}
