


























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { Department } from "../../types/Departament/Department";
import { TUserlist } from "../../types/Vacation/TUserlist";
import { translate } from "../../plugins/i18n/index";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

@Component({
  components: {},
  directives: { mask },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class UsersDepartmentInput extends Request {
  @Prop() readonly entity: Department;
  @Prop() readonly loadData: () => void;
  @Prop() readonly userList: TUserlist[];
  @Prop() readonly departmentId: number;
 readonly header:string = `${translate("employees")}`;

  dialog: boolean = false;
  name: string = "";
  companyId = this.$route.query.companyId;
  selectedUser: any = [];
  localEntity: any ={};
  editorUserId: number = null;
  getUser: any;
  firstName: string = "";
  userId:number =null;
  active: boolean = true;
  id:number;

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async onSave(): Promise<any> {
    const user = this.getUser();
    this.companyId = user.companyId.id;
    this.editorUserId = user.id;
    this.active = user.active;
    const users = this.selectedUser.map((user) => {
      return {
        userId: user.id,
        active: user.active,
      };
    });

    const result = await this.save(this.paths.departmentUsersPathSave, {
      users: users,
      departmentId: this.departmentId,
      editorUserId: this.editorUserId,
      active: this.active,
    });
    if (!this.localEntity.id) {
      this.selectedUser = "";
      this.localEntity = {};
    }
    this.loadData();
    this.dialog = false;
    return result;
  }

  disableItem(item) {
    const isSelected = this.selectedUser.find((x) => x.id === item.id);
    if (isSelected) return true;
  }

  async mounted() {


    this.load();
    const user = this.getUser();
    this.companyId = user.companyId.id;
    this.editorUserId = user.id;
    this.active = this.entity.active;

    if (this.localEntity.id) {
      this.selectedUser = this.localEntity.userId;
      this.load();
    }
   
    if (this.localEntity.id) {
      const departmentUsers = await this.get(
        this.paths.departmentUsersPathList + this.departmentId
      );
      this.selectedUser = departmentUsers.map((user) => {
        return {
          id: user.userId,
          active: user.active,
        };
      });
    }
  }
  logicDelete(index) {
    if (index > -1) {
      this.selectedUser.splice(index, 1);
    }
  }
  addComponent(): void {
    this.selectedUser.push({
      active: (this.active = true),
    });
  }

  get missingName() {
    return !this.selectedUser;
  }
  get saveDisabled() {
    return this.missingName;
  }
}
