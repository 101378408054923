









































































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import VacationInput from "./VacationInput.vue";
import Request from "../../services/request";
import moment from "moment";
import { IVacation } from "../../types/Vacation/IVacation";
import { TUserlist } from "../../types/Vacation/TUserlist";
import { itemsPerPage } from "../../types/itemsPerPage";
import { HeaderItem } from "../../types/HeaderItem";
import { Breadcrumb } from "../../types/Breadcrumb";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    VacationInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PageHeader,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class VacationList extends Request {
  deletePath: string = "";
  title:string = `${translate("GDH")}`;
  search: string = "";
  expanded = [];
  listVacationsDetails: IVacation[] = [];
  companyId = this.$route.query.companyId;
userList:TUserlist[] = [];
itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  breadcrumbs:Breadcrumb[]   = [
    {
      text: `${translate("vacation")}`,
      disabled: false,
      to: "/vacation/",
    },
  ];
  // customColumns = [
  //  {
  //    slotName: "item.vacationDetails",
  //    itemValue: "item.vacationDetails",
  //   },
  // ];
  header:HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "user.firstName" },
    {
      text: `${translate("surname")}`,
      align: "start",
      value: "user.lastName",
    },

    {
      text: `${translate("vacationAllowancePeriodStart")}`,
      align: "center",
      value: "formattedPermissionInicialDate",
    },
    {
      text: `${translate("vacationAllowancePeriodEnd")}`,
      align: "center",
      value: "formattedPermissionEndDate",
    },
    {
      text: `${translate("dateLimit")}`,
      align: "center",
      value: "formattedLimit",
    },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  headerVacation:HeaderItem[] = [
    {
      text: `${translate("startOfVacation")}`,
      align: "center",
      value: "formattedStartDate",
    },
    {
      text: `${translate("endOfVacation")}`,
      align: "center",
      value: "formattedEndDate",
    },

    {
      text: `${translate("startOfVacation2")}`,
      align: "center",
      value: "formattedStartDate2",
    },
    {
      text: `${translate("endOfVacation2")}`,
      align: "center",
      value: "formattedEndDate2",
    },
    {
      text: `${translate("type")}`,
      align: "center",
      value: "item.vacationDetails",
    },
    // {
    //   text: `${translate("actions")}`,
    //   value: "actions",
    //   align: "center",
    //   sortable: false,
    //   width: "15%",
    // },
    { text: "", value: "data-table-expand" },
  ];
  getUser: any;
  user: any;
  pageHeaderp: TPageHeader = {
    mainIcon: "mdi-bag-suitcase",
    titlePage: `${translate("vacation")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: IVacation [] = [];
  async loadData(): Promise<void> {
    this.deletePath = this.paths.vacationDeletePath + "/";

    let vacationResult = await this.get(
      this.paths.vacationPageListPath + `?&take=${this.itemsPerPage}&skip=${1}`
    );
    if (vacationResult.result) {
      this.list = vacationResult.result.map((vacation) => {
        return {
          ...vacation,
          formattedPermissionEndDate: moment(vacation.permissionEndDate).format(
            "DD/MM/YYYY"
          ),
          formattedPermissionInicialDate: moment(
            vacation.permissionInicialDate
          ).format("DD/MM/YYYY"),
          formattedLimit: moment(vacation.limit).format("DD/MM/YYYY"),
        };
      });
    }
    this.pageCount = Math.ceil(vacationResult.count / this.itemsPerPage);
  }

  async loadDetails({ item }): Promise<void> {
    const vacationDetails = item.vacationDetails;
    if (vacationDetails && vacationDetails.length === 2) {
      const detail1 = vacationDetails[0];
      const detail2 = vacationDetails[1];

      this.listVacationsDetails = [
        {
          ...detail1,
          "item.vacationDetails": translate("fifteenDays"),
          formattedStartDate: moment(detail1.startDate).format("DD/MM/YYYY"),
          formattedEndDate: moment(detail1.endDate).format("DD/MM/YYYY"),
          formattedStartDate2: moment(detail2.startDate).format("DD/MM/YYYY"),
          formattedEndDate2: moment(detail2.endDate).format("DD/MM/YYYY"),
        },
      ];
    } else {
      this.listVacationsDetails = vacationDetails.map((detail) => ({
        ...detail,
        "item.vacationDetails": `${translate("thirtyDays")}`,
        formattedStartDate: moment(detail.startDate).format("DD/MM/YYYY"),
        formattedEndDate: moment(detail.endDate).format("DD/MM/YYYY"),
        formattedStartDate2: "----",
        formattedEndDate2: "----",
      }));
    }
  }

  async mounted(): Promise<void> {
    this.loadData();

    if (this.getUser().companyId.id) {
      this.user = this.getUser();
      this.companyId = this.user.companyId.id;
    } else {
      this.user = this.getUser();
      this.companyId = this.user.companyId;
    }

    const userResult = await this.get(
      this.paths.userByCompanylistPath + "/" + this.companyId
    );

    const fullNames = userResult.map((user) => {
      return {
        id: user.id,
        fullNames: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
      };
    });

    this.userList = fullNames;
  }

  async loadPagination() {
    const vacationPageList = await this.get(
      this.paths.vacationPageListPath +
        `?take=${this.itemsPerPage}&skip=${this.page}`
    );
    if (vacationPageList.result) {
      this.list = vacationPageList.result.map((vacation) => {
        return {
          ...vacation,
          formattedPermissionEndDate: moment(vacation.permissionEndDate).format(
            "DD/MM/YYYY"
          ),
          formattedPermissionInicialDate: moment(
            vacation.permissionInicialDate
          ).format("DD/MM/YYYY"),
          formattedLimit: moment(vacation.limit).format("DD/MM/YYYY"),
        };
      });

      this.pageCount = Math.ceil(vacationPageList.count / this.itemsPerPage);
    }
  }
  get listVacation() {
    return this.listVacationsDetails;
  }
}
