




































import { Component, Prop } from "vue-property-decorator";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views/index";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import { IwhatsappConfig } from "../../types/WhatsappConfig";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
@Component({
  components: {
    InputPopup,
  },
})
export default class WhatsappConfigInput extends Request {
  @Prop() entity: IwhatsappConfig;
  @Prop() list: IwhatsappConfig[];
  @Prop() companyProductPlanId: number;
  header = `${translate("whatsappConfig")}`;
  phoneNumber: string = "";
  active: boolean = true;
  isVerified: boolean = false;
  officialApi: boolean = false;

  async onSave(): Promise<any> {
    if (this.entity) {
      this.entity.phoneNumber = this.phoneNumber;
      this.entity.isVerified = this.isVerified;
      this.entity.officialApi = this.officialApi;
      this.entity.companyProductPlanId = this.companyProductPlanId;
      return await this.save(this.paths.whatsappConfigPath, this.entity);
    }
  }
  mounted() {
    this.phoneNumber = this.entity.phoneNumber;
    this.isVerified = this.entity.isVerified;
    this.officialApi = this.entity.officialApi;
    this.companyProductPlanId = this.entity.companyProductPlanId;
  }

  itemsForm: InputModel[] = [
    {
      id: 1,
      col: 6,
      slot: "phone",
    },

    {
      id: 2,
      col: 3,
      slot: "isVerified",
    },
    {
      id: 3,
      col: 3,
      slot: "officialApi",
    },
  ];
}
