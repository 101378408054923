




























import {
  Customizer,
  Header,
  Sidebar,
  Footer,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import SideBarItems from "../../utils/SidebarItems";
import { Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import env from "../../services/env";
import { io } from "socket.io-client";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import { TranslateResult } from "vue-i18n";
@Component({
  components: {
    Header,
    Sidebar,
    Footer,
    Customizer,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
      getSecret: "auth/getSecret",
    }),
  },
})
export default class Layout extends Request {
  companyName: string | TranslateResult = translate("matrixGo");
  pathSaveAccount: string = "";
  pathChangePassword: string = "";
  SideBarItems = SideBarItems;
  expandOnHover = false;
  isOpen = false;
  logoutPah = "/auth/login";
  socketUrl = `${env.protocol}${env.socket}`;
  socket = io(this.socketUrl, {
    transports: ["websocket", "polling"],
    reconnection: true,
  });

  logoIcon = require("../../assets/logos-new/LOGO 3.svg");
  getUser: any;
  entity: any = [];
  notification = {};
  profile: any = {};
  getSecret: any;
  typeLogo: string = "";

  logoLight = require("../../assets/logos-new/LOGO 11.png");

  logoDark = require("../../assets/logos-new/LOGO 1.png");

  async created() {
    this.companyName = translate("matrixGo");

    const user = this.getUser();
    const secret = this.getSecret();
    this.isOpen = secret;
    let result;

    this.entity = user;

    if (this.entity.userProfiles && this.entity.userProfiles[0].id) {
      const filteredProfiles = this.entity.userProfiles.filter(
        (profile) => profile.productId === 2
      );

      if (filteredProfiles.length > 0) {
        this.profile = await this.get(
          this.paths.profilePath + filteredProfiles[0].profileId
        );
      }
    }

    if (!this.entity.profile) {
      this.entity.profile = this.profile;
    }

    this.socket.on("connect", () => {
      this.socket.on(`notification-${user.id}`, () => {
        this.notificationView();
      });
    });

    result = await this.get(
      this.paths.notificationMaintenanceCheck + `${user.id}` + "?skip=1&take=3"
    );

    this.notification = result;
  }

  async handleSeeAllNotification() {
    const user = this.getUser();

    await this.patch(this.paths.notificationCheckAllPath + user.id, {
      isChecked: true,
    });

    this.notificationView();

    this.$router.push({
      name: "maintenanceNotificationList",
    });
  }

  async handleSeeNotificationOne(id: number) {
    await this.patch(this.paths.notificationMaintenance + id, {
      isChecked: true,
    });

    this.notificationView();

    this.$router.push({
      name: "maintenanceNotificationList",
      query: { item: id.toString() },
    });
  }

  async notificationView() {
    const user = this.getUser();
    let result;

    result = await this.get(
      this.paths.notificationMaintenanceCheck + `${user.id}` + "?skip=1&take=3"
    );

    this.notification = result;
  }

  beforeMount() {
    if (
      !localStorage.getItem("themeColor") &&
      !localStorage.getItem("sideBarColor") &&
      !localStorage.getItem("navBarColor")
    ) {
      localStorage.setItem("themeColor", "#802678");
      localStorage.setItem("sideBarColor", "#121212");

      localStorage.setItem("navBarColor", "#802678");
    }
    localStorage.setItem("logo-dark", this.logoDark);
    localStorage.setItem("logo-light", this.logoLight);
  }

  mounted() {
    this.pathSaveAccount = this.paths.userPath;
    this.pathChangePassword = this.paths.pathChangePassword;
  }
}
