





































































import { Component, Prop } from "vue-property-decorator";
import {
  InputModel,
  SelectModel,
} from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import { IProfile } from "../../types/Profile/IProfile";
import Request from "../../services/request";
import ProfileActions from "./ProfileActions.vue";
import { TProfileFunctionalityAction } from "../../types/Profile/TProfileFunctionalityAction";
import { TAction } from "../../types/Profile/TAction";
import { translate } from "../../plugins/i18n/index";
import { IGetUser } from "../../types/IGetUser";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { mapGetters } from "vuex";
@Component({
  components: {
    ProfileActions,
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ProfileInput extends Request {
  @Prop() readonly productList: SelectModel[];
  @Prop() readonly actionList: TAction[];
  @Prop() readonly entity: IProfile;
  @Prop() readonly functionality: TProfileFunctionalityAction[] ;
  @Prop() readonly list: IProfile[];
  @Prop() profileId: number;
  @Prop() companyId: number;
  @Prop() loadData: ()=>void;


  active: boolean = true;
  dialog: boolean = null;
  name: string = "";
  nameProfile: string = "";
  selected: any = [];
  header = `${translate("title.addProfile")}`;
  functionalityList: TProfileFunctionalityAction[] = [];
  selectedProduct: number | null = null;
  filterFuncionalities: TProfileFunctionalityAction[] = [];
  editorUserId: number = null;
  getUser: any;
  user: IGetUser ;
  selectedActions(value) {
    const objs = value.map(function (x) {
      return {
        actionId: x[1],
        functionalityId: x[0],
      };
    });
    this.filterFuncionalities = objs;
  }

  async chargerFunctionalities() {
    const result = await this.get(this.paths.functionalityByProductPath + this.selectedProduct);

    this.functionalityList = result;
  }

  async onSave(): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;
    this.dialog = !this.dialog;
    let result;

    this.entity.companyId = this.companyId;
    this.entity.productId = this.selectedProduct;
    this.entity.profileFunctionalityActions = this.filterFuncionalities;
    this.entity.active = this.active;
    this.entity.name = this.nameProfile;
    this.entity.profileId = this.entity.id;
    if (this.entity.id) {
      result = await this.put(this.paths.profileUpdatePath, {
        ...this.entity,
        editorUserId: this.editorUserId,
      });
    } else {
      result = await this.save(this.paths.profileSavePath, {
        ...this.entity,
        editorUserId: this.editorUserId,
      });
    }

    if (!this.entity.id) {
      this.selectedProduct = null;
      this.nameProfile = "";
      this.filterFuncionalities = [];
      this.functionalityList = [];
      this.selected = [];
      this.selectedProduct = null;
    }
    this.dialog = !this.dialog;
    this.loadData();
    return result;
  }
  async saveAndClear() {
    const result = await this.onSave();
    this.dialog = !this.dialog;
    if (!this.entity.id) {
      this.selectedProduct = null;
      this.nameProfile = "";
      this.filterFuncionalities = [];
      this.functionalityList = [];
      this.selected = [];
      this.selectedProduct = null;
    }

    return result;
  }
  async mounted(): Promise<void> {
    this.selectedProduct = this.entity.productId;
    this.nameProfile =  this.entity.name 
    if (this.selectedProduct) {

      this.functionalityList = this.functionality;
    }
  }
  cancelDialog() {
    this.dialog = !this.dialog;
    if (!this.entity.id) {
      this.selectedProduct = null;
      this.nameProfile = "";
      this.filterFuncionalities = [];
      this.functionalityList = [];
      this.selected = [];
      this.selectedProduct = null;
    }
  }

  itemsForm: InputModel[] = [
    {
      id: 1,
      col: 6,
      slot: "name",
      required: true,
    },
    {
      id: 2,
      col: 4,
      slot: "product",
      required: false,
    },
    {
      id: 3,
      col: 2,
      slot: "active",
    },
    {
      id: 4,
      col: 12,
      slot: "profileActions",
      required: true,
    },
  ];
}
