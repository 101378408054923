

























import { Component } from "vue-property-decorator";
import Request, { setTokenHeader } from "../../services/request";
import { paths } from "../../services/apiPaths";
import { translate } from "../../plugins/i18n";
import LoginV2 from "@matrixbrasil/matrix-lib-auth-frontend/src/components/loginv2/Loginv2.vue";
import { mapActions } from "vuex";

@Component({
  components: { LoginV2 },
  methods: {
    ...mapActions({
      setPermissions: "auth/setPermissions",
      setLogin: "auth/setLogin",
      setUser: "auth/setUser",
      setUserId: "auth/setUserId",
      setSecret: "auth/setSecret",
    }),
  },
})
export default class FullLogin extends Request {
  setPermissions: any;
  setLogin: any;
  setUser: any;
  setUserId: any;
  user: any;
  setSecret: any;
  forgotPasswordText: string = `${translate("forgotPassword")}`;
  emailText: string = `${translate("email")}`;
  passwordLabelText: string = `${translate("password")}`;
  signInButtonText: string = `${translate("button.enter")}`;
  rememberMeText: string = `${translate("rememberMe")}`;
  path: string = "";
  scope: string = "MKP";
  grant_type: string = "password";
  images = [
    {
      src: require("@/assets/backgroundRed.jpg"),
    },
  ];
  productId: number = null;
  checkbox: boolean = false;
  client_id: string = "";
  password: string = "";
  client_secret: string = "";
  show1: boolean = false;
  passwordRules = [
    (v: string) => !!v || `${translate("passwordIsRequired")}`,
    (v: string) => (v && v.length <= 20) || `${translate("passwordSize")}`,
  ];
  emailRules = [
    (v: string) => !!v || `${translate("emailIsRequired")}`,
    (v: string) => /.+@.+\..+/.test(v) || `${translate("validEmail")}`,
  ];
  getClientId(value) {
    this.client_id = value;
  }
  getPassword(value) {
    this.password = value;
  }
  rememberUser() {
    if (this.checkbox == true) {
      localStorage.client_id = this.client_id;
      localStorage.password = this.password;
    } else {
      localStorage.client_id = "";
      localStorage.password = "";
    }
  }
  async signIn() {
    const newClientId = this.client_id.split(/\s/).join("");
    try {
      return await this.post(this.path, {
        client_id: newClientId,
        password: this.password,
        scope: this.scope,
        grant_type: this.grant_type,
        client_secret: this.password,
        username: newClientId,
      }).then(async (data) => {
        setTokenHeader(data.access_token);
        this.setUser(data.user);
        this.setSecret(data.isSecretTemporary);
        this.setLogin({ user: data.user, token: data.access_token });
        const result = await this.get(
          paths.productGetOnePath + `?scope=${data.scope}`
        );
        this.productId = result.id;
        await this.get(
          paths.rolePath + data.user.id + `?productId=${this.productId}`
        ).then((data) => {
          localStorage.setItem("role", data.user.id);
          localStorage.setItem("client_id", newClientId);
          localStorage.setItem("password", this.password);
          this.setPermissions(data.permissions);
          this.$router.push("/");
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  async mounted() {
    this.path = paths.loginPath;
    if (localStorage.client_id) {
      this.client_id = localStorage.client_id;
    }
    if (localStorage.password) {
      this.password = localStorage.password;
    }

    if (localStorage.client_id && localStorage.password) {
      this.checkbox = true;
    }
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });

    localStorage.clear();

    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
}
