






















































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { IInfrastructure } from "../../types/Infrastructure/IInfrastructure";
import { IInfraDetails } from "../../types/Infrastructure/IInfraDetails";
import { THost } from "../../types/Infrastructure/THost";
import { TGroupHost } from "../../types/Infrastructure/TGroupHost";
import { TSelect } from "../../types/Infrastructure/TSelect";
import { Breadcrumb } from "../../types/Breadcrumb";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Options } from "../../types/Options";
import { HeaderItem } from "../../types/HeaderItem";
import { translate } from "../../plugins/i18n/index";
import {
  BaseBreadcrumb,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";

@Component({
  components: {
    BaseBreadcrumb,
    DatePicker,
    PageHeader,
  },
})
export default class InfrastructureList extends Request {
  title: string = `${translate("infra")}`;
  list: IInfrastructure[] = [];
  searchGroup: string = "";
  searchHost: string = "";
  entriesGroup: TGroupHost[] = [];
  entriesHost: THost[] = [];
  dynamicHeader: HeaderItem[] = [];
  selectGroup: TGroupHost = {};
  selectHost: THost = {};
  menuStartDate: boolean = false;
  menuEndDate: boolean = false;
  originalEndDate: string = "";
  originalStartDate: string = "";
  listAlertDetails: IInfraDetails = {};
  selectList: number[] | number = [];
  selectSeverityList: number = null;
  selectAcknowledgedList: string = "";
  expanded = [];
  selectSeach: TSelect[] = [
    {
      id: 1,
      name: `${translate("alltiny")}`,
    },
    {
      id: 2,
      name: `${translate("group")}`,
    },
    {
      id: 3,
      name: "Host",
    },
  ];

  selectAcknowledged: TSelect[] = [
    {
      id: 3,
      name: `${translate("alltiny")}`,
      value: "",
    },
    {
      id: 1,
      name: `${translate("yes")}`,
      value: "1",
    },
    {
      id: 2,
      name: `${translate("no")}`,
      value: "0",
    },
  ];
  selectSeverity: TSelect[] = [
    {
      id: 6,
      name: `${translate("alltiny")}`,
    },
    {
      id: 5,
      name: `${translate("disaster")}`,
    },
    {
      id: 4,
      name: `${translate("high")}`,
    },
    {
      id: 3,
      name: `${translate("average")}`,
    },
    {
      id: 2,
      name: `${translate("attention")}`,
    },
    {
      id: 1,
      name: `${translate("information")}`,
    },

    {
      id: 0,
      name: `${translate("notClassified")}`,
    },
  ];
  startDate: string = null;
  endDate: string = null;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("infra")}`,
      disabled: true,
    },
  ];
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  };
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page: number = 1;
  pageCount: number = 0;
  itemsPerPage: number = 10;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-lan-pending",
    titlePage: `${translate("infra")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    crudDetail: "false",
  };

  headerAlert: HeaderItem[] = [
    {
      text: `${translate("recognitionTotal")}`,
      align: "center",
      value: "timeToAcknowledged",
    },
  ];

  async seach(item: boolean) {
    if (item == true) {
      this.page = 1;
    }

    let result;

    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";
    const severity =
      this.selectSeverityList === 6 ? "" : this.selectSeverityList;
    if (!sortBy) {
      if (this.selectList === 2) {
        this.selectHost = null;

        result = await this.get(
          this.paths.zabbixAlertGroupPath +
            `?&take=${this.itemsPerPage}&skip=${
              this.page
            }&orderByParam=${"id"}&orderOrientation=${orientation}` +
            `&startDate=${this.originalStartDate}` +
            `&endDate=${this.originalEndDate}` +
            `&groupId=${this.selectGroup.id}` +
            `&severity=${severity}` +
            `&acknowledged=${this.selectAcknowledgedList}`
        );
      } else if (this.selectList === 3) {
        this.selectGroup = null;
        result = await this.get(
          this.paths.zabbixAlertGroupPath +
            `?&take=${this.itemsPerPage}&skip=${
              this.page
            }&orderByParam=${"id"}&orderOrientation=${orientation}` +
            `&startDate=${this.originalStartDate}` +
            `&endDate=${this.originalEndDate}` +
            `&hostId=${this.selectHost.id}` +
            `&severity=${severity}` +
            `&acknowledged=${this.selectAcknowledgedList}`
        );
      } else if (this.selectList === 1) {
        this.selectList = 1;
        this.selectGroup = null;
        this.selectHost = null;
        result = await this.get(
          this.paths.zabbixAlertGroupPath +
            `?&take=${this.itemsPerPage}&skip=${
              this.page
            }&orderByParam=${"id"}&orderOrientation=${orientation}` +
            `&startDate=${this.originalStartDate}` +
            `&endDate=${this.originalEndDate}` +
            `&severity=${severity}` +
            `&acknowledged=${this.selectAcknowledgedList}`
        );
      }
    } else {
      if (this.selectList === 2) {
        this.selectHost = null;
        result = await this.get(
          this.paths.zabbixAlertGroupPath +
            `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}` +
            `&startDate=${this.originalStartDate}` +
            `&endDate=${this.originalEndDate}` +
            `&groupId=${this.selectGroup.id}` +
            `&severity=${severity}` +
            `&acknowledged=${this.selectAcknowledgedList}`
        );
      } else if (this.selectList === 3) {
        this.selectGroup = null;

        result = await this.get(
          this.paths.zabbixAlertGroupPath +
            `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}` +
            `&startDate=${this.originalStartDate}` +
            `&endDate=${this.originalEndDate}` +
            `&hostId=${this.selectHost.id}` +
            `&severity=${severity}` +
            `&acknowledged=${this.selectAcknowledgedList}`
        );
      } else if (this.selectList === 1) {
        this.selectList = 1;
        this.selectGroup = null;
        this.selectHost = null;
        result = await this.get(
          this.paths.zabbixAlertGroupPath +
            `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}` +
            `&startDate=${this.originalStartDate}` +
            `&endDate=${this.originalEndDate}` +
            `&severity=${severity}` +
            `&acknowledged=${this.selectAcknowledgedList}`
        );
      }
    }
    if (result && result?.result) {
      this.list = result.result.map((alert) => ({
        ...alert,
        clock: moment.utc(alert.clock).format("DD/MM/YYYY HH:mm"),
        formattedDateAcknowledgedAt: alert?.acknowledgedAt
          ? moment.utc(alert.acknowledgedAt).format("DD/MM/YYYY HH:mm")
          : "--",
      }));
    }

    this.pageCount = Math.ceil(result.count / this.itemsPerPage);

    this.dynamicHeader = [
      {
        text: `${translate("name")}`,
        align: "start",
        value: "name",
      },
      {
        text: `${translate("severity")}`,
        align: "center",
        value: "severity",
      },
      {
        text: `${translate("ticketCreatedAt")}`,
        align: "center",
        value: "clock",
      },
      {
        text: `${translate("acknowledged")}`,
        align: "center",
        value: "acknowledged",
      },
      {
        text: `${translate("acknowledgedAt")}`,
        align: "center",
        value: "formattedDateAcknowledgedAt",
      },

      { text: "", value: "data-table-expand" },
    ];
  }

  async loadDetails({ item }): Promise<void> {
    const resultMetrics = await this.get(
      this.paths.zabbixMetricsAlertPath + item.id
    );

    if (resultMetrics.message) {
      this.listAlertDetails = {
        ...resultMetrics,
        timeToAcknowledged: this.$t("notRecognized"),
      };
    } else {
      let formattedTime;
      const duration = moment.duration(resultMetrics.timeToAcknowledged);
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      switch (true) {
        case days === 0 && hours > 0:
          formattedTime = `${hours} ${translate(
            "hour"
          )}(s)   ${minutes} ${translate("minute")}(s)   ${seconds} ${translate(
            "second"
          )}(s)`;
          break;

        case days === 0 && hours === 0 && minutes > 0:
          formattedTime = `${minutes} ${translate(
            "minute"
          )}(s)   ${seconds} ${translate("second")}(s)`;
          break;

        case days === 0 && hours === 0 && minutes === 0 && seconds > 0:
          formattedTime = ` ${seconds} ${translate("second")}(s)`;
          break;

        default:
          formattedTime = `${days} ${translate("day")}(s)  ${hours} ${translate(
            "hour"
          )}(s)   ${minutes} ${translate("minute")}(s)   ${seconds} ${translate(
            "second"
          )}(s)`;
          break;
      }

      this.listAlertDetails = {
        ...resultMetrics,
        timeToAcknowledged: formattedTime,
      };
    }
  }

  updateOptions(newOptions: Options) {
    this.options = { ...this.options, ...newOptions };

    if (this.startDate && this.endDate) {
      this.page = 1;
      const isFormattedDateSelected = this.options?.sortBy?.includes(
        "formattedDateAcknowledgedAt"
      );

      if (isFormattedDateSelected) {
        this.options.sortBy = ["acknowledgedAt"];
      }

      this.seach(true);
    }
  }
  async mounted() {
    this.selectList = 1;
    this.selectSeverityList = 6;
    this.selectAcknowledgedList = "";

    const groupList = await this.get(
      this.paths.zabbixGroupPath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    this.entriesGroup = groupList[0];

    const hostList = await this.get(
      this.paths.zabbixHostPath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    this.entriesHost = hostList[0];

    this.page = 1;
  }

  @Watch("searchGroup")
  async searchGroups(val) {
    if (val && val.length >= 3) {
      const groups = await this.get(
        this.paths.zabbixGroupPath +
          `?take=${this.itemsPerPage}&skip=${1}&search=${val}`
      );
      return (this.entriesGroup = groups[0]);
    }
    if (val && val.length === 0) {
      const groups = await this.get(
        this.paths.zabbixGroupPath +
          `?take=${this.itemsPerPage}&skip=${1}&search=${val}`
      );
      return (this.entriesGroup = groups[0]);
    }
  }
  @Watch("searchHost")
  async searchHosts(val) {
    if (val && val.length >= 3) {
      const hosts = await this.get(
        this.paths.zabbixHostPath +
          `?take=${this.itemsPerPage}&skip=${1}&search=${val}`
      );
      return (this.entriesHost = hosts[0]);
    }
    if (val && val.length === 0) {
      const hosts = await this.get(
        this.paths.zabbixHostPath +
          `?take=${this.itemsPerPage}&skip=${1}&search=${val}`
      );
      return (this.entriesHost = hosts[0]);
    }
  }
  async handleClearGroup() {
    const groups = await this.get(
      this.paths.zabbixGroupPath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    return (this.entriesGroup = groups[0]);
  }
  async handleClearHost() {
    const hosts = await this.get(
      this.paths.zabbixHostPath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    this.entriesHost = hosts[0];
  }

  @Watch("originalStartDate")
  formatStartDate(value) {
    this.startDate = moment(value).format("DD/MM/YYYY");
  }
  @Watch("originalEndDate")
  formatEndDate(value) {
    this.endDate = moment(value).format("DD/MM/YYYY");
  }

  translateSeverity(severity) {
    switch (severity) {
      case 0:
        return this.$t("notClassified");
      case 1:
        return this.$t("information");
      case 2:
        return this.$t("attention");
      case 3:
        return this.$t("average");
      case 4:
        return this.$t("high");
      case 5:
        return this.$t("disaster");
      default:
        return severity;
    }
  }
  getSeverityStyle(severity) {
    let color;
    switch (severity) {
      case 0:
        color = "#808080";
        break;
      case 1:
        color = "";
        break;
      case 2:
        color = "#BDB76B";
        break;
      case 3:
        color = "#FF8C00";
        break;
      case 4:
        color = "#A52A2A";
        break;
      case 5:
        color = "#FF6347";
        break;
      default:
        color = "";
    }

    return { color };
  }
  getAcknowledgedClass(acknowledged) {
    let color;
    switch (acknowledged) {
      case false:
        color = "#FF6347";
        break;
      case true:
        color = "#4CAF50";
        break;

      default:
        color = "#C0C0C0";
        break;
    }
    return { color };
  }
  getAcknowledgedTranslation(acknowledged) {
    switch (acknowledged) {
      case true:
        return this.$t("yes");
      case false:
        return this.$t("no");

      default:
        return acknowledged;
    }
  }

  getSourceTranslation(source) {
    switch (source) {
      case 0:
        return this.$t("eventTigger");
      case 1:
        return this.$t("eventRule");
      case 2:
        return this.$t("eventAutoregistration");
      case 3:
        return this.$t("internalEvent");
      case 4:
        return this.$t("eventUpdate");

      default:
        return source;
    }
  }

  getStatusClass(status) {
    let color;
    switch (status) {
      case 1:
        color = "#F90000";
        break;
      case 0:
        color = "#4CAF50";
        break;

      default:
        color = "#C0C0C0";
        break;
    }
    return color;
  }
  getStatusTranslation(status) {
    switch (status) {
      case 1:
        return this.$t("inactive");
      case 0:
        return this.$t("active");

      default:
        return status;
    }
  }

  get groupList() {
    return this.entriesGroup;
  }
  get hostList() {
    return this.entriesHost;
  }
  get validDateInverted() {
    let dateStart = moment.tz(
      this.startDate,
      "DD/MM/YYYY",
      "America/Sao_Paulo"
    );
    let dateEnd = moment.tz(this.endDate, "DD/MM/YYYY", "America/Sao_Paulo");

    return dateStart.isAfter(dateEnd);
  }
  get header() {
    return this.dynamicHeader;
  }

  get missingStartDate() {
    return !this.startDate;
  }

  get missingSelectAcknowledged() {
    return typeof this.selectAcknowledgedList !== "string";
  }
  get missingSelectSeverity() {
    return typeof this.selectSeverityList !== "number";
  }

  get missingHost() {
    return !this.selectHost;
  }

  get missingGroup() {
    return !this.selectGroup;
  }
  get missingEndDate() {
    return !this.endDate;
  }
  get saveDisabled() {
    if (this.selectList === 1) {
      return (
        this.missingEndDate ||
        this.missingStartDate ||
        this.validDateInverted ||
        this.missingSelectSeverity ||
        this.missingSelectAcknowledged
      );
    } else if (this.selectList === 2) {
      return (
        this.missingEndDate ||
        this.missingStartDate ||
        this.validDateInverted ||
        this.missingGroup ||
        this.missingSelectSeverity ||
        this.missingSelectAcknowledged
      );
    } else {
      return (
        this.missingEndDate ||
        this.missingStartDate ||
        this.validDateInverted ||
        this.missingHost ||
        this.missingSelectSeverity ||
        this.missingSelectAcknowledged
      );
    }
  }
}
