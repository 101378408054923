var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":_vm.buttonIcons.color,"title":_vm.buttonIcons.title}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.buttonIcons.icon))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(!_vm.entity.isBlocked ? _vm.$t("block") : _vm.$t("unblock"))+" "),_c('v-spacer')],1),_c('v-card',[_c('v-card-text',[_c('v-row',[(!_vm.entity.isBlocked)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.blockList,"label":_vm.$t('reasonToBlock'),"outlined":"","item-text":"reason","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reason)+" ")]}}],null,false,608973413),model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.unblockList,"label":_vm.$t('reasonToUnblock'),"outlined":"","item-text":"reason","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reason)+" ")]}}]),model:{value:(_vm.unblock),callback:function ($$v) {_vm.unblock=$$v},expression:"unblock"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('observation')},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"black","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info","disabled":_vm.saveDisabled},on:{"click":_vm.blockUnblock}},[_vm._v(" "+_vm._s(_vm.$t("button.confirm"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }