import Vue from "vue";
import App from "@/App.vue";
import vuetify from "./plugins/vuetify";
import router from "./services/router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css"; 
import "@/plugins/base";
import Rules from "@matrixbrasil/matrix-lib-utils-frontend/src/utils/Rules";

import { i18n } from "./plugins/i18n/index"; 
import VueQuillEditor from "vue-quill-editor";
import { hasPermission } from "./utils/hasPermission";
import { VueMaskDirective } from "v-mask";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "vue-datetime/dist/vue-datetime.css";   
import VueApexCharts from 'vue-apexcharts';
Vue.component('apexchart', VueApexCharts);
 
Vue.use(VueQuillEditor);
Vue.config.productionTip = false;
Vue.prototype.$rules = Rules;
Vue.prototype.$vuetify = vuetify;
Vue.directive("mask", VueMaskDirective);
Vue.directive("has-permission", {
  inserted: function (el, binding, vnode) {
    if (binding === null) {
      el;
    }
    if (vnode.elm && !hasPermission(binding.value)) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  },
});
if (
  !localStorage.getItem("themeColor") &&
  !localStorage.getItem("sideBarColor") &&
  !localStorage.getItem("navBarColor") 
) {
  localStorage.setItem("themeColor", "#802678");
  localStorage.setItem("sideBarColor", "#121212");

  localStorage.setItem("navBarColor", "#802678");
}
new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export const eventBus = new Vue();
