




































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import FunctionalityInput from "./FunctionalityInput.vue";
import Request from "../../services/request";
import { Action, Functionality } from "../../types/Functionality";
import { IProduct } from "src/types/Product/IProduct";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Options } from "../../types/Options";
import { page } from "../../types/Page";
import { translate } from "../../plugins/i18n/index";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    FunctionalityInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class FunctionalityList extends Request {
  productId = this.$route.query.productId;
  pathDelete:string = "";
  entity = this.$route.query.item as IProduct;
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  pag: number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };
  page:page = {
    title: `${translate("functionality")}`,
    icon: null,
  };
  actions: Action[] = [];
  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("product")}`,
      disabled: false,
      to: "/product/",
    },
    {
      text: `${translate("functionality")}`,
      disabled: true,
    },
  ];

  tagStatus = "status";

  header:HeaderItem[] = [
    { text: `${translate("name")}`, value: "name" },
    { text: `${translate("code")}`, value: "code" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-shape",
    titlePage: `${translate("functionality")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: Functionality[] = [];

  // async mounted(): Promise<void> {
  //   await this.loadData();
  // }

  async loadData(): Promise<void> {


    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";
    let result;

    const actionsList = await this.get(this.paths.actionPath);
    this.actions = actionsList[0];
    this.pathDelete = this.paths.functionalityPath;

    if (!sortBy) {
      result = await this.get(
        this.paths.functionalityProductCompanyPage +
          this.productId +
          `?&take=${this.itemsPerPage}&skip=${
            this.pag
          }&orderByParam=${"id"}&orderOrientation=${orientation}`
      );
    } else {
      result = await this.get(
        this.paths.functionalityProductCompanyPage +
          this.productId +
          `?&take=${this.itemsPerPage}&skip=${this.pag}&orderByParam=${sortBy}&orderOrientation=${orientation}`
      );
    }

    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
    this.list = result.result;
  }

  updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }
}
