






















































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import DepartmentInput from "./DepartmentInput.vue";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { Options } from "../../types/Options";
import moment from "moment";
import {
  BaseBreadcrumb,
  ConfirmDelete,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { mapGetters } from "vuex";
import { itemsPerPage } from "../../types/itemsPerPage";
import UsersDepartmentInput from "./UsersDepartmentInput.vue";
import { HeaderItem } from "../../types/HeaderItem";
import { TUserlist } from "../../types/Vacation/TUserlist";
import { IGetUser } from "../../types/IGetUser";
import { Department } from "../../types/Departament/Department";
import { Breadcrumb } from "../../types/Breadcrumb";
import ConfirmDeleteDepartment from "../../components/ConfirmDeleteDepartment.vue";

@Component({
  components: {
    DepartmentInput,
    UsersDepartmentInput,
    List,
    ConfirmDeleteDepartment,
    ConfirmDelete,
    BaseBreadcrumb,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class DepartmentList extends Request {
  deletePath: string = "";
  getUser: any;
  user:IGetUser;
  isCharged:boolean = false;
  userList: TUserlist[]=[];
  search: string = "";
  itemsPerPageList:itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };
  page:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  companyId:number = null
  title = `${translate("sector")}`;

  breadcrumbs:Breadcrumb[] = [
    {
      text: `${translate("sector")}`,
      disabled: false,
      to: "/department/",
    },
  ];
  customColumns = [
    {
      slotName: "active",
      itemValue: "active",
    },
  ];
  header:HeaderItem[] = [
    { text: `${translate("name")}`, value: "name" },

    {
      text: `${translate("createdAt")}`,
      align: "center",
      value: "formatcreatedAt",
    },
    { text: `${translate("status")}`, align: "center", value: "active" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-sitemap",
    titlePage: `${translate("sector")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list:Department [] = [];
  async loadData(): Promise<void> {
    const user = this.getUser();

    this.companyId = user.companyId.id;
    this.deletePath = this.paths.departmentDeletePath + "/";

    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";
    let result;

    if (!sortBy) {
      result = await this.get(
        this.paths.departmentPagePathList +
          this.companyId +
          `?&take=${this.itemsPerPage}&skip=${
            this.page
          }&orderByParam=${"id"}&orderOrientation=${orientation}`
      );
    } else {
      result = await this.get(
        this.paths.departmentPagePathList +
          this.companyId +
          `?&take=${this.itemsPerPage}&skip=${this.page}&orderByParam=${sortBy}&orderOrientation=${orientation}`
      );
    }
    if (result.result) {
      this.list = result.result.map((department) => ({
        ...department,
        formatcreatedAt: moment(department.createdAt).format("DD/MM/YYYY"),
      }));
    }
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);

    const userResult = await this.get(
      this.paths.userByCompanylistPath + "/" + `${this.companyId}`
    );

    const fullNames = userResult.map((user) => {
      return {
        id: user.id,
        fullNames: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        active: user.active,
      };
    });
    this.userList = fullNames;

    this.isCharged = true;
  }

  updateOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }
}
