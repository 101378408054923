



















































import { Component } from "vue-property-decorator";
import Request from "../../../services/request";

@Component
export default class Dashboard extends Request {
  itemsHeppy: any[] = [];
  itemsOmni: any[] = [];
  items: any[] = [];

  async mounted() {
    const result = await this.get(this.paths.dashboardCountProductsPath);

    this.items = result;
  }
}
