


















import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
@Component
export default class DirectAuthentication extends Request {
  @Prop() companyProductPlanId: number;

  async auth() {
    const result = await this.get(
      this.paths.companyProductConfigListPath + this.companyProductPlanId
    );
    if (result.rootUser && result.rootPassword && result.domain) {
      (document.getElementById("login") as HTMLInputElement).value =
        result.rootUser;
      (document.getElementById("password") as HTMLInputElement).value =
        result.rootPassword;

      const form = this.$refs.url as HTMLFormElement;

      form.action = `${result.domain}/login/autenticar`;

      form.target = "_blank";

      const button = document.getElementById(
        "submit-button"
      ) as HTMLElement | null;

      if (button != null) {
        button.click();
      }
    }
  }
}
