






















































































import MatrixReport from "@matrixbrasil/matrix-lib-report/src/views/MatrixReport.vue";
import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import VueApexCharts from "vue-apexcharts";
@Component({
  components: {
    VueApexCharts,
    MatrixReport,
  },
})
export default class AnaliticalReportfrom extends Request {
  @Prop() attendencyReport: any;
  @Prop() conversationReportItems: any;
  @Prop() readonly conversationResultNewItems: any;
  seriesAtendencyAnalitical = [];
  itemsDownload = [];
  any = null;
  colors = this.getNavBarColor();
  headerAtendencyAnalitical = [
    {
      text: `${translate("day")}`,
      value: "requiredDate",
    },
    {
      text: `${translate("amount")}`,
      value: "amount",
    },
  ];
  headerConversationAnalitical = [
    {
      text: `${translate("day")}`,
      value: "requiredDate",
    },
    {
      text: `${translate("directionType")}`,
      value: "nameDirection",
    },
    {
      text: `${translate("sectionType")}`,
      value: "nameType",
    },
    {
      text: `${translate("amount")}`,
      value: "amount",
    },
  ];

  customColumns = [
    {
      slotName: "nameType",
      itemValue: "nameType",
    },
    {
      slotName: "nameDirection",
      itemValue: "nameDirection",
    },
  ];

  path: string = "";
  resolveNameTipe(nameType) {
    switch (nameType) {
      case "FREE_TIER":
        return `${translate("freeTier")}`;
      case "REGULAR":
        return `${translate("regular")}`;
      case "FREE_ENTRY_POINT":
        return `${translate("FREE_ENTRY_POINT")}`;
    }
  }
  getchartOptionsConversationAnalitical() {
    return {
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        toolbar: {
          show: true,
        },
      },
      theme: {
        mode: localStorage.getItem("themeColor"),
      },
      tooltip: {
        theme: localStorage.getItem("themeColor"),
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          columnWidth: "30%",
          horizontal: false,
        },
      },
      xaxis: {
        categories: this.conversationReportItems
          .filter(
            (item) =>
              item.nameDirection === "USER_INITIATED" &&
              item.nameType == "REGULAR"
          )
          .map((item) => item.requiredDate)
          .sort(this.sortByDate),
        labels: {
          style: {
            colors: this.colors,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: this.colors,
          },
        },
      },
    };
  }

  getchartOptionsAttendencyAnalitical() {
    return {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      theme: {
        mode: localStorage.getItem("themeColor"),
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      tooltip: {
        theme: localStorage.getItem("themeColor"),
      },
      grid: {
        row: {
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: this.conversationReportItems.map((item) => {
          return item.requiredDate;
        }),
        labels: {
          style: {
            colors: this.colors,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: this.colors,
          },
        },
      },
      fill: {
        opacity: 1,
      },
    };
  }

  downloadCsvConversation() {
    this.conversationReportItems.map((item) => {
      item.nameDirection = `${translate(item.nameDirection)}`;
      item.nameType = `${translate(item.nameType)}`;
    });
    this.conversationReportItems.sort((a, b) =>
      a.requiredDate > b.requiredDate ? 1 : -1
    );

    return this.conversationReportItems;
  }
  downloadCsvAttendency() {
    return this.attendencyReport;
  }
  onSort(isDesc, value) {
    if (isDesc == true) {
      let isDescResul = isDesc;
      let index = value[0];
      index;
      isDescResul;
    } else {
      let isDescResul = isDesc;
      let index = value[0];
      index;
      isDescResul;
    }
  }
  getConversationSeries() {
    // console.log(
    //   this.conversationReportItems
    //     .filter(
    //       (item) =>
    //         item.nameDirection === "BUSINESS_INITIATED" &&
    //         item.nameType == "FREE_TIER"
    //     )
    //     .map((item) => item.amount)
    //     .sort(this.sortByDate)
    // );

    if (
      this.conversationResultNewItems.length > 0 &&
      (this.conversationResultNewItems[0].nameDirection === "SERVICE" ||
        this.conversationResultNewItems[0].nameDirection === "UTILITY" ||
        this.conversationResultNewItems[0].nameDirection === "MARKETING" ||
        this.conversationResultNewItems[0].nameDirection === "AUTHENTICATION")
    ) {
      return [
        {
          name: "SERVICE",
          data: this.conversationResultNewItems
            .filter(
              (item) =>
                item.nameDirection === "SERVICE" && item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },

        {
          name: "SERVICE",
          data: this.conversationResultNewItems
            .filter(
              (item) =>
                item.nameDirection === "SERVICE" &&
                item.nameType == "FREE_ENTRY_POINT"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },

        {
          name: "SERVICE",
          data: this.conversationResultNewItems
            .filter(
              (item) =>
                item.nameDirection === "SERVICE" && item.nameType == "FREE_TIER"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "UTILITY",
          data: this.conversationResultNewItems
            .filter(
              (item) =>
                item.nameDirection === "UTILITY" && item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "MARKETING",
          data: this.conversationResultNewItems
            .filter(
              (item) =>
                item.nameDirection === "MARKETING" && item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "AUTHENTICATION",
          data: this.conversationResultNewItems
            .filter(
              (item) =>
                item.nameDirection === "AUTHENTICATION" &&
                item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "Iniciado pela empresa Free",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "BUSINESS_INITIATED" &&
                item.nameType == "FREE_ENTRY_POINT"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },

        {
          name: "Iniciado pela empresa Free",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "USER_INITIATED" &&
                item.nameType == "FREE_ENTRY_POINT"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },

        {
          name: "Iniciado pela empresa Free",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "BUSINESS_INITIATED" &&
                item.nameType == "FREE_TIER"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "Iniciado pela empresa Regular",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "BUSINESS_INITIATED" &&
                item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "Iniciado pela empresa Free",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "USER_INITIATED" &&
                item.nameType == "FREE_TIER"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "Iniciado pela empresa Regular",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "USER_INITIATED" &&
                item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
      ];
    } else if (
      this.conversationReportItems.length > 0 &&
      (this.conversationReportItems[0].nameDirection === "BUSINESS_INITIATED" ||
        this.conversationReportItems[0].nameDirection === "USER_INITIATED")
    ) {
      return [
        {
          name: "Iniciado pela empresa Free",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "BUSINESS_INITIATED" &&
                item.nameType == "FREE_ENTRY_POINT"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },

        {
          name: "Iniciado pela empresa Free",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "USER_INITIATED" &&
                item.nameType == "FREE_ENTRY_POINT"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },

        {
          name: "Iniciado pela empresa Free",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "BUSINESS_INITIATED" &&
                item.nameType == "FREE_TIER"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "Iniciado pela empresa Regular",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "BUSINESS_INITIATED" &&
                item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "Iniciado pela empresa Free",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "USER_INITIATED" &&
                item.nameType == "FREE_TIER"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "Iniciado pela empresa Regular",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "USER_INITIATED" &&
                item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
      ];
    } else {
      return [
        {
          name: "SERVICE",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "SERVICE" && item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },

        {
          name: "SERVICE",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "SERVICE" &&
                item.nameType == "FREE_ENTRY_POINT"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },

        {
          name: "SERVICE",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "SERVICE" && item.nameType == "FREE_TIER"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "UTILITY",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "UTILITY" && item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "MARKETING",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "MARKETING" && item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
        {
          name: "AUTHENTICATION",
          data: this.conversationReportItems
            .filter(
              (item) =>
                item.nameDirection === "AUTHENTICATION" &&
                item.nameType == "REGULAR"
            )
            .map((item) => item.amount)
            .sort(this.sortByDate),
        },
      ];
    }
  }
  getNavBarColor() {
    return localStorage.getItem("navBarColor");
  }

  getAttendencySeries() {
    return [
      {
        name: "Quantidade",
        data: this.attendencyReport.map((item) => Number(item.amount)),
      },
    ];
  }

  sortByDate(a, b) {
    const date1 = new Date(a);
    const date2 = new Date(b);

    return date1 > date2 ? 1 : -1;
  }
}
