



























import { Component } from "vue-property-decorator";
import { translate } from "../../plugins/i18n";
import Request from "../../services/request";
import { HeaderItem } from "../../types/HeaderItem";
import { IZabbixAlert } from "../../types/zabbixAlert";
import { format } from "date-fns";
import moment from "moment";
@Component
export default class TableCriticals extends Request {
  list: IZabbixAlert[] = [];
  header:HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "name" },
    { text: `${translate("createdAt")}`, align: "start", value: "createdAt" },

    {
      text: `${translate("acknowledgedUser")}`,
      align: "center",
      value: "user.name",
    },
    {
      text: `${translate("acknowledged")}`,
      align: "center",
      value: "acknowledged",
    },
  ];
  async mounted() {
    const today = new Date();
    const formattedDate = format(today, "yyyy-MM-dd");
    const result = await this.get(
      this.paths.zabbixAlertGroupPath +
        `?&take=10&skip=1&orderByParam=id&orderOrientation=ASC&startDate=${formattedDate}&endDate=${formattedDate}&severity=5&filter=dashboard`
    );
    this.list = result.result;

    this.list.map((item) => {
      item.createdAt = moment(item.createdAt).format("DD/MM/YYYY");
      item.acknowledgedUser ? item.acknowledgedUser : "--";
    });
  }
}
