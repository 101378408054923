









































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import TechnicalNoteInput from "./TechnicalNoteInput.vue";
import { ITechnicalNote } from "../../types/TechnicalNote/ITechnicalNote";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { TCompanyTicket } from "../../types/Ticket/TCompanyTicket";
import moment from "moment";

import DownloadTechnicalNote from "./DownloadTechnicalNote.vue";
@Component({
  components: {
    TechnicalNoteInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    DownloadTechnicalNote,
  },
})
export default class TechnicalNoteList extends Request {
  manutentionId:string = ''
  deletePath: string = "";
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  entriesCompany: TCompanyTicket[] = [];
  entriesProduct: any[] = [];
  page: number = 1;
  pageCount: number = 0;
  itemsPerPage: number = 10;
  title = `${translate("title.technicalNote")}`;

  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.home")}`,
      to: "/dashboard/",
      disabled: false,
    },
    {
      text: `${translate("title.technicalNote")}`,
      disabled: true,
    },
  ];

  header: HeaderItem[] = [
    { text: `${translate("subject")}`, align: "start", value: "subject" },
    {
      text: `${translate("ticketZendesk")}`,
      align: "start",
      value: "ticketZendesk",
    },
    { text: `${translate("endDate")}`, align: "start", value: "endDate" },
    { text: `${translate("startDate")}`, align: "start", value: "startDate" },
    { text: `${translate("active")}`, align: "start", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-folder-account-outline",
    titlePage: `${translate("technicalNote")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: ITechnicalNote[] = [];
  async loadData() {
    if (this.$route.query.manutentionId) {
      this.manutentionId = String(this.$route.query.manutentionId);
    }
    this.deletePath = this.paths.technicalNotePath;
    const result = await this.get(
      this.paths.technicalNoteListPath + `?&take=${this.itemsPerPage}&skip=${1}`
    );
    const companyList = await this.get(
      this.paths.companyPagePath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    this.entriesCompany = companyList.result;
    const producList = await this.get(
      this.paths.productPath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    this.entriesProduct = producList[0];
    if (result) {
      this.list = result.result;
      this.list.map((item) => {
        item.startDate = moment(item.startDate).format("DD/MM/YYYY");
        item.endDate = moment(item.endDate).format("DD/MM/YYYY");
      });
    }
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
  }
  async mounted(): Promise<void> {
    this.loadData();
  }
  async loadPagination() {
    const technicalNoteList = await this.get(
      this.paths.technicalNoteListPath +
        `?take=${this.itemsPerPage}&skip=${this.page}`
    );
    if (technicalNoteList.result) {
      this.list = technicalNoteList.result;
      this.list.map((item) => {
        item.startDate = moment(item.startDate).format("DD/MM/YYYY");
        item.endDate = moment(item.endDate).format("DD/MM/YYYY");
      });
    }
    this.pageCount = Math.ceil(technicalNoteList.count / this.itemsPerPage);
  }
  updateOptions() {
    //this.loadData();
  }

  get companiesWithCodeAndSocialName() {
    // const newItem = {
    //   id: 0,
    //   socialName: this.$t("sendToAll") as string,
    //   code: "0",
    //   companyProductPlanId: 0,
    // };

    // this.entriesCompany.unshift(newItem);

    return this.entriesCompany.map((company) => ({
      ...company,
      companyId: company.id,
      codeAndSocialName: `${company.code} - ${company.socialName}`,
    }));
  }
  entriesCompanyEmited(companies) {
    this.entriesCompany = [...this.entriesCompany, ...companies];
  }
}
