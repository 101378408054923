
















































































































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { IOminiCustomers } from "../../types/OminiCustomers/IOminiCustomers";
import { itemsPerPage } from "../../types/itemsPerPage";
import { HeaderItem } from "../../types/HeaderItem";
import { Breadcrumb } from "../../types/Breadcrumb";
import { paths } from "../../services/apiPaths";
import {
  BaseBreadcrumb,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";

@Component({
  components: {
    BaseBreadcrumb,
    PageHeader,
  },
})
export default class OmniCustomersReport extends Request {
  path:string = paths.zendeskTicket;
  title:string = `${translate("ominiCustomers")}`;
  list: IOminiCustomers[] = [];
  dynamicHeader: HeaderItem[] = [];
  headerItems: any = "";
  countList:number =0
  breadcrumbs: Breadcrumb[]= [
    {
      text: `${translate("ominiCustomers")}`,
      disabled: true,
    },
  ];
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  headerItemsList = [
    {
      id: 1,
      value: "company.socialName",
      title: translate("companyName"),
      headerValue: "socialName",
    },
    {
      id: 2,
      value: "company.officialName",
      title: translate("officialName"),
      headerValue: "officialName",
    },
    {
      id: 3,
      value: "company.cnpj",
      title: translate("cnpj"),
      headerValue: "cnpj",
    },
    {
      id: 4,
      value: "companyProductPlan.code",
      title: translate("code"),
      headerValue: "code",
    },
    {
      id: 4,
      value: "companyProductConfig.domain",
      title: translate("serverUrl"),
      headerValue: "domain",
    },
    {
      id: 5,
      value: "companyProductConfig.version",
      title: translate("version"),
      headerValue: "version",
    },
  ];
  page:number = 1;
  pageCount:number = 0;
  itemsPerPage:number = 10;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-chart-timeline",
    titlePage: `${translate("ominiCustomers")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    crudDetail: "false",
  };
  isBlocked: boolean = false;
  itemsDownload = [];
  async seach() {
    const fields = this.headerItems.map((item) => {
      return `"${item.value}"`;
    });

    this.page = 1;

    const result = await this.get(
      this.paths.ominiCustomersPath +
        `?take=${this.itemsPerPage}&skip=${1}&isBlocked=${
          this.isBlocked
        }&fields=[${fields}]`
    );
    this.countList = result.count
    this.list = result.result;
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
    this.dynamicHeader = this.headerItems.map((item) => {
      return {
        text: `${translate(item.title)}`,
        align: "start",
        value: item.value,
      };
    });
  }

  formatedVersion(version: string) {
    return version != null ? version : "--";
  }

  async loadPagination() {
    const fields = this.headerItems.map((item) => {
      return `"${item.value}"`;
    });

    const result = await this.get(
      this.paths.ominiCustomersPath +
        `?take=${this.itemsPerPage}&skip=${this.page}&isBlocked=${this.isBlocked}&fields=[${fields}]`
    );
    this.list = result.result;
    this.pageCount = Math.ceil(result.count / this.itemsPerPage);

    this.dynamicHeader = this.headerItems.map((item) => {
      return {
        text: `${translate(item.title)}`,
        align: "start",
        value: item.value,
      };
    });
  }
  get header() {
    return this.dynamicHeader;
  }
  get saveDisabled() {
    return false;
  }
  downloadCsv(list: any) {
    return list;
  }
  async downloadExcel() {
    const fields = this.headerItems.map((item) => {
      return `"${item.value || "--"}"`;
    });

    this.page = 1;

    const resultListAll = await this.get(
      this.paths.ominiCustomersPath +
        `?take=${this.countList}&skip=${1}&isBlocked=${this.isBlocked}&fields=[${fields}]`
    );
    const listDownload = resultListAll.result;
    this.itemsDownload = await this.downloadCsv(listDownload);
    let result, ctr, keys;

    if (
      this.itemsDownload === null ||
      (!this.itemsDownload && !this.itemsDownload.length)
    ) {
      return this.$message?.successMessage(translate("downloadReady"));
    }

    keys = Object.keys(this.itemsDownload[0]);

    result = "";
    result += keys.map((key) => translate(`ominiCostumers.${key}`)).join(",");
    result += "\n";

    this.itemsDownload.forEach((item) => {
      ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) {
          result += ",";
        }

        const value = item[key] !== undefined ? item[key] : "--";
        result +=
          typeof value === "string" && value.includes(",")
            ? `"${value}"`
            : value;
        ctr++;
      });
      result += "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(result);
    hiddenElement.target = "_blank";

    hiddenElement.download = "relatório.csv";
    hiddenElement.click();
  }

  updateOptions() {
    //this.seach();
  }
}
