













import { Component, Vue } from "vue-property-decorator";
import {
  Message,
  Loading,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import Request from "./services/request";
@Component({
  components: {
    Message,
    Loading,
  },
})
export default class App extends Request {
logoLight = require("@/assets/logos-new/LOGO 11.png");
logoDark = require('@/assets/logos-new/LOGO 1.png');
  async mounted() {
    localStorage.setItem("logo-dark", this.logoDark);
    localStorage.setItem("logo-light", this.logoLight);
    Vue.prototype.$message = this.$refs.message;
    Vue.prototype.$loading = this.$refs.loading;
  }
}
