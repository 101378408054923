import { render, staticRenderFns } from "./NotificationPopup.vue?vue&type=template&id=52d174c0&scoped=true"
import script from "./NotificationPopup.vue?vue&type=script&lang=ts"
export * from "./NotificationPopup.vue?vue&type=script&lang=ts"
import style0 from "./NotificationPopup.vue?vue&type=style&index=0&id=52d174c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d174c0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCardActions,VCol,VDataTable,VDialog,VIcon,VPagination,VRow,VSelect,VSpacer,VToolbar,VTooltip})
