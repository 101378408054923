















import { Component, Prop } from "vue-property-decorator";
import { PlanDetail } from "../../types/PlanDetail";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views/index";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";

@Component({
  components: { InputPopup },
})
export default class PlanDetailInput extends Request {
  @Prop() readonly entity: PlanDetail;
  @Prop() readonly list: PlanDetail[];
  @Prop() readonly planId;
  @Prop() readonly productName;
  readonly header = `${translate("planDetail")}`;

  

  itemsForm: InputModel[] = [];

  async mounted() {
    if (this.productName.toUpperCase() === "HEPPY") {
      this.itemsForm = [
        {
          id: 1,
          col: 6,
          label: `${translate("name")}`,
          tag: "v-text-field",
          type: "text",
          model: "name",
          required: true,
        },
        {
          id: 2,
          col: 6,
          label: `${translate("value")}`,
          tag: "v-text-field",
          type: "text",
          model: "value",
          required: true,
        },
        {
          id: 3,
          col: 2,
          label: `${translate("active")}`,
          tag: "v-switch",
          model: "active",
        },
      ];
    } else if (this.productName.toUpperCase() === "OMNI") {
      this.itemsForm = [
        {
          id: 1,
          col: 6,
          label: `${translate("plataformUrl")}`,
          tag: "v-text-field",
          type: "text",
          model: "plataformUrl",
          required: true,
        },
        {
          id: 2,
          col: 6,
          label: `${translate("serverLogin")}`,
          tag: "v-text-field",
          type: "text",
          model: "serverLogin",
          required: true,
        },
        {
          id: 3,
          col: 6,
          label: `${translate("serverPassword")}`,
          tag: "v-text-field",
          type: "text",
          model: "serverPassword",
          required: true,
        },
        {
          id: 4,
          col: 6,
          label: `${translate("contractedChannels")}`,
          tag: "v-text-field",
          type: "text",
          model: "contractedChannels",
          required: true,
        },
        {
          id: 6,
          col: 6,
          label: `${translate("integratons")}`,
          tag: "v-text-field",
          type: "text",
          model: "integratons",
          required: true,
        },
        {
          id: 6,
          col: 6,
          label: `${translate("technicalManager")}`,
          tag: "v-text-field",
          type: "text",
          model: "technicalManager",
          required: true,
        },
        {
          id: 7,
          col: 6,
          label: `${translate("financialResponsible")}`,
          tag: "v-text-field",
          type: "text",
          model: "financialResponsible",
          required: true,
        },
      ];
    }
  }
}
