





































































import { Component, Prop, Watch } from "vue-property-decorator";
import Request from "../../services/request";
import { IGetUser } from "../../types/IGetUser";
import { mapGetters } from "vuex";
import { ITechnicalNote } from "../../types/TechnicalNote/ITechnicalNote";
import { IMaintenance } from "../../types/Maintenance/IMaintenance";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Authorizations extends Request {
  @Prop() readonly loadData: () => void;
  @Prop() readonly entriesTechnicalNote: ITechnicalNote[];
  @Prop() readonly entity: IMaintenance;

  technicalNoteList: ITechnicalNote[] = [];
  dialog: boolean = false;
  searchTechnicalNote: string = "";
  getUser: any;
  editorUserId: number;
  user: IGetUser = {};
  itemsPerPage: number = 10;
  technocalNote: any = {};

  mounted() {
    this.user = this.getUser();
    if (this.entity?.technicalNote?.id) {
      this.technocalNote = this.entity.technicalNote.id;
    }
  }

  goToTechnicalNote() {
    if (this.entity?.id) {
      this.$router.push({
        name: "TechnicalNoteList",
        query: {
          manutentionId: String(this.entity.id),
        },
      });
    }
  }
  async onSave() {
    const result = await this.put(
      this.paths.technicalNoteUpdatePath + this.technocalNote.id,
      {
        editorUserId: this.user.id,
        manutentionId: this.entity.id,
      }
    );
    this.loadData();
    this.dialog = false;
    return result;
  }
  get technicalNoteListAutocomplete() {
    if (this.technicalNoteList?.length > 0) {
      return this.technicalNoteList;
    } else {
      return this.entriesTechnicalNote;
    }
  }
  async handleClear() {
    const result = await this.get(
      this.paths.technicalNotePath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    return (this.technicalNoteList = result.result);
  }
  @Watch("searchTechnicalNote")
  async searchTechnicalNotes(val) {
    if (val && val.length >= 3) {
      const companies = await this.get(
        this.paths.technicalNotePath +
          `?take=${this.itemsPerPage}&skip=${1}&searchValue=${val}`
      );
      return (this.technicalNoteList = companies.result);
    }
    if (val && val.length === 0) {
      const companies = await this.get(
        this.paths.technicalNotePath + `?take=${this.itemsPerPage}&skip=${1}`
      );
      return (this.technicalNoteList = companies.result);
    }
  }

  get missingNote() {
    return !this.technocalNote.id;
  }

  get saveDisabled() {
    return this.missingNote;
  }
}
