























































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";

@Component
export default class DeleteCompanyProductPlan extends Request {
  @Prop() companyProductPlanId: number;
  @Prop() entityDelete: any;
  readonly header = `${translate("addChannels")}`;
  entity: any = {};
  dialog: boolean = false;
  onDelete() {
    this.entity = this.entityDelete.companyProductPlan;
    this.entity.active = !this.entity.active;
    this.entity.companyProductPlanId = this.entityDelete.companyProductPlan.id;
    this.delete(this.paths.companyProductPlanPath + "delete-logic", this.entity);
    this.dialog = false;
    return this.$emit("is-active", this.entity);
  }
  mounted() {
    if (this.entityDelete && this.entityDelete.companyProductPlan)
      this.entity = this.entityDelete.companyProductPlan;
  }
  get buttonIcons() {
    return this.entity.active === true
      ? {
          icon: "mdi-close-circle",
          title: `${translate("inactivate")}`,
          color: "rgb(249 0 0)",  
        }
      : {
          icon: "mdi-check-circle",
          title: `${translate("activate")}`,
          color: "rgb(76, 175, 80)",
        };
  }
}
