




























































































































import { Component, Prop } from "vue-property-decorator";

import { Template, toolbarOptions } from "./Template";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import moment from "moment";
@Component({
  computed: {},
})
export default class ProductInput extends Request {
  @Prop() readonly entity: Template;
  @Prop() readonly list: Template[];
  readonly header = `${translate("templateList")}`;
  dialog: boolean = false;
  templateTypeList: [] = [];
  imageUrl: any = [];
  attachment: any = null;
  attachmentFile: any = null;
  blob: any = null;
  editorOption = {
    modules: {
      toolbar: toolbarOptions,
    },
    placeholder: `${translate("insertHtml")}` ,

    theme: "snow",
  };
  from: string = "";
  subject: string | undefined = "";
  templateTypeId: number | undefined = 0;
  variable: any = null;
  message: string = "";
  active: boolean = true;
  localEntity: any = [];
  variableList: number | number[] = 0;
  close() {
    if (!this.entity.id) {
      this.from = "";
      this.active = false;
      this.subject = "";
      this.message = "";
      this.templateTypeId = 0;
      this.variable = 0;
      this.dialog = false;
      this.attachment = {};
    } else {
      this.dialog = false;
    }
  }
  async onSave(): Promise<any> {
    this.dialog = false;
    return this.newList();
  }
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async newList() {
    Object.assign(this.entity, this.localEntity);

    this.entity.from = this.from;
    this.entity.active = this.active;
    this.entity.subject = this.subject;
    this.entity.message = this.message;
    this.entity.templateTypeId = this.templateTypeId;

    this.entity.attachment = this.attachment;

    this.entity.templateVariable = this.variable.map((item) => {
      return { variableId: item };
    });

    const result = await this.save(this.paths.templateCreatePath, this.entity);
    // const resultAttachment = await this.save(this.paths.attachmentPath, {
    //   name: this.attachment.name,
    //   contentType: this.attachment.contentType,
    //   file: this.attachmentFile,
    // });
    const dbEntity = result;
    dbEntity.updatedAt = moment(dbEntity.updatedAt).format("DD/MM/YYYY");

    if (!this.localEntity.id) {
      this.list.push({
        ...this.entity,
        id: dbEntity.id,
        from: dbEntity.from,
        active: dbEntity.active,
        subject: dbEntity.subject,
        message: dbEntity.message,
        templateTypeId: dbEntity.templateTypeId,
        templateVariable: dbEntity.templateVariable,
        attachment: dbEntity.attachment,
      });
      this.localEntity = {};
    }
  }
  async mounted() {
    const templateType = await this.get(this.paths.templateTypePath);
    this.templateTypeList = templateType[0];
    const variable = await this.get(this.paths.variablePath);
    this.variableList = variable[0];

    this.from = this.entity.from;
    this.active = this.entity.active;
    this.subject = this.entity.subject;
    this.message = this.entity.message;
    this.templateTypeId = this.entity.templateTypeId;
    this.variable = this.entity.templateVariable;
    this.attachment = this.entity.attachment;
    this.load();
  }
  attachmentFileChange(e) {
    let filesResult: any = [];
    e.forEach((_file, index) => {
    
      const files = e;
      const fr = new FileReader();
      fr.readAsDataURL(files[index]);
      fr.addEventListener("load", () => {
        filesResult.push({
          name: this.attachmentFile[index].name,
          contentType: this.attachmentFile[index].type,
          file: fr.result,
        });
        // this.imageUrl.push(fr.result);
      });
    });
    //
    // for (let index = 0; index < this.attachmentFile.length; index++) {
    //   files.push({
    //     name: this.attachmentFile[index].name,
    //     contentType: this.attachmentFile[index].type,
    //     file: this.imageUrl[0],
    //   });
    // }
    this.attachment = filesResult;
  }
}
