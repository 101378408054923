













































































import { Component, Prop } from "vue-property-decorator";
import { Department } from "../../types/Departament/Department";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";

@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class DepartmentInput extends Request {
  @Prop() readonly entity: Department;
  @Prop() readonly list: Department[];
  @Prop() readonly loadData: () => void;

  readonly header = `${translate("editSector")}`;
  dialog:boolean = false;
  name:string = "";
  companyId = this.$route.query.companyId;
  localEntity: Department={} ;
  editorUserId: number = null;
  getUser: any;
  active: boolean = true;

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async onSave(): Promise<any> {
    const user = this.getUser();
    this.companyId = user.companyId.id;
    this.editorUserId = user.id;
    let result;
 
    if (this.localEntity.id) {
      result = await this.put(
        this.paths.departmentPath + "/" + this.localEntity.id,
        {
          name: this.localEntity.name,
          editorUserId: this.editorUserId,
          companyId: this.companyId,
          active: this.active,
        }
      );
    } else {
      result = await this.save(this.paths.departmentPath, {
        name: this.localEntity.name,
        editorUserId: this.editorUserId,
        companyId: this.companyId,
      });
    }
    this.loadData();
    if (!this.localEntity.id) {
      this.localEntity = {};
    }

    this.dialog = false;
    return result;
  }

  async mounted() {
    this.name = this.entity.name;
    this.active = this.entity.active;

    this.load();
  }
  get missingName() {
    return !this.localEntity.name;
  }

  get saveDisabled() {
    return this.missingName;
  }
}
