


































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
@Component
export default class IsDeleteCompany extends Request {
  @Prop() path: string;
  @Prop() entity: any;
  @Prop() list: any[];
  @Prop() loadData: any;
  dialog = false;

  async deleteItems() {
    this.entity.isDeleted = true;
    this.entity.active = false;
    const result = await this.remove(this.path + this.entity.id, {});
    this.loadData();
    this.dialog = false;
    return result;
  }
}
