
































































































































































































import { Component, Watch } from "vue-property-decorator";
import CompanyInput from "./CompanyInput.vue";
import { ICompany } from "../../types/Company/ICompany";
import { Options } from "../../types/Options";
import { TProduct } from "../../types/Company/TProduct";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import DeleteCascateCompanyProductPlan from "../../components/commonComponents/DeleteCascateCompanyProductPlan.vue";
import ConfirmDelete from "../../components/IsDeleteCompany.vue";
import {
  BaseBreadcrumb,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";

@Component({
  components: {
    CompanyInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    PageHeader,
    DeleteCascateCompanyProductPlan,
  },
})
export default class CompanyList extends Request {
  path: string = paths.deleteLogicCompanyPath;
  search: string = "";
  productList: TProduct[] = [];
  title = `${translate("title.company")}`;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.company")}`,
      disabled: true,
    },
  ];
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page: number = 1;
  pageCount: number = 2;
  itemsPerPage: number = 10;
  header: HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "socialName" },
    {
      text: `${translate("usualCompanyName")}`,
      align: "center",
      value: "officialName",
    },
    {
      text: `${translate("title.identifier")}`,
      align: "center",
      value: "code",
    },
    { text: `${translate("cnpj")}`, align: "center", value: "cnpj" },
    { text: `${translate("status")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-office-building",
    titlePage: `${translate("title.companies")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  options: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };
  list: ICompany[] = [];
  async loadData(): Promise<void> {
    const products = await this.get(this.paths.productPlanListPath);
    this.productList = products;

    let result;

    const sortBy = this.options?.sortBy[0];
    const orientation = this.options?.sortDesc[0] === true ? "DESC" : "ASC";

    if (!sortBy) {
      result = await this.get( 
        this.paths.pagiinationCountPath +
          `?&take=${this.itemsPerPage}&skip=${this.page}&searchValue=${
            this.search
          }&orderByParam=${"id"}&orderOrientation=${orientation}`
      );
    } else {
      result = await this.get(
        this.paths.pagiinationCountPath +
          `?&take=${this.itemsPerPage}&skip=${this.page}&searchValue=${this.search}&orderByParam=${sortBy}&orderOrientation=${orientation}`
      );
    }

    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
    this.list = result.result;
  }

  // async mounted(): Promise<any> {
  //   await this.loadData();
  // }
  async loadOmie() {
    const companyList = await this.get(this.paths.omiePath);
    this.list = companyList;
  }

  @Watch("search")
  onSearchChange(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      this.page = 1;
    }
  }

  formattedCNPJ(cnpj: string) {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  updateOptions(newOptions: Options) {
    this.options = { ...this.options, ...newOptions };

    this.loadData();
  }
}
