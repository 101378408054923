













































































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { IProduct } from "../../types/Product/IProduct";
import { IMaintenance } from "../../types/Maintenance/IMaintenance";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { TUserMaintenance } from "../../types/Maintenance/TUserMaintenance";
import { ICompany } from "../../types/Company/ICompany";
import { TExecutor } from "../../types/Maintenance/TExecutor";
import { IGetUser } from "../../types/IGetUser";
import { TManutentionAuthorization } from "../../types/Maintenance/TManutentionAuthorization";
import { mask } from "vue-the-mask";
import moment from "moment";
import { mapGetters } from "vuex";
@Component({
  directives: { mask },
  components: {
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class MaintenanceInput extends Request {
  @Prop() readonly resultAuthorizations: TManutentionAuthorization;
  @Prop() readonly resultProduct: IProduct[];
  @Prop() readonly resultUsers: TUserMaintenance[];
  @Prop() readonly entity;
  @Prop() readonly list: IMaintenance[];
  @Prop() readonly manutentionId: number;
  @Prop() readonly comapanieId: number;
  @Prop() loadData: () => void;

  readonly header = `${translate("editMaintenance")}`;
  productList: IProduct[] = [];
  selectedProduct: number = null;
  date: string = null;
  dialog: boolean = null;
  menu: boolean = false;
  companyTypeIds: ICompany[] = [];
  companieId: any;
  entriesCompany: any = [];
  clientUser: TUserMaintenance[] = [];
  searchCompany: string = "";
  searchAuthorizations: string = "";
  searchUsers: string = "";
  startDateTime: string = "";
  startDateMenu: string = "";
  valid: boolean = false;
  endDateTime: string = "";
  endtDateMenu: boolean = false;
  requester: string = "";
  getUser: any;
  user: IGetUser;
  edit: string = `${translate("editMaintenancea")}`;
  description: string = "";
  editorUserId: number;
  resultFind: IMaintenance = null;
  technicalManagerList: TUserMaintenance[] = [];
  technicalManager: TExecutor[] = [];
  authorizations: any[] = [];
  authorizationsList: TUserMaintenance[] = [];
  localEntity: IMaintenance = {};

  async onProductSelected(productId) {
    let companyList;

    if (productId === true) {
      this.selectedProduct = this.entity.productId;
      companyList = await this.get(
        this.paths.notificationListCompany + this.selectedProduct
      );
    } else {
      companyList = await this.get(
        this.paths.notificationListCompany + productId
      );
    }
    this.entriesCompany = companyList;
  }
  async mounted() {
    if (this.getUser().companyId.id) {
      this.user = this.getUser();
      this.companieId = this.user.companyId.id;
    } else {
      this.user = this.getUser();
      this.companieId = this.user.companyId;
    }

    if (this.entity.id) {
      this.selectedProduct = this.entity.productId;
      this.date = moment(new Date(this.entity.date)).format("DD/MM/YYYY");
      this.startDateTime = this.entity.initialTime;
      this.endDateTime = this.entity.finalTime;

      this.companyTypeIds = this.entity.manutentionClients.map(
        (item) => item.companyId
      );
      this.description = this.entity.description;
      this.technicalManager = this.entity.manutentionExecutors.map(
        (executor) => executor.executorUser.id
      );

      this.authorizations = this.entity.manutentionAuthorizations.map(
        (userID) => userID.authorizationUser.id
      );
    }

    const user = this.$store.getters["auth/getUser"];
    this.requester = user.firstName;
    this.productList = this.resultProduct;
    this.technicalManagerList = this.resultUsers;
    this.authorizationsList = this.resultUsers;
  }

  close() {
    this.dialog = false;
  }
}
