var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.validMaintenance == true)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.missingTechnical}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dense":"","disabled":_vm.missingTechnical,"title":_vm.$t('download')}},[_vm._v(" mdi-download ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dense":"","title":_vm.$t('download')}},[_vm._v(" mdi-download ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary"},[_vm._v(" "+_vm._s(_vm.$t("technicalNote"))+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-center align-center mt-5"},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("selectCompany")))])]),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.companyTechnicalNotes,"label":_vm.$t('company'),"outlined":"","item-text":"officialName","item-value":"id","return-object":true,"rules":[_vm.$rules.fieldsRequired],"multiple":"","chips":"","clareable":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.validMaintenance === true)?_c('span',[_vm._v(" "+_vm._s(item.clientCompany.officialName)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.officialName)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.validMaintenance === true)?_c('v-chip',[_vm._v(" "+_vm._s(item.clientCompany.officialName)+" "),_c('v-icon',{on:{"click":function($event){return _vm.removeCompanyType(item.id)}}},[_vm._v("mdi-close-circle")])],1):_c('v-chip',[_vm._v(" "+_vm._s(item.officialName)+" "),_c('v-icon',{on:{"click":function($event){return _vm.removeCompanyType(item.id)}}},[_vm._v("mdi-close-circle")])],1)]}}]),model:{value:(_vm.companies),callback:function ($$v) {_vm.companies=$$v},expression:"companies"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("button.cancel")))]),_c('v-btn',{attrs:{"disabled":_vm.saveDisabled,"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.generatePDF(_vm.entity)}}},[_vm._v(_vm._s(_vm.$t("download")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }