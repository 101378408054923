
























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { TUserMaintenance } from "../../types/Maintenance/TUserMaintenance";
import { IGetUser } from "../../types/IGetUser";
import { HeaderItem } from "../../types/HeaderItem";
import { TManutentionAuthorization } from "../../types/Maintenance/TManutentionAuthorization";
import { mapGetters } from "vuex";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Authorizations extends Request {
  @Prop() readonly manutentionId: number;
  @Prop() readonly resultAuthorizations: TManutentionAuthorization;
  @Prop() readonly resultUsers: TUserMaintenance[];
  @Prop() loadData: () => void;
  dialog: boolean = false;
  authorizationsList: TUserMaintenance[] = [];
  observation: string = "";
  getUser: any;
  editorUserId: number;
  user: IGetUser = {};
  userObservationId: number = null;
  headers: HeaderItem[] = [
    {
      text: `${translate("authorizing")}`,
      align: "start",
      value: "name",
    },
    { text: `${translate("justification")}`, value: "observation" },
    { text: `${translate("status")}`, value: "authorization" },
  ];
  list = [];

  changeStatus(value, index) {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[index].authorization === 0) {
        this.list[index].authorization = value;
      } else if (this.list[index].authorization === 1) {
        this.list[index].authorization = value;
      } else {
        this.list[index].authorization = value;
      }
    }
  }

  async mounted(): Promise<void> {
    const result = this.resultAuthorizations;

    this.user = this.getUser();
    this.authorizationsList = this.resultUsers;

    if (result) {
      const foundObject = result[0].find(
        (item) => item.id === this.manutentionId
      );

      if (foundObject) {
        this.list = foundObject.manutentionAuthorizations;
      }

      this.list.map((item) => {
        this.authorizationsList.find((i) => {
          if (item.userId === i.id) {
            item.userId = i.id;
            for (let index = 0; index < this.list.length; index++) {
              item.name = i.firstName + " " + i.lastName;
            }
          }
        });
      });
    }
  }

  async onSave() {
    const user = this.getUser();
    this.editorUserId = user.id;

    for (const item of this.list) {
      if (
        item.authorization !== item.originalAuthorization ||
        item.observation !== item.originalObservation
      ) {
        if (this.editorUserId === item.userId) {
          const entityAuthorization = {
            manutentionId: this.manutentionId,
            authorizationUserId: item.authorizationUserId,
            authorization: item.authorization,
            observation: item.observation,
          };

          const result = await this.patch(
            this.paths.authorizationPath + item.id,
            { ...entityAuthorization, editorUserId: this.editorUserId }
          );
          this.dialog = false;
          item.changed = false;
          this.loadData();
          return result;
        }
      }
    }
  }

  getItem(item) {
    this.userObservationId = item.id;
  }
  get disableObservation() {
    return this.user.id != this.userObservationId;
  }
}
