






























































































































































































































































import { Datetime } from "vue-datetime";
import { Component, Prop, Watch } from "vue-property-decorator";
import Request from "../../services/request";
import { IVacation } from "../../types/Vacation/IVacation";
import { TUserlist } from "../../types/Vacation/TUserlist";
import { TItem} from "../../types/Vacation/TItem";
import { IGetUser } from "../../types/IGetUser";
import { translate } from "../../plugins/i18n/index";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import moment from "moment";

@Component({
  components: {
    Datetime,
  },
  directives: { mask },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class VacationInput extends Request {
  @Prop() readonly entity: IVacation;
  @Prop() readonly list:IVacation [];
  @Prop() readonly loadData: () => void;
  @Prop() readonly userList: TUserlist[];
  dialog: boolean = false;
  readonly header = `${translate("editVacation")}`;
  name: string = "";
  selectedUser: any = "";
  period: any = "";
  active: boolean = true;
  editorUserId: number = null;
  getUser: any;
  firstName: string = "";
  date30: string = '';
  date15: string = '';
  permission: string = '';
  menu:boolean = false;
  menu2:boolean = false;
  menu3:boolean = false;
  menuLimit:boolean = false;
  localEntity: any = {};
  user: IGetUser = {};
  items: TItem[] = [
    { id: 1, name: `${translate("vestingPeriod")}` },
    { id: 2, name: `${translate("LimitVacationEnjoyment")}` },
  ];
  itemsPeriod:TItem[] = [
    { id: 1, name: `${translate("thirtyDays")}` },
    { id: 2, name: `${translate("fifteenDays")}` },
  ];
  load(): void {
    if (this.entity) {
      this.localEntity = {
        ...this.entity,
        date30: [],
        date15: [],
        permission: [],
      };
    }
  }

  async onSave(): Promise<any> {
    const user = this.getUser();
    this.editorUserId = user.id;

    let result;
    let vacationDetails;

    if (this.localEntity.id) {
      if (
        this.localEntity.vacationDetails &&
        this.localEntity.vacationDetails[1] &&
        this.localEntity.vacationDetails[1].id
      ) {
        const dateStart30 = this.localEntity.date30[0].split(",");
        const dateEnd30 = this.localEntity.date30[1].split(",");
        const vacationDateId = this.localEntity.vacationDetails[0].id;
        const dateStart15 = this.localEntity.date15[0].split(",");
        const dateEnd15 = this.localEntity.date15[1].split(",");
        const vacationDateId2 = this.localEntity.vacationDetails[1].id;

        vacationDetails = [
          {
            startDate:
              dateStart30[0] !== "2"
                ? dateStart30[0]
                : this.localEntity.vacationDetails[0].startDate,
            endDate:
              dateEnd30[0] !== "0"
                ? dateEnd30[0]
                : this.localEntity.vacationDetails[0].endDate,
            id: vacationDateId,
          },
          {
            startDate:
              dateStart15[0] !== "2"
                ? dateStart15[0]
                : this.localEntity.vacationDetails[1].startDate,
            endDate:
              dateEnd15[0] !== "0"
                ? dateEnd15[0]
                : this.localEntity.vacationDetails[1].endDate,
            id: vacationDateId2,
          },
        ];
      } else if (
        this.period.id == 1 ||
        (this.period == 1 &&
          this.localEntity.vacationDetails &&
          this.localEntity.vacationDetails[0] &&
          this.localEntity.vacationDetails[0].id &&
          !this.localEntity.date15[0])
      ) {
        const dateStart30 = this.localEntity.date30[0].split(",");
        const dateEnd30 = this.localEntity.date30[1].split(",");
        const vacationDateId = this.localEntity.vacationDetails[0].id;

        vacationDetails = [
          {
            startDate:
              dateStart30[0] !== "2"
                ? dateStart30[0]
                : this.localEntity.vacationDetails[0].startDate,
            endDate:
              dateEnd30[0] !== "0"
                ? dateEnd30[0]
                : this.localEntity.vacationDetails[0].endDate,
            id: vacationDateId,
          },
        ];
      } else {
        const dateEnd30 = this.localEntity.date30[1].split(",");
        const dateStart30 = this.localEntity.date30[0].split(",");
        const dateStart15 = this.localEntity.date15[0].split(",");
        const dateEnd15 = this.localEntity.date15[1].split(",");

        const vacationDateId = this.localEntity.vacationDetails[0].id;
        vacationDetails = [
          {
            startDate:
              dateStart30[0] !== "2"
                ? dateStart30[0]
                : this.localEntity.vacationDetails[0].startDate,
            endDate:
              dateEnd30[0] !== "0"
                ? dateEnd30[0]
                : this.localEntity.vacationDetails[0].endDate,
            id: vacationDateId,
          },
          {
            startDate: dateStart15[0],
            endDate: dateEnd15[0],
          },
        ];
      }

      result = await this.put(
        this.paths.vacationUpdatePath + this.localEntity.id,
        {
          userId: this.selectedUser,
          permissionInicialDate:
            this.localEntity.permission[0] !== "2"
              ? this.localEntity.permission[0]
              : this.localEntity.permissionInicialDate,
          permissionEndDate:
            this.localEntity.permission[1] !== "0"
              ? this.localEntity.permission[1]
              : this.localEntity.permissionEndDate,
          limit: this.localEntity.limit,
          editorUserId: this.editorUserId,
          vacationDetails: vacationDetails,
        }
      );
    } else {
      if (this.localEntity.date30[0]) {
        const dateStart30 = this.localEntity.date30[0].split(",");
        const dateEnd30 = this.localEntity.date30[1].split(",");
        vacationDetails = [
          {
            startDate: dateStart30[0],
            endDate: dateEnd30[0],
          },
        ];
      }

      if (this.localEntity.date15[0]) {
        const dateStart15 = this.localEntity.date15[0].split(",");
        const dateEnd15 = this.localEntity.date15[1].split(",");
        vacationDetails.push({
          startDate: dateStart15[0],
          endDate: dateEnd15[0],
        });
      }

      result = await this.save(this.paths.vacationCreatePath, {
        userId: this.selectedUser.id,
        editorUserId: this.editorUserId,
        permissionInicialDate: this.localEntity.permission[0],
        permissionEndDate: this.localEntity.permission[1],
        limit: this.localEntity.limit,
        vacationDetails: vacationDetails,
      });
    }
    this.dialog = false;
   
    if (!this.localEntity.id) {
  this.selectedUser = "";
  this.period = "";
  this.localEntity.date15 = ["", ""];
  this.localEntity.date30 = ["", ""];
  this.localEntity.permission = ["", ""]; 
  this.localEntity.limit = ""; 
}
    
    this.loadData();
    return result;
  }

  async mounted() {
  
    this.load();
    

    this.editorUserId = this.user.id;
    if (this.localEntity.id) {
      this.selectedUser = this.localEntity.userId;

      if (
        this.localEntity.vacationDetails &&
        this.localEntity.vacationDetails.length <= 1
      ) {
        this.period = 1;
        this.localEntity.date30 =
          this.localEntity.vacationDetails[0].startDate +
          "," +
          this.localEntity.vacationDetails[0].endDate;

        this.localEntity.permission =
          this.localEntity.permissionInicialDate +
          "," +
          this.localEntity.permissionEndDate;
        this.localEntity.limitFormated = moment(this.localEntity.limit).format(
          "DD/MM/YYYY"
        );
    
      } else if (
        this.localEntity.vacationDetails &&
        this.localEntity.vacationDetails.length >= 2
      ) {
        this.period = 2;
        this.localEntity.date30 =
          this.localEntity.vacationDetails[0].startDate +
          "," +
          this.localEntity.vacationDetails[0].endDate;
        this.localEntity.date15 =
          this.localEntity.vacationDetails[1].startDate +
          "," +
          this.localEntity.vacationDetails[1].endDate;
        this.localEntity.permission =
          this.localEntity.permissionInicialDate +
          "," +
          this.localEntity.permissionEndDate;
        this.localEntity.limitFormated = moment(this.localEntity.limit).format(
          "DD/MM/YYYY"
        );
       
      }
    }

  
  }

 
  @Watch("localEntity.limit")
  formatedLimit(value) {
    if (moment(value, "YYYY-MM-DD", true).isValid()) {
      this.localEntity.limitFormated = moment(value).format("DD/MM/YYYY");
    } else {
      this.localEntity.limitFormated = "";
    }
  }

  @Watch("localEntity.date30")
  formatedDate30(value) {
    const type = typeof value;

    if (type == "string") {
      let [firstDate, secondDate] = value.split(",");
      firstDate = moment(firstDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");
      secondDate = moment(secondDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");

      if (
        moment(firstDate, "DD/MM/YYYY", true).isValid() &&
        moment(secondDate, "DD/MM/YYYY", true).isValid()
      ) {
        this.localEntity.date30Formated = `${firstDate},${secondDate}`;
      } else {
        this.localEntity.date30Formated = "";
      }
    } else {
  let firstDate, secondDate;

  if (Array.isArray(value)) {
    [firstDate, secondDate] = value;
  } else {
    firstDate = null; 
    secondDate = null
  }

  firstDate = moment(firstDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");
  secondDate = moment(secondDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");

  if (
    moment(firstDate, "DD/MM/YYYY", true).isValid() &&
    moment(secondDate, "DD/MM/YYYY", true).isValid()
  ) {
    this.localEntity.date30Formated = `${firstDate},${secondDate}`;
  } else {
    this.localEntity.date30Formated = "";
  }
}

  }
  @Watch("localEntity.date15")
  formatedDate15(value) {
    const type = typeof value;

    if (type == "string") {
      let [firstDate, secondDate] = value.split(",");
      firstDate = moment(firstDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");
      secondDate = moment(secondDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");

      if (
        moment(firstDate, "DD/MM/YYYY", true).isValid() &&
        moment(secondDate, "DD/MM/YYYY", true).isValid()
      ) {
        this.localEntity.date15Formated = `${firstDate},${secondDate}`;
      } else {
        this.localEntity.date15Formated = "";
      }
    } else {
  let firstDate, secondDate;

  if (Array.isArray(value)) {
    [firstDate, secondDate] = value;
  } else {
    firstDate = null; 
    secondDate = null; 
  }

  firstDate = moment(firstDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");
  secondDate = moment(secondDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");

  if (
    moment(firstDate, "DD/MM/YYYY", true).isValid() &&
    moment(secondDate, "DD/MM/YYYY", true).isValid()
  ) {
    this.localEntity.date15Formated = `${firstDate},${secondDate}`;
  } else {
    this.localEntity.date15Formated = "";
  }
}

  }

  @Watch("localEntity.permission")
  formatedpermission(value) {
    const type = typeof value;

    if (type == "string") {
      let [firstDate, secondDate] = value.split(",");
      firstDate = moment(firstDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");
      secondDate = moment(secondDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");

      if (
        moment(firstDate, "DD/MM/YYYY", true).isValid() &&
        moment(secondDate, "DD/MM/YYYY", true).isValid()
      ) {
        this.localEntity.permissionFormated = `${firstDate},${secondDate}`;
      } else {
        this.localEntity.permissionFormated = "";
      }
    } else {
  let firstDate, secondDate;

  if (Array.isArray(value)) {
    [firstDate, secondDate] = value;
  } else {
    firstDate = null; 
    secondDate = null; 
  }

  const formattedFirstDate = moment(firstDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");
  const formattedSecondDate = moment(secondDate, "YYYY-MM-DD", true).format("DD/MM/YYYY");

  if (
    moment(formattedFirstDate, "DD/MM/YYYY", true).isValid() &&
    moment(formattedSecondDate, "DD/MM/YYYY", true).isValid()
  ) {
    this.localEntity.permissionFormated = `${formattedFirstDate},${formattedSecondDate}`;
  } else {
    this.localEntity.permissionFormated = "";
  }
}

  }
  get missingName() {
    return !this.selectedUser;
  }

  get missingLimit() {
    return !this.localEntity.limit;
  }
  get missingPermission() {
    return this.localEntity.permission <= 1;
  }
  get missingDate30() {
    if (this.period == 1 || this.period.id == 1) {
      return this.localEntity.date30.length <= 1;
    } else {
      return this.localEntity.date15.length <= 1;
    }
  }

  get saveDisabled() {
    return (
      this.missingName ||
      this.missingLimit ||
      this.missingPermission ||
      this.missingDate30
    );
  }
}
